import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import TbFilter from '../../components/common/ui/TbFilter';
import useApiError from '../../hooks/useApiError';
import { useQuery } from 'react-query';
import {
  EditorElementType,
  getEditorElementCategoryPage,
  getEditorElementCollectionPage,
  getEditorElementEnum,
  getEditorElementPage,
  ReqEditorElementPage,
} from '../../api/editorElementAPI';
import DataTable from 'react-data-table-component';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import { editorElementColumns } from './data/editor-element-columns';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import { enumToSelect } from '../../utils/enumUtils';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import TbInputColor from '../../components/common/ui/form/TbInputColor';
import { longTermCacheConfig } from '../../data/customizer/config';
import TbInput from '../../components/common/ui/form/TbInput';

const EditorElementList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [elementData, setElementData] = useState<any>([]);
  const [totalRows, setTotalRows] = useState(0);
  // query string 값으로 request 파라미터 초기화.
  const [reqParams, setReqParams] = useState<ReqEditorElementPage>({
    ...defaultFilter,
    // Custom Filter
    hexColor: searchParams.get('hexColor') || '',
    type: (searchParams.get('type') as EditorElementType) || '',
    idCategory: searchParams.get('idCategory') || '',
    idCollection: searchParams.get('idCollection') || '',
    idSearchKeyword: searchParams.get('idSearchKeyword') || '',
    active: searchParams.get('active') === 'true' ? true : searchParams.get('active') === 'false' ? false : null,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * Enum 리스트 조회.
   * */
  const { data: enumList } = useQuery(['getEditorElementEnum'], () => getEditorElementEnum(), {
    ...longTermCacheConfig,
    select: (data) => {
      return enumToSelect(data.typeList);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getEditorElementPage', { reqParams }], () => getEditorElementPage(reqParams), {
    onSuccess: (data) => {
      setElementData(data?._embedded?.editorElements || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 카테고리 목록 요청.
   * */
  const { data: resCategories } = useQuery(
    ['getEditorElementCategoryPage'],
    () => getEditorElementCategoryPage({ size: 1000 }),
    {
      ...longTermCacheConfig,
      select: (data) => {
        return data?._embedded?.categories?.map((item: any) => ({
          label: item.name,
          value: item.idElementCategory,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 컬렉션 목록 요청.
   * */
  const { data: resCollections } = useQuery(
    ['getEditorElementCollectionPage'],
    () => getEditorElementCollectionPage({ size: 1000 }),
    {
      ...longTermCacheConfig,
      select: (data) => {
        return data?._embedded?.collections?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/editor-element/update/${row.idElement}`);
  };

  /**
   * 검색 핸들러
   * */
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent="에디터 요소" title="요소 목록">
        <Link className="btn btn-primary" to={`/admin/editor-element/create`}>
          새로 등록
        </Link>
      </Breadcrumb>
      <div className="container-fluid">
        {/* :::::::::: Filter : Start ::::::::::  */}
        <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
          {({ register, errors, setValue }) => {
            setValue('type', reqParams.type);
            setValue('idCategory', reqParams.idCategory);
            setValue('idCollection', reqParams.idCollection);
            setValue('idSearchKeyword', reqParams.idSearchKeyword);
            setValue('active', reqParams.active);
            setValue('hexColor', reqParams.hexColor);
            return (
              <Row>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">형식</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="type"
                        options={enumList || []}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">카테고리</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="idCategory"
                        options={resCategories || []}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">컬렉션</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="idCollection"
                        options={resCollections || []}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">검색 키워드 ID</label>
                    </Col>
                    <Col sm={10}>
                      <TbInput
                        name={'idSearchKeyword'}
                        placeholder={'검색 키워드 ID'}
                        type="number"
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">활성상태</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="active"
                        options={[
                          { label: '활성', value: 'true' },
                          { label: '비활성', value: 'false' },
                        ]}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">HEX 색상</label>
                    </Col>
                    <Col sm={10}>
                      {reqParams.hexColor}
                      <div className={'d-flex'}>
                        <TbInputColor id={'hexColor'} name={'hexColor'} register={register} errors={errors} />
                        <div
                          onClick={() => {
                            setValue('hexColor', '');
                          }}
                          className={'btn btn-link btn-sm px-2'}
                        >
                          Reset
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }}
        </TbFilter>
        {/* :::::::::: Filter : End ::::::::::  */}

        {/*  :::::::::: List ::::::::::  */}
        <DataTable
          noHeader
          columns={editorElementColumns()}
          data={elementData}
          progressPending={isLoading}
          highlightOnHover
          pointerOnHover
          pagination
          paginationServer
          paginationDefaultPage={(reqParams.page || 0) + 1}
          paginationTotalRows={totalRows}
          paginationPerPage={reqParams.size}
          paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={handleRowClicked}
          dense={true}
          customStyles={{
            rows: rowsStyle,
            headCells: headCellsStyle,
            cells: cellsStyle,
          }}
        />
      </div>
    </Fragment>
  );
};
export default React.memo(EditorElementList);
