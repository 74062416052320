import APIClient from './APIClient';
import { PageInfo } from './types';
import { SupportLocale } from './settingAPI';

export interface ReqBrandKeyword {
  orderAscending?: number;
  active?: boolean;
}

export interface ResBrandKeyword {
  id?: number;
  name?: string;
  orderAscending?: number;
  active?: boolean;
  createdDate?: string;
  updatedDate?: string;
}

// 키워드
// 리스트
export const getBrandKeywordList = async (params: PageInfo) => {
  const response = await APIClient.get(`/v2/api/brand-keyword/page`, {
    params,
  });
  return response.data;
};
// 상세
export const getBrandKeyword = async (idBrandKeyword: number) => {
  const response = await APIClient.get(`/v2/api/brand-keyword/${idBrandKeyword}`, {});
  return response.data;
};
// Crate
export const createBrandKeyword = async (body: ReqBrandKeyword) => {
  const response = await APIClient.post(`/v2/api/brand-keyword`, body);
  return response.data;
};
// Delete
export const deleteBrandKeyword = async (idBrandKeyword: string) => {
  const response = await APIClient.delete(`/v2/api/brand-keyword/${idBrandKeyword}`, {});
  return response.data;
};
// Patch
export const updateDeleteKeyword = async ({
  idBrandKeyword,
  body,
}: {
  idBrandKeyword: string;
  body: ReqBrandKeyword;
}) => {
  const response = await APIClient.patch(`/v2/api/brand-keyword/${idBrandKeyword}`, body);
  return response.data;
};

// ===== i18n =====

export interface ReqBrandKeywordI18n {
  idBrandKeyword?: number;
  locale?: SupportLocale;
  name?: string;
}

export interface ResBrandKeywordI18n {
  id?: number;
  locale?: SupportLocale;
  name?: string;
  createdDate: string;
  updatedDate: string;
}

/**
 * [BrandKeywordI18n] 목록
 * */
export const getBrandKeywordI18nList = async (idBrandKeyword: number) => {
  const response = await APIClient.get('/v2/api/brand-keyword/i18n/list', {
    params: {
      idBrandKeyword,
    },
  });
  return response.data;
};

/**
 * [BrandKeywordI18n] 단일 조회
 * */
export const getBrandKeywordI18n = async (idBrandKeywordI18n: number) => {
  const response = await APIClient.get(`/v2/api/brand-keyword/i18n/${idBrandKeywordI18n}`);
  return response.data;
};

/**
 * [BrandKeywordI18n] 생성
 * */
export const createBrandKeywordI18n = async (brandKeywordI18n: ReqBrandKeywordI18n) => {
  const response = await APIClient.post('/v2/api/brand-keyword/i18n', brandKeywordI18n);
  return response.data;
};

/**
 * [BrandKeywordI18n] 수정
 * */
export const updateBrandKeywordI18n = async ({
  idBrandKeywordI18n,
  body,
}: {
  idBrandKeywordI18n: number;
  body: ReqBrandKeywordI18n;
}) => {
  const response = await APIClient.patch(`/v2/api/brand-keyword/i18n/${idBrandKeywordI18n}`, body);
  return response.data;
};

/**
 * [BrandKeywordI18n] 삭제
 * */
export const deleteBrandKeywordI18n = async (idBrandKeywordI18n: number) => {
  const response = await APIClient.delete(`/v2/api/brand-keyword/i18n/${idBrandKeywordI18n}`);
  return response.data;
};
