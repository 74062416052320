import React, { Fragment, useMemo, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import {
  createTemplateForm,
  deleteTemplateForm,
  getTemplateForm,
  ReqTemplateForm,
  updateTemplateForm,
} from '../../api/templateFormAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbModal from '../../components/common/ui/TbModal';
import TbLoading from '../../components/common/ui/TbLoading';
import TbDateText from '../../components/common/ui/form/TbDateText';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbInput from '../../components/common/ui/form/TbInput';
import { cmToPx, mmToPx } from '../../utils/dpiUtils';

const TemplateFormUpdate = () => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idTemplateForm: idTemplateForm } = useParams<{ idTemplateForm: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const createElement = useMutation(createTemplateForm);
  const updateMutate = useMutation(updateTemplateForm);
  const deleteElement = useMutation(deleteTemplateForm);
  const sizeUnitOption = [
    { label: 'px(웹)', value: 'PX' },
    { label: 'mm(인쇄)', value: 'MM' },
    { label: 'cm(인쇄)', value: 'CM' },
  ];

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqTemplateForm>({
    defaultValues: {
      ...(!idTemplateForm
        ? // 생성일 경우 초기값.
          {
            active: false, // 여기에 초기값 설정
            top: false,
          }
        : {}),
    },
  });

  const watchSizeUnit = watch('sizeUnit');
  const watchUnitWidth = watch('unitWidth');
  const watchUnitHeight = watch('unitHeight');

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(
    ['getTemplateForm', idTemplateForm],
    () => getTemplateForm(Number(idTemplateForm)),
    {
      enabled: !!idTemplateForm,
      select: (data) => {
        return {
          ...data,
        };
      },
      onSuccess: (data) => {
        setValue('name', data.name);
        setValue('unitWidth', Number(data.unitWidth));
        setValue('unitHeight', Number(data.unitHeight));
        setValue('sizeUnit', data.sizeUnit);
        setValue('top', data.top);
        setValue('active', data.active);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(false);
    if (idTemplateForm && Number(idTemplateForm) > 0) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idTemplateForm: Number(idTemplateForm),
          body: { ...data } as ReqTemplateForm,
        });
        toast.success('템플릿 양식 수정이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createElement.mutateAsync({
          ...data,
        });
        toast.success('템플릿 양식이 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idTemplateForm) {
      try {
        await deleteElement.mutateAsync(Number(idTemplateForm));
        navigate(-1);
        toggleDeleteModal();
        toast.success('요소가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  // 폭 픽셀 환산값
  const toPixelSize = useMemo(() => {
    if (watchUnitWidth && watchUnitHeight) {
      let cWidth = 0;
      let cHeight = 0;
      switch (watchSizeUnit) {
        case 'MM':
          cWidth = mmToPx(watchUnitWidth, 96);
          cHeight = mmToPx(watchUnitHeight, 96);
          return {
            width: Math.ceil(cWidth),
            height: Math.ceil(cHeight),
            originWidth: cWidth,
            originHeight: cHeight,
          };
        case 'CM':
          cWidth = cmToPx(watchUnitWidth, 96);
          cHeight = cmToPx(watchUnitHeight, 96);
          return {
            width: Math.ceil(cWidth),
            height: Math.ceil(cHeight),
            originWidth: cWidth,
            originHeight: cHeight,
          };
        default:
          return {
            width: watchUnitWidth,
            height: watchUnitHeight,
            originWidth: watchUnitWidth,
            originHeight: watchUnitHeight,
          };
      }
    }
    return null;
  }, [watchSizeUnit, watchUnitWidth, watchUnitHeight]);

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="템플릿 관리" title={`템플릿 양식 ${idTemplateForm ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idTemplateForm ? (
                <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                  삭제
                </Button>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idTemplateForm ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>템플릿 양식 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="이름 - KR"
                    name={'name'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbSelect
                    label="사이즈 단위"
                    name="sizeUnit"
                    options={sizeUnitOption}
                    readonly={defaultData?.used}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  <TbInput
                    label="폭"
                    name={'unitWidth'}
                    message={
                      <div>
                        = <b>{toPixelSize?.width}</b>px (={toPixelSize?.originWidth})
                      </div>
                    }
                    type="number"
                    inputGroupTextRight={watchSizeUnit?.toLowerCase()}
                    readonly={defaultData?.used}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  <TbInput
                    label="높이"
                    name={'unitHeight'}
                    message={
                      <div>
                        = <b>{toPixelSize?.height}</b>px (={toPixelSize?.originHeight})
                      </div>
                    }
                    type="number"
                    inputGroupTextRight={watchSizeUnit?.toLowerCase()}
                    readonly={defaultData?.used}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />

                  {idTemplateForm && (
                    <>
                      <TbDateText label={'생성일'} date={defaultData?.createdDate || ''} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate || ''} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
export default React.memo(TemplateFormUpdate);
