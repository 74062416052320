import React, { useMemo } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useMutation, useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import {
  createSubscriptionLink,
  deleteSubscriptionLink,
  getSubscriptionLink,
  ReqSubscriptionLink,
} from '../../../api/subscriptionLinkAPI';
import { getPlanList } from '../../../api/planAPI';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import TbSelect from '../../../components/common/ui/form/TbSelect';
import TbDatePicker from '../../../components/common/ui/form/TbDatePicker';
import { FORMAT_DATE_TIME_UNIT_BAR } from '../../../constant/format';

interface ModalProps {
  idSubscription: number;
  status: string;
  toggle?: () => void;
  isOpen: boolean;
}

function SubscriptionLinkModal({ idSubscription, status, toggle, isOpen }: ModalProps) {
  const handleApiError = useApiError(); // 에러 처리 훅
  const deleteMutation = useMutation(deleteSubscriptionLink);
  const postMutation = useMutation(createSubscriptionLink);
  const listPeriodType = [
    { label: '월간', value: 'MONTHLY' },
    { label: '연간', value: 'YEARLY' },
  ];

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqSubscriptionLink>({});

  const watchIdPlan = watch('idPlan');
  const watchPeriodType = watch('periodType');

  const { data: subscriptionLink, refetch } = useQuery(
    ['getSubscriptionLink', idSubscription],
    () => getSubscriptionLink(String(idSubscription)),
    {
      enabled: status === 'EXPIRED' && isOpen, // 조건이 맞을 때만 쿼리를 실행
      onSuccess: (data) => {
        if (data) {
          setValue('idPlan', data.relativePlan.id);
          setValue('idSubscription', idSubscription);
          setValue('expiredDate', data.expiredDate);
          setValue('periodType', data.periodType);
        }
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 요금제 목록
   * */
  const { data: listPlan } = useQuery(['getPlanList'], () => getPlanList({ active: true }), {
    select: (data) => {
      return data.map((plan: any) => {
        return {
          label: `${plan.title} : ${plan.description} __월간 : ${plan.price.toLocaleString()}원__/__연간 : ${(
            plan.discountPrice * 12
          ).toLocaleString()}원`,
          value: plan.id,
          data: plan,
          disabled: plan.level === 1 || plan.level === 30 || plan.level === 31,
        };
      });
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    try {
      await postMutation.mutateAsync({
        idSubscription: Number(idSubscription),
        ...data,
        expiredDate: moment(data.expiredDate).format(FORMAT_DATE_TIME_UNIT_BAR),
      });
      toast.success(`링크가 생성되었습니다.`);
      refetch();
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (subscriptionLink?.id) {
      try {
        await deleteMutation.mutateAsync(subscriptionLink?.id);
        toast.success(`링크가 삭제되었습니다.`);
        setValue('idPlan', undefined);
        setValue('expiredDate', undefined);
        setValue('periodType', undefined);
        refetch();
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 최종 결제 금액
   * */
  const resultData = useMemo(() => {
    let amount = 0;
    let credit = 0;
    if (watchIdPlan) {
      const plan = listPlan.find((plan: any) => plan.value === Number(watchIdPlan));
      if (watchPeriodType === 'MONTHLY') {
        amount = plan.data.price;
        credit = plan.data.credit;
      } else if (watchPeriodType === 'YEARLY') {
        amount = plan.data.priceOfYear;
        credit = plan.data.credit * 12;
      }
    }
    return {
      amount,
      credit,
    };
  }, [watchIdPlan, watchPeriodType]);

  const handleCopyLink = () => {
    const copyText = `${process.env.REACT_APP_SERVICE_URL}/payment/${subscriptionLink?.id}`;

    // 클립보드에 복사
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast.success(`링크가 복사되었습니다.`);
      })
      .catch((err) => {
        console.error('복사에 실패했습니다.', err);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-body" centered={true} size={'md'} fade={true}>
      {/* Header */}
      <ModalHeader className={'modal-header'} toggle={toggle}>
        결제 링크 조회
      </ModalHeader>
      {/*   Body  */}
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <ModalBody className="modal-body">
          {status === 'EXPIRED' &&
            (subscriptionLink ? (
              <div>
                <table className="table table-hover table-bordered">
                  <tbody>
                    <tr>
                      <th>요금제</th>
                      <td>
                        ID. {subscriptionLink?.relativePlan?.id} / Lv. {subscriptionLink?.relativePlan?.level} /
                        {subscriptionLink?.relativePlan?.title}
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <th>결제 주기</th>
                      {subscriptionLink?.periodType === 'MONTHLY' && <td>월간</td>}
                      {subscriptionLink?.periodType === 'YEARLY' && <td>연간</td>}
                    </tr>
                    <tr>
                      <th>결제 금액</th>
                      <td>{resultData.amount.toLocaleString()} 원</td>
                    </tr>
                    <tr>
                      <th>링크 만료일</th>
                      <td>
                        {subscriptionLink?.expiredDate
                          ? moment(subscriptionLink?.expiredDate).format('YYYY/MM/DD HH:mm:ss')
                          : ''}
                      </td>
                    </tr>
                    <tr>
                      <th>링크</th>
                      <td>
                        <div className="input-group m-b">
                          <input
                            id="inputLink"
                            type="text"
                            value={`${process.env.REACT_APP_SERVICE_URL}/payment/${subscriptionLink?.id}`}
                            className="form-control"
                            readOnly
                          />
                          <div className="input-group-btn">
                            <button
                              id="btnLinkCopy"
                              type="button"
                              className="btn btn-primary"
                              style={{ marginRight: '10px', backgroundColor: '#d3d3d3' }}
                              onClick={handleCopyLink}
                            >
                              링크복사
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <TbSelect
                  label={'요금제'}
                  name={'idPlan'}
                  options={listPlan}
                  horizontal={true}
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                />
                <TbSelect
                  label={'결제 주기'}
                  name={'periodType'}
                  options={listPeriodType}
                  horizontal={true}
                  register={register}
                  errors={errors}
                  validation={{ required: true }}
                />
                <TbDatePicker
                  label={'링크 만료일'}
                  control={control}
                  name={'expiredDate'}
                  horizontal={true}
                  errors={errors}
                  validation={{ required: true }}
                  showTimeSelect
                  onChange={(date: Date | null) => {
                    if (date) {
                      setValue('expiredDate', date);
                    }
                  }}
                />
                <hr />
                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <div className="col-md-6">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>결제 주기</span>
                      <b>
                        {watchPeriodType === 'MONTHLY' && '월간'}
                        {watchPeriodType === 'YEARLY' && '연간'}
                      </b>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>충전 크레딧</span>
                      <b>{resultData.credit.toLocaleString()} 크레딧</b>
                    </div>
                    <hr />
                    <h5 style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>총 결제 금액</span>
                      <span>{resultData.amount.toLocaleString()} 원</span>
                    </h5>
                  </div>
                </Row>
              </div>
            ))}
          {status === 'SUBSCRIBE' && (
            <div className={'card-body'}>
              <p className={`text-center m-5-15 text-danger`}>구독중인 상태에서는 결제링크 생성이 불가합니다.</p>
            </div>
          )}
        </ModalBody>
        {/* Footer */}
        <ModalFooter className={'modal-footer'}>
          {subscriptionLink && (
            <Button onClick={onSubmitDelete} color="danger">
              삭제
            </Button>
          )}
          {!subscriptionLink && status === 'EXPIRED' && (
            <Button type={'submit'} color="success">
              생성
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default React.memo(SubscriptionLinkModal);
