import React, { Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import TbInput from '../../components/common/ui/form/TbInput';
import Breadcrumb from '../../components/common/breadcrumb';
import TbTextarea from '../../components/common/ui/form/TbTextarea';
import DatePicker from 'react-datepicker';
import { postTask } from '../../api/taskAPI';
import { toast } from 'react-toastify';
import { FORMAT_DATE_TIME_UNIT_BAR } from '../../constant/format';
import moment from 'moment/moment';
import SearchBrand from '../brand/components/SearchBrand';

const TaskProductCreate: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const createMutation = useMutation(postTask);
  const navigate = useNavigate();
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [idBrand, setIdBrand] = useState<number | null>(null);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue('idBrand', idBrand);
  }, [idBrand]);

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    const requestData = {
      idBrand: idBrand,
      title: data.title,
      request: data.request,
      credit: data.credit,
      decision: data.decision,
      note: data.note,
      startDate: moment(startDate).format(FORMAT_DATE_TIME_UNIT_BAR),
      endDate: moment(endDate).format(FORMAT_DATE_TIME_UNIT_BAR),
    };
    //  Create (POST)
    try {
      await createMutation.mutateAsync(requestData);
      toast.success('작업이 성공적으로 완료되었습니다.');
      navigate(-1);
    } catch (error) {
      handleApiError(error);
    }
  };

  //  숫자만 입력할 수 있도록 문자 입력 방지하는 onChange 핸들러
  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    // 숫자만 입력할 수 있도록 필터링
    // 숫자만 남기고 모든 문자 제거
    e.target.value = value.replace(/[^0-9]/g, '');
  };

  //  =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="디자이너 작업" title={'디자이너 작업 생성'}>
          <div className="mb-0">
            <Button color="secondary" className="me-3" type="submit">
              생성
            </Button>
            <Button onClick={() => navigate(-1)} color="primary">
              목록
            </Button>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="4">
              <Card>
                <div className="card-header">
                  <h5>요청 설정</h5>
                </div>
                <CardBody>
                  {/* 브랜드 id 검색 */}
                  <SearchBrand onSelect={setIdBrand} />
                  <TbInput
                    label="브랜드 ID"
                    name={'idBrand'}
                    type="text"
                    register={register}
                    disabled
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="요청 제목"
                    name={'title'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbTextarea
                    label="요청 사항"
                    id="request"
                    name={'request'}
                    placeholder="확정 작업 내용"
                    rows={8}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="크레딧"
                    name={'credit'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                    onChange={handleNumberInputChange} // // 문자 입력을 막는 onChange 핸들러
                  />
                </CardBody>
              </Card>
            </Col>
            <Col sm="8">
              <Card>
                <div className="card-header">
                  <h5>작업 내용</h5>
                </div>
                <CardBody>
                  <TbTextarea
                    label="확정 작업 내용"
                    id="memo"
                    name={'decision'}
                    placeholder="확정 작업 내용"
                    rows={8}
                    register={register}
                    errors={errors}
                  />
                  <TbTextarea
                    label="관리자 메모"
                    id="memo"
                    name={'note'}
                    placeholder="관리자 메모"
                    rows={8}
                    register={register}
                    errors={errors}
                  />
                  <div className={'mb-3'}>
                    <div className={'mb-1'}>시작 시간</div>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      showTimeSelect
                      dateFormat={FORMAT_DATE_TIME_UNIT_BAR}
                      onChange={(date: Date | null) => setStartDate(date)}
                    />
                  </div>
                  <div>
                    <div className={'mb-1'}>종료 시간</div>
                    <DatePicker
                      className="form-control"
                      selected={endDate}
                      showTimeSelect
                      dateFormat={FORMAT_DATE_TIME_UNIT_BAR}
                      onChange={(date: Date | null) => setEndDate(date)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default TaskProductCreate;
