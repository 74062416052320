import TbSearch from '../../../components/common/ui/TbSearch';
import React, { useCallback, useState } from 'react';
import useApiError from '../../../hooks/useApiError';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { getFontFamilyPage } from '../../../api/fontAPI';
import { Button } from 'reactstrap';

interface DefaultProps {
  onSelect?: (brand: any) => void;
}

const SearchFontFace = ({ onSelect }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [query, setQuery] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);

  // GET ::: Brand ID
  useQuery(
    ['getFontFamilyPage', { query }],
    () =>
      getFontFamilyPage({
        query,
        page: 0,
        size: 30,
      }),
    {
      enabled: !!query && query.length > 2,
      onSuccess: (data) => {
        const resultArr: any[] = [];
        data?._embedded?.fontFamilies.map((fontFamily: any) => {
          fontFamily?.fontFaces?.map((fontFace: any) => {
            resultArr.push({
              value: fontFace.idFontFace,
              label: `${fontFamily.name} (${fontFace.weightValue})`,
              data: {
                fontFamily: fontFamily,
                fontFace: {
                  ...fontFace,
                  fontFamily: fontFamily,
                },
              },
            });
          });
        });
        setSearchResults(resultArr);
      },
      onError: (error: any) => {
        handleApiError(error);
      },
    },
  );

  // Search
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value) {
        setQuery(value);
      }
    }, 700),
    [],
  );

  // onChange
  const handleSearchKeyword = (value: string) => {
    debouncedSearch(value);
  };

  return (
    <>
      <TbSearch
        label={'폰트 패밀리명으로 검색하세요.'}
        placeholder="ex) Pretendard"
        onChange={handleSearchKeyword}
        searchData={searchResults}
        onSelectValue={(value, data) => {
          setSelectedData(data);
        }}
      />
      <div className={'text-center'}>
        <Button
          onClick={() => {
            if (selectedData) {
              onSelect?.(selectedData);
            }
          }}
          color={'primary'}
        >
          폰트 선택
        </Button>
      </div>
    </>
  );
};
export default React.memo(SearchFontFace);
