import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import TbLoading from '../../../components/common/ui/TbLoading';
import React, { Fragment, useEffect, useState } from 'react';
import useApiError from '../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { ReqBrandVisualTypography, ResBrandVisualTypography, updateBrandVisualTypography } from '../../../api/brandAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbInput from '../../../components/common/ui/form/TbInput';
import TbModal from '../../../components/common/ui/TbModal';
import SearchFontFace from '../../fonts/components/SearchFontFace';
import { ResFontFace } from '../../../api/fontFaceAPI';

interface DefaultProps {
  idBrand: string;
  defaultData: ResBrandVisualTypography;
}
const BrandTypographyUpdate = ({ idBrand, defaultData }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateMutate = useMutation(updateBrandVisualTypography);
  const [isSearchFontModal, setIsSearchFontModal] = useState(false);
  const [fontType, setFontType] = useState<'HeaderKo' | 'BodyKo' | 'HeaderEn' | 'BodyEn' | ''>('');
  // 현재 선택된 폰트페이스 표기용으로 사용.
  const [currentFontFace, setCurrentFontFace] = useState<{
    fontFaceHeaderEn?: ResFontFace;
    fontFaceBodyEn?: ResFontFace;
    fontFaceHeaderKo?: ResFontFace;
    fontFaceBodyKo?: ResFontFace;
  }>();

  // Form
  const {
    // control,
    register,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<ReqBrandVisualTypography>({
    defaultValues: {},
  });

  /* 초기 데이터 설정 */
  useEffect(() => {
    setValue('idFontFaceHeaderEn', defaultData.fontFaceHeaderEn?.idFontFace);
    setValue('idFontFaceBodyEn', defaultData.fontFaceBodyEn?.idFontFace);
    setValue('idFontFaceHeaderKo', defaultData.fontFaceHeaderKo?.idFontFace);
    setValue('idFontFaceBodyKo', defaultData.fontFaceBodyKo?.idFontFace);

    setCurrentFontFace({
      ...defaultData,
    });
  }, [defaultData, setValue]);

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    console.log(data, idBrand);
    setIsLoading(true);
    //Update
    try {
      await updateMutate.mutateAsync({
        idBrand: idBrand,
        body: { ...data } as ReqBrandVisualTypography,
      });
      toast.success('타이포그래피 수정이 완료되었습니다.');
      // navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * 폰트 검색 및 선택 결과를 폼에 반영.
   * */
  const onSelectFont = (data: any) => {
    if (fontType === 'HeaderEn') {
      setValue('idFontFaceHeaderEn', data.fontFace.idFontFace);
      setCurrentFontFace({
        ...currentFontFace,
        fontFaceHeaderEn: data.fontFace,
      });
    } else if (fontType === 'BodyEn') {
      setValue('idFontFaceBodyEn', data.fontFace.idFontFace);
      setCurrentFontFace({
        ...currentFontFace,
        fontFaceBodyEn: data.fontFace,
      });
    } else if (fontType === 'HeaderKo') {
      setValue('idFontFaceHeaderKo', data.fontFace.idFontFace);
      setCurrentFontFace({
        ...currentFontFace,
        fontFaceHeaderKo: data.fontFace,
      });
    } else if (fontType === 'BodyKo') {
      setValue('idFontFaceBodyKo', data.fontFace.idFontFace);
      setCurrentFontFace({
        ...currentFontFace,
        fontFaceBodyKo: data.fontFace,
      });
    }
    setIsSearchFontModal(false);
  };

  return (
    <Fragment>
      {/** 폰트 검색 모달 */}
      <TbModal
        isOpen={isSearchFontModal}
        headerTitle={'폰트 검색'}
        isShowFooter={false}
        toggle={() => setIsSearchFontModal(!isSearchFontModal)}
      >
        {isSearchFontModal && <SearchFontFace onSelect={onSelectFont} />}
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Card>
          <CardHeader>
            <h5>비주얼 - 타이포그래피</h5>
            <Button color="secondary" type="submit" disabled={isLoading}>
              {isLoading ? <TbLoading /> : '수정'}
            </Button>
          </CardHeader>
          <CardBody>
            {/* 영문 제목(대표 제목) */}
            <TbInput
              label="영문 제목(대표 제목)"
              placeholder={'폰트를 선택하세요'}
              type={'text'}
              readonly={true}
              defaultValue={
                currentFontFace?.fontFaceHeaderEn
                  ? `${currentFontFace?.fontFaceHeaderEn?.fontFamily?.name} - ${currentFontFace?.fontFaceHeaderEn?.weightValue}`
                  : ''
              }
              onClick={() => {
                setFontType('HeaderEn');
                setIsSearchFontModal(true);
              }}
            />
            <TbInput name={'idFontFaceHeaderEn'} type="hidden" register={register} errors={errors} />
            {/* 영문 본문(대표 본문) */}
            <TbInput
              label="영문 본문(대표 본문)"
              placeholder={'폰트를 선택하세요'}
              type={'text'}
              readonly={true}
              defaultValue={
                currentFontFace?.fontFaceBodyEn
                  ? `${currentFontFace?.fontFaceBodyEn?.fontFamily?.name} - ${currentFontFace?.fontFaceBodyEn?.weightValue}`
                  : ''
              }
              onClick={() => {
                setFontType('BodyEn');
                setIsSearchFontModal(true);
              }}
            />
            <TbInput name={'idFontFaceBodyEn'} type="hidden" register={register} errors={errors} />
            {/* 국문 제목(대표 제목) */}
            <TbInput
              label="국문 제목(보조 제목)"
              placeholder={'폰트를 선택하세요'}
              type={'text'}
              readonly={true}
              defaultValue={
                currentFontFace?.fontFaceHeaderKo
                  ? `${currentFontFace?.fontFaceHeaderKo?.fontFamily?.name} - ${currentFontFace?.fontFaceHeaderKo?.weightValue}`
                  : ''
              }
              onClick={() => {
                setFontType('HeaderKo');
                setIsSearchFontModal(true);
              }}
            />
            <TbInput name={'idFontFaceHeaderKo'} type="hidden" register={register} errors={errors} />
            {/* 국문 본문(보조 본문) */}
            <TbInput
              label="국문 본문(보조 본문)"
              placeholder={'폰트를 선택하세요'}
              type={'text'}
              readonly={true}
              defaultValue={
                currentFontFace?.fontFaceBodyKo
                  ? `${currentFontFace?.fontFaceBodyKo?.fontFamily?.name} - ${currentFontFace?.fontFaceBodyKo?.weightValue}`
                  : ''
              }
              onClick={() => {
                setFontType('BodyKo');
                setIsSearchFontModal(true);
              }}
            />
            <TbInput name={'idFontFaceBodyKo'} type="hidden" register={register} errors={errors} />
          </CardBody>
        </Card>
      </Form>
    </Fragment>
  );
};

export default React.memo(BrandTypographyUpdate);
