import React, { Fragment, useEffect } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
// import ThemeCustomizer from './common/theme-customizer';
import { ToastContainer } from 'react-toastify';
// import Loader from "./common/loader";
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSetting } from '../store/reducers/settingSlice';
import { AppDispatch, RootState } from '../store';
import TbGlobalModal from './common/ui/TbGlobalModal';

const AppLayout: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const setting = useSelector((state: RootState) => state.setting);

  // 처음 구동 시 설정(setting) API 호출
  useEffect(() => {
    if (!setting?.data) {
      dispatch(fetchSetting());
    }
  }, []);

  return (
    <Fragment>
      {/* <Loader /> */}
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <Sidebar />
          <RightSidebar />
          <div className="page-body">
            <Outlet />
          </div>
          <Footer />
          {/*<ThemeCustomizer />*/}

          {/* ::::: 공통 모달 :::::   */}
          <TbGlobalModal />
        </div>
      </div>
      <ToastContainer position={'top-center'} autoClose={3000} />
    </Fragment>
  );
};

export default AppLayout;
