import React, { Fragment, useRef, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import { useForm } from 'react-hook-form';
import {
  EditorElementType,
  getEditorElementCategoryPage,
  getEditorElementCollectionPage,
  ReqEditorElementCategoryPage,
  ReqEditorElementCollectionPage,
} from '../../api/editorElementAPI';
import TbFileInput from '../../components/common/ui/form/TbInputFile';
import BatchImageItem, { EditorElementItem } from './components/BatchImageItem';
import TbCheckbox from '../../components/common/ui/form/TbCheckbox';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import TbInputTag from '../../components/common/ui/form/TbInputTag';
import { longTermCacheConfig } from '../../data/customizer/config';

export interface FormEditorElement {
  type: EditorElementType | '';
  idCategories?: number[];
  idCollections?: number[];
  tagList?: string[];
  files?: File[];
}

const EditorElementBatch = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [isRetry, setIsRetry] = useState(false);
  const [listElement, setListElement] = useState<EditorElementItem[] | undefined>([]);
  const [listQueue, setlistQueue] = useState<number[]>([]);
  const listQueueRef = useRef<number[]>([]);

  const [reqCategoryParams] = useState<ReqEditorElementCategoryPage>({
    page: 0,
    size: 1000,
  });
  const [reqCollectionParams] = useState<ReqEditorElementCollectionPage>({
    page: 0,
    size: 1000,
  });

  /**
   * 카테고리 목록 요청.
   * */
  const { data: resCategories } = useQuery(
    ['getEditorElementCategoryPage'],
    () => getEditorElementCategoryPage(reqCategoryParams),
    {
      ...longTermCacheConfig,
      select: (data) => {
        return data?._embedded?.categories?.map((item: any) => ({
          label: item.name,
          value: item.idElementCategory,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 컬렉션 목록 요청.
   * */
  const { data: resCollections } = useQuery(
    ['getEditorElementCollectionPage'],
    () => getEditorElementCollectionPage(reqCollectionParams),
    {
      ...longTermCacheConfig,
      select: (data) => {
        return data?._embedded?.collections?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // Form
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormEditorElement>();

  /**
   * 폼 서브밋
   * */
  const onSubmit = async (data: FormEditorElement) => {
    setIsRetry(true);

    setListElement(
      Array.from(data.files || [])?.map((file) => {
        return {
          type: data.type,
          idCategories: data.idCategories,
          idCollections: data.idCollections,
          tagList: data.tagList,
          file: file,
        } as EditorElementItem;
      }),
    );
  };

  const handleReset = () => {
    reset({
      type: '',
      idCategories: [],
      idCollections: [],
      tagList: [],
      files: [],
    });
    setListElement([]);
    setIsRetry(false);
  };

  return (
    <Fragment>
      <Breadcrumb parent="에디터 요소" title="요소 일괄 업로드">
        <div className="mb-0">
          {/*<Button color="primary" className="me-3" type="submit">*/}
          {/*  저장*/}
          {/*</Button>*/}
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm={4}>
            <Card>
              <CardHeader>
                <h5>에디터 요소 일괄 업로드(비트맵, 사진)</h5>
              </CardHeader>
              <CardBody>
                <h6>[NOTE]</h6>
                <ol>
                  <li>비트맵, 사진 요소만 일괄 업로드 가능합니다.</li>
                </ol>
                <hr />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TbSelect
                    label={'요소 타입'}
                    name={'type'}
                    options={[
                      { label: '사진', value: 'IMAGE' },
                      { label: '비트맵', value: 'BITMAP' },
                    ]}
                    horizontal={true}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <FormGroup>
                    <Label>
                      카테고리<span className={'text-danger'}>(필수)</span>
                    </Label>
                    <div>
                      {resCategories?.map((item: any) => (
                        <TbCheckbox
                          key={item.value}
                          label={item.label}
                          name={'idCategories'}
                          value={item.value}
                          type={'checkbox'}
                          horizontal={true}
                          validation={{ required: true }}
                          register={register}
                          errors={errors}
                        />
                      ))}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>컬렉션</Label>
                    <div>
                      {resCollections?.map((item: any) => (
                        <TbCheckbox
                          key={item.value}
                          label={item.label}
                          name={'idCollections'}
                          value={item.value}
                          type={'checkbox'}
                          horizontal={true}
                          register={register}
                          errors={errors}
                        />
                      ))}
                    </div>
                  </FormGroup>
                  <TbInputTag control={control} label="필수 키워드" name={'tagList'} errors={errors} />
                  <TbFileInput
                    label={'이미지 선택'}
                    name={'files'}
                    horizontal={true}
                    multiple={true}
                    accept={'.jpeg, .jpg, .png'}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <div className={'text-end'}>
                    {isRetry ? (
                      <Button color="warning" className="" onClick={() => handleReset()}>
                        업로드 리셋
                      </Button>
                    ) : (
                      <Button color="primary" className="" type="submit">
                        업로드
                      </Button>
                    )}
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={8}>
            <Card>
              <CardBody>
                <h6>
                  업로드 현황 {(listElement?.length || 0) - listQueue.length}/{listElement?.length}
                </h6>
                <br />
                <Row>
                  {listElement?.map((item) => (
                    <Col sm={2} key={item.file.name} className={'mb-3'}>
                      <BatchImageItem
                        data={item}
                        listQueue={listQueue}
                        onInitTask={(idEditorElement: number) => {
                          const newArr = [...listQueueRef.current, idEditorElement] as number[];
                          listQueueRef.current = newArr;
                          setlistQueue(newArr);
                        }}
                        onCompleteTask={(idEditorElement: number) => {
                          const newArr = listQueueRef.current.filter((item) => item !== idEditorElement);
                          listQueueRef.current = newArr;
                          setlistQueue(newArr);
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default React.memo(EditorElementBatch);
