import React, { Fragment, useState } from 'react';
import TbModal from '../../components/common/ui/TbModal';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import {
  createBundleCollection,
  deleteBundleCollection,
  getBundleCollection,
  ReqBundleCollection,
  updateBundleCollection,
} from '../../api/bundleCollectionAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbLoading from '../../components/common/ui/TbLoading';
import Breadcrumb from '../../components/common/breadcrumb';
import TbInput from '../../components/common/ui/form/TbInput';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/form/TbDateText';
import TbInputUploadImage from '../../components/common/ui/form/file/TbResourceFileUpload';
import BundleList from './components/BundleList';

const BundleCollectionUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idBundleCollection } = useParams<{ idBundleCollection: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteBundleCollection);
  const createMutate = useMutation(createBundleCollection);
  const updateMutate = useMutation(updateBundleCollection);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<ReqBundleCollection>({
    defaultValues: {
      ...(!idBundleCollection
        ? // 생성일 경우 초기값.
          {
            top: false,
            active: false,
          }
        : {}),
    },
  });

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(
    ['getBundleCollection', idBundleCollection],
    () => getBundleCollection(Number(idBundleCollection)),
    {
      enabled: !!idBundleCollection,
      select: (data) => {
        return {
          ...data,
        };
      },
      onSuccess: (data) => {
        setValue('name', data.name);
        setValue('thumbnail', data.thumbnail);
        setValue('fallbackThumb', data.fallbackThumb);
        setValue('infoImages', data.infoImages);
        setValue('top', data.top);
        setValue('active', data.active);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (idBundleCollection && Number(idBundleCollection) > 0) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idBundleCollection: Number(idBundleCollection),
          body: { ...data } as ReqBundleCollection,
        });
        toast.success('번들 컬렉션 수정이 완료되었습니다.');
        navigate(location?.state?.from ? location.state.from : `/admin/bundle-collection/list`);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          ...data,
        });
        toast.success('번들 컬렉션 생성이 완료되었습니다.');
        navigate(location?.state?.from ? location.state.from : `/admin/bundle-collection/list`);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idBundleCollection) {
      try {
        await deleteMutate.mutateAsync(Number(idBundleCollection));
        navigate(`/admin/bundle-collection/list`);
        toggleDeleteModal();
        toast.success('번들 컬렉션 삭제가 완료되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    if (defaultData.count > 0) {
      toast.warning('하위 번들이 존재합니다.');
      return;
    }
    setIsDeleteModal(!isDeleteModal);
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="번들 관리" title={`번들 컬렉션 ${idBundleCollection ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idBundleCollection ? (
                <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                  삭제
                </Button>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idBundleCollection ? '수정' : '생성'}
              </Button>
              <Button
                onClick={() => {
                  navigate(location?.state?.from ? location.state.from : `/admin/bundle-collection/list`);
                }}
                color="primary"
              >
                목록
              </Button>
            </div>
          </Breadcrumb>

          {/* ::: 양식 :::*/}
          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>번들 컬렉션 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="번들 컬렉션 이름"
                    name={'name'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  {idBundleCollection && (
                    <>
                      <TbInputUploadImage
                        label={'영상 썸네일'}
                        type={'VIDEO'}
                        path={`market_thumb/collection/${idBundleCollection}`}
                        control={control}
                        message={'1MB 이하 MP4 형식만 업로드 가능합니다.'}
                        accept={'.mp4'}
                        name={'thumbnail'}
                        maxSize={1}
                        initialData={defaultData?.thumbnail}
                        errors={errors}
                      />
                      <TbInputUploadImage
                        label={'영상 대체 썸네일'}
                        type={'IMAGE'}
                        path={`market_thumb/collection/${idBundleCollection}`}
                        control={control}
                        message={'1MB 이하 JPG, JPEG, PNG, GIF 형식만 업로드 가능합니다.'}
                        accept={'.jpeg, .jpg, .png, .gif'}
                        name={'fallbackThumb'}
                        maxSize={1}
                        initialData={defaultData?.fallbackThumb}
                        errors={errors}
                      />

                      <TbInputUploadImage
                        type={'IMAGE'}
                        path={`market_thumb/collection/${idBundleCollection}`}
                        control={control}
                        label={'상세 설명 이미지'}
                        message={
                          <>
                            3MB 이하 JPG, JPEG, PNG형식만 업로드 가능합니다.(최대 20장)
                            <br />* 드래그로 순서 변경 가능.
                          </>
                        }
                        accept={'.jpeg, .jpg, .png'}
                        name={'infoImages'}
                        maxSize={3}
                        multiple={true}
                        sortable={true}
                        maxCount={20}
                        initialData={defaultData?.infoImages}
                        errors={errors}
                      />
                    </>
                  )}
                </CardBody>
              </Card>

              {idBundleCollection && (
                <>
                  <BundleList
                    idBundleCollection={Number(idBundleCollection)}
                    mainBundleId={defaultData?.mainBundleId}
                  />
                </>
              )}
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  {idBundleCollection && (
                    <>
                      <TbRadioBoolean
                        control={control}
                        message={'활성일 경우 최상단에 노출됩니다.'}
                        label={'상단 고정 여부'}
                        name="top"
                        register={register}
                      />
                      <TbRadioBoolean
                        control={control}
                        message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                        label={'게시 상태'}
                        name="active"
                        register={register}
                      />
                      <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default React.memo(BundleCollectionUpdate);
