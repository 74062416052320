import APIClient from './APIClient';
import { AxiosResponse } from 'axios';

export interface ResDashboard {
  subscribeCount?: number;
  userCount?: number;
  userCountOfMonth?: number;
  brandCount?: number;
}

/**
 * 대시보드 데이터 조회
 * */
export const getDashboard = async () => {
  const response: AxiosResponse<ResDashboard> = await APIClient.get(`/v2/api/dashboard`);
  return response.data;
};
