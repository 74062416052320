import React from 'react';
import moment from 'moment';

export const paymentColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    center: true,
    minWidth: '50px',
    cell: (row: any) => <div className={'text-left'}>ID.{row.id}</div>,
  },
  {
    name: 'PID(결제번호)',
    selector: (row: any) => row.pid,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: '결제 내용',
    selector: (row: any) => row.title,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '100px',
  },
  {
    name: '결제 금액',
    selector: (row: any) => row.amount,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
  },
  {
    name: '결제 상태',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
    cell: (row: any) => (
      <div>
        {row.orderStatus === 'INIT' && <span className={'badge badge-info'}>결제 대기</span>}
        {row.orderStatus === 'PAID' && <span className={'badge badge-success'}>결제 완료</span>}
        {row.orderStatus === 'FAILED' && <span className={'badge badge-danger'}>결제 실패</span>}
        {row.orderStatus === 'REFUND' && <span className={'badge badge-warning'}>환불 완료</span>}
      </div>
    ),
  },
  {
    name: '결제 방법',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '80px',
    cell: (row: any) => (
      <div>
        {row.type === 'CARD' && <span>신용카드</span>}
        {row.type === 'CASH' && <span>현금</span>}
        {row.type === 'PAYPAL' && <span>페이팔</span>}
      </div>
    ),
  },
  {
    name: '결제일',
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
    cell: (row: any) => (
      <div style={{ whiteSpace: 'nowrap' }}>
        시작일: {row.paymentDate ? moment(row.paymentDate).format('YYYY/MM/DD HH:mm:ss') : '-'}
        <br />
        종료일: {row.paymentDate ? moment(row.paymentDate).format('YYYY/MM/DD HH:mm:ss') : '-'}
      </div>
    ),
  },
];
