import React, { Fragment, useState } from 'react';
import TbModal from '../../components/common/ui/TbModal';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import TbLoading from '../../components/common/ui/TbLoading';
import { useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import {
  createTemplate,
  deleteTemplate,
  getTemplate,
  ReqTemplate,
  TemplateCreatedType,
  updateTemplate,
} from '../../api/templateAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbInput from '../../components/common/ui/form/TbInput';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/form/TbDateText';
import TbInputUploadImage from '../../components/common/ui/form/file/TbResourceFileUpload';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbFormText from '../../components/common/ui/form/TbFormText';
import TbInputTag from '../../components/common/ui/form/TbInputTag';
import TbInputColor from '../../components/common/ui/form/TbInputColor';
import TBGenColorPalette from '../../components/common/ui/TBGenColorPalette';
import { getTemplateFormPage } from '../../api/templateFormAPI';
import { longTermCacheConfig } from '../../data/customizer/config';
import TbTextarea from '../../components/common/ui/form/TbTextarea';
import ManageBrand from '../subscription/components/ManageBrand';
import BtnEditTemplate from './component/BtnEditTemplate';
import TbGenKeywords from '../../components/common/ui/TbGenKeywords';
import SearchBrand from '../brand/components/SearchBrand';

const TemplateUpdate = ({ createdType }: { createdType: TemplateCreatedType }) => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idTemplate } = useParams<{ idTemplate: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteTemplate);
  const createMutate = useMutation(createTemplate);
  const updateMutate = useMutation(updateTemplate);
  const [brandInfo, setBrandInfo] = useState<any>(null);
  // 변환 함수
  const createdOptions = [
    { label: '사용자-개인', value: 'USER' },
    { label: '사용자-브랜드', value: 'BRAND' },
    { label: '마켓', value: 'MARKET' },
  ];

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqTemplate>({
    defaultValues: {
      ...(!idTemplate
        ? // 생성일 경우 초기값.
          {
            createdType: createdType,
            top: false,
            active: false,
          }
        : {}),
    },
  });
  const watchThumbnail = watch('thumbnail');
  const watchKeywordList = watch('keywordList');

  /**
   * 양식 목록 요청.
   * */
  const { data: formList } = useQuery(
    ['getTemplateFormPage'],
    () =>
      getTemplateFormPage({
        size: 1000,
      }),
    {
      ...longTermCacheConfig,
      select: (data) => {
        return data?._embedded?.templateForms.map((form: any) => {
          return {
            label: form.name + `- ${form.unitWidth}x${form.unitHeight}${form.sizeUnit?.toLowerCase()}`,
            value: form.idTemplateForm,
          };
        });
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(['getTemplate', idTemplate], () => getTemplate(idTemplate!), {
    enabled: !!idTemplate,
    select: (data) => {
      return {
        ...data,
      };
    },
    onSuccess: (data) => {
      setValue('idBrand', data.idBrand);
      setValue('name', data.name);
      setValue('thumbnail', data.thumbnail);

      setValue('idForm', data.form.idTemplateForm);

      setValue('createdType', data.createdType);
      setValue('keywordList', data.keywordList);
      setValue('color', data.colorGroups?.[0]?.displayColor);

      setValue('top', data.top);
      setValue('active', data.active);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (idTemplate) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idTemplate: idTemplate,
          body: { ...data } as ReqTemplate,
        });
        toast.success('템플릿 수정이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          ...data,
        });
        toast.success('템플릿이 생성되었습니다.');
        if (createdType === 'MARKET') {
          navigate(`/admin/template-event/list`);
        } else {
          navigate(`/admin/template-brand/list`);
        }
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idTemplate) {
      try {
        await deleteMutate.mutateAsync(idTemplate);
        navigate(-1);
        toggleDeleteModal();
        toast.success('템플릿이 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb
            parent={`${createdType === 'BRAND' ? '브랜드' : '마켓'} 템플릿`}
            title={`${createdType === 'BRAND' ? '브랜드' : '마켓'} 템플릿 ${idTemplate ? '수정' : '생성'}`}
          >
            <div className="mb-0">
              {idTemplate ? (
                <>
                  <BtnEditTemplate idTemplate={idTemplate} email={brandInfo?.email} />
                  <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                    삭제
                  </Button>
                </>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idTemplate ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>템플릿 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="템플릿 이름"
                    name={'name'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  {idTemplate ? (
                    <>
                      <TbInputUploadImage
                        label={'썸네일'}
                        type={'IMAGE'}
                        path={createdType === 'MARKET' ? `market_thumb/template/tidy_${idTemplate}` : `template_thumb`}
                        control={control}
                        message={'1MB 이하 JPG, JPEG, PNG, GIF 형식만 업로드 가능합니다.'}
                        accept={'.jpeg, .jpg, .png, .gif'}
                        name={'thumbnail'}
                        readonly={createdType !== 'MARKET'}
                        maxSize={1}
                        initialData={defaultData?.thumbnail}
                        errors={errors}
                      />

                      {defaultData?.design ? (
                        <TbTextarea
                          label={`다자인 바디 (${defaultData?.design?.unitWidth}x${
                            defaultData?.design?.unitHeight
                          }${defaultData?.design?.sizeUnit.toLowerCase()})`}
                          rows={10}
                          readonly={true}
                          defaultValue={defaultData?.design?.body}
                        ></TbTextarea>
                      ) : (
                        <TbFormText label={'다자인 바디'} value={'생성된 디자인 바디가 없습니다.'} />
                      )}
                    </>
                  ) : (
                    <TbTextarea label="다자인 바디" rows={10} name={'body'} register={register} errors={errors} />
                  )}
                </CardBody>
              </Card>

              {/* ::::::::::::: 브랜드 정보 ::::::::::: */}
              {idTemplate && (
                <ManageBrand idBrand={defaultData?.idBrand.toString()} onLoad={(data) => setBrandInfo(data)} />
              )}
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <SearchBrand
                    onSelect={(idBrand) => setValue('idBrand', idBrand)}
                    isMarket={createdType === 'MARKET'}
                  />
                  <TbInput
                    label="브랜드 ID"
                    name={'idBrand'}
                    type="text"
                    register={register}
                    disabled
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbSelect
                    label="생성 타입"
                    name="createdType"
                    options={createdOptions}
                    readonly={true}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbSelect
                    label="양식"
                    name="idForm"
                    options={formList}
                    readonly={!!idTemplate}
                    register={register}
                    errors={errors}
                    validation={{ required: !idTemplate }}
                  />
                  {idTemplate && createdType === 'MARKET' && (
                    // 수정 이면서 마켓 템플릿인 경우만
                    <>
                      <TbFormText label={'삭제 상태'} value={defaultData?.deletedType} />
                      <TbInputTag
                        control={control}
                        label="검색 키워드"
                        message={
                          <TbGenKeywords
                            url={watchThumbnail}
                            onComplete={(data) => {
                              setValue('keywordList', [...(watchKeywordList || []), ...data]);
                            }}
                          />
                        }
                        name={'keywordList'}
                        errors={errors}
                      />
                      <TbInputColor
                        label={'검색 컬러 지정'}
                        name={'color'}
                        message={"'#000000'은 적용되지 않습니다."}
                        register={register}
                        errors={errors}
                        validate={(value: any) => {
                          return !value || value === '#000000' ? '검색 컬러를 지정하세요.' : true;
                        }}
                      />
                      {/** 컬러 추출 */}
                      {watchThumbnail && (
                        <TBGenColorPalette
                          url={watchThumbnail}
                          onSelect={(hex) => {
                            setValue('color', hex);
                          }}
                        />
                      )}
                    </>
                  )}

                  <hr className={'my-4'} />
                  <TbRadioBoolean control={control} label={'상단 고정'} name="top" register={register} />
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  {idTemplate && (
                    <>
                      <TbDateText label={'디자인 최종 업데이트'} date={defaultData?.designUpdatedDate} />
                      <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
export default React.memo(TemplateUpdate);
