import React, { useState } from 'react';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import moment from 'moment/moment';
import { formatPrice } from '../../../utils/formatUtils';
import ManageSubscribeCancelModal from './ManageSubscribeCancelModal';
import ManageSubscribeResetModal from './ManageSubscribeResetModal';
import { toast } from 'react-toastify';
import ManageSubscribeModal from './ManageSubscribeModal';
import ManageSubscribeExpireModal from './ManageSubscribeExpireModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { DropdownDivider, DropdownHeader } from 'react-bootstrap';
import { FORMAT_DATE_TIME_UNIT_BAR, FORMAT_DATE_UNIT_BAR } from '../../../constant/format';

interface PropsType {
  subscription?: any;
  refetchSubscription?: () => void;
}
/**
 * 구독 상세 정보
 * (구독 상세 페이지에 공통으로 사용됨)
 * */
function ManageSubscription({ subscription, refetchSubscription }: PropsType) {
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const [dropdownOpen, setOpen] = useState(false);
  const [isSubscribeModal, setIsSubscribeModal] = useState(false);
  const [isExpireModal, setIsExpireModal] = useState(false);
  const [isRestModal, setIsResetModal] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [subscribePreset, setSubscribePreset] = useState<any>(null);

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  return (
    <div>
      {subscription && (
        <>
          <ManageSubscribeModal
            isOpen={isSubscribeModal}
            toggle={() => setIsSubscribeModal(!isSubscribeModal)}
            idSubscription={subscription?.id}
            defaultValue={subscribePreset}
            onSuccess={() => {
              if (refetchSubscription) {
                refetchSubscription();
              }
            }}
          />
          <ManageSubscribeExpireModal
            isOpen={isExpireModal}
            toggle={() => setIsExpireModal(!isExpireModal)}
            idSubscription={subscription?.id}
            onSuccess={() => {
              if (refetchSubscription) {
                refetchSubscription();
              }
            }}
          />
          <ManageSubscribeCancelModal
            isOpen={isCancelModal}
            toggle={() => setIsCancelModal(!isCancelModal)}
            defaultValue={{
              idSubscription: subscription?.id,
              canceled: subscription?.canceled,
              cancelDate: subscription?.cancelDate,
            }}
            onSuccess={() => {
              if (refetchSubscription) {
                refetchSubscription();
              }
            }}
          />
          <ManageSubscribeResetModal
            isOpen={isRestModal}
            toggle={() => setIsResetModal(!isRestModal)}
            idSubscription={subscription?.id}
            onSuccess={() => {
              if (refetchSubscription) {
                refetchSubscription();
              }
            }}
          />
        </>
      )}
      <div className="card">
        <div className={'card-header'}>
          <h5>구독정보</h5>
          {subscription && (
            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle size={'sm'} color="dark" outline={true}>
                <span>
                  작업 <i className="fa fa-caret-down"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    if (subscription.status === 'EXPIRED' && subscription.type === 'FREE') {
                      setSubscribePreset({
                        idPlan: 2,
                        type: 'PAID',
                        periodType: 'MONTHLY',
                        title: '로고 제작 고객(브랜드 자산 관리 SaaS 1년 이용권)',
                        periodMonth: 12, // 최초 구독 주기
                        contractMonth: 12, // 약정 개월 수
                      });
                      setIsSubscribeModal(true);
                    } else {
                      toast.warning('무료 요금제인 경우만 설정 가능합니다.');
                    }
                  }}
                >
                  {'베이직 1년 구독 시작'}
                </DropdownItem>
                <DropdownItem onClick={() => setIsCancelModal(true)}>{'구독 취소 설정'}</DropdownItem>

                {(userRole === 'ROLE_SUPER' || userRole === 'ROLE_ADMIN') && (
                  <>
                    <DropdownDivider />
                    <DropdownHeader>어드민 기능</DropdownHeader>
                    <DropdownItem
                      onClick={() => {
                        if (subscription.status === 'EXPIRED') {
                          setSubscribePreset(null);
                          setIsSubscribeModal(true);
                        } else {
                          toast.warning('현재 구독 상태 입니다.');
                        }
                      }}
                    >
                      {'구독 시작 설정'}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        subscription.status === 'SUBSCRIBE'
                          ? setIsExpireModal(true)
                          : toast.warning('현재 구독 만료 상태 입니다.');
                      }}
                    >
                      {'구독 만료 설정'}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        subscription.status === 'EXPIRED'
                          ? setIsResetModal(true)
                          : toast.warning('구독 만료 시 초기화 가능합니다.')
                      }
                    >
                      {'구독 초기화(무료 전환)'}
                    </DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        <div className={'card-body'}>
          {!subscription ? (
            <p className={'text-center m-t-15 text-danger'}>브랜드를 연결하세요.</p>
          ) : (
            <Row className="details">
              <Col xs="6" className={'detail-item'}>
                구독 ID
                <br />
                <span>{subscription.id}</span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                구독 상태
                <br />
                {subscription.status === 'SUBSCRIBE' && (
                  <span className={'text-success'}>
                    <i className="fa fa-check-circle-o"></i> 구독중
                  </span>
                )}
                {subscription.status === 'EXPIRED' && (
                  <span className={'text-danger'}>
                    <i className="fa fa-times-circle-o"></i> 구독만료
                  </span>
                )}
              </Col>
              <Col xs="6" className={'detail-item'}>
                납입 주기
                <br />
                <span>
                  {subscription.periodType === 'MONTHLY' && '월납'}
                  {subscription.periodType === 'YEARLY' && '연납'}
                </span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                약정 기간(계약 기간)
                <br />
                <span>
                  {subscription?.startDate ? moment(subscription?.startDate).format(FORMAT_DATE_UNIT_BAR) : ''}
                  &nbsp;~&nbsp;
                  {subscription?.endDate ? moment(subscription?.endDate).format(FORMAT_DATE_UNIT_BAR) : ''}
                </span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                주기
                <br />
                <span>
                  {subscription?.periodStartDate
                    ? moment(subscription?.periodStartDate).format(FORMAT_DATE_UNIT_BAR)
                    : ''}
                  &nbsp;~&nbsp;
                  {subscription?.periodEndDate ? moment(subscription?.periodEndDate).format(FORMAT_DATE_UNIT_BAR) : ''}
                </span>
              </Col>
              <Col xs="6" className={'detail-item'}>
                구독 취소
                <br />
                <span>
                  {subscription?.canceled ? (
                    <span className={'text-danger'}>
                      <i className="fa fa-times-circle-o"></i> 구독취소
                    </span>
                  ) : (
                    '-'
                  )}
                  &nbsp;
                  {subscription?.cancelDate
                    ? `(${moment(subscription?.cancelDate).format(FORMAT_DATE_TIME_UNIT_BAR)})`
                    : ''}
                </span>
              </Col>
            </Row>
          )}

          <hr />
          <h6 className="f-w-600 m-b-10 m-t-30">구독 요금제</h6>
          {!subscription ? (
            <p className={'text-center m-t-15 text-danger'}>브랜드를 연결하세요.</p>
          ) : (
            <>
              {subscription?.plan?.level > 1 ? (
                <Row className="details">
                  <Col xs="6" className={'detail-item'}>
                    요금제 ID
                    <br />
                    <span>
                      {subscription.plan.id} (Lv.{subscription.plan.level})
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    요금제명
                    <br />
                    <span>
                      {subscription.plan.title}
                      <br />- {subscription.plan.description}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    구독료
                    <br />
                    <span>
                      월납: {formatPrice(subscription.plan.price)}원/월 <br />
                      연납: {formatPrice(subscription.plan.price * 12 * (1 - subscription.plan.discountRate))}원/연 (
                      {subscription.plan.discountRate * 100}% 할인)
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    스펙
                    <br />
                    <span>
                      - 크레딧 : {subscription.plan.credit} 크레딧/월
                      <br />- 스토리지 : {subscription.plan.storage / 1000}GB
                      <br />- 최대 계정 연동 : {subscription.plan.memberCount} 계정
                    </span>
                  </Col>
                </Row>
              ) : (
                <p className={'text-center m-t-15'}>구독 중인 요금제가 없습니다.</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ManageSubscription);
