import { PageInfo } from './types';
import APIClient from './APIClient';
import { ResPlan } from './planAPI';
import { Type } from './paymentAPI';

export type SubscriptionType = 'FREE' | 'PAID' | 'ADMIN';
export const typeList: any[] = [
  { label: '무료 계정', value: 'FREE' },
  { label: '유료 계정', value: 'PAID' },
  { label: '관리자 계정', value: 'ADMIN' },
];
export type SubscriptionStatus = 'SUBSCRIBE' | 'EXPIRED';
export const statusList: any[] = [
  { label: '구독중', value: 'SUBSCRIBE' },
  { label: '구독 만료', value: 'EXPIRED' },
];
export type SubscriptionPeriodType = 'MONTHLY' | 'YEARLY';
export const periodTypeList: any[] = [
  { label: '월간', value: 'MONTHLY' },
  { label: '연간', value: 'YEARLY' },
];
export interface ReqSubscriptionPage extends PageInfo {
  type?: 'FREE' | 'PAID' | 'ADMIN';
  status?: 'SUBSCRIBE' | 'EXPIRED';
  periodType?: 'MONTHLY' | 'YEARLY';
  idBrand?: number;
}

export interface ReqSubscribeCancel {
  canceled: boolean;
  cancelDate: string; // yyyy-MM-dd HH:mm:ss
}

export interface ReqUpdateSubscribe {
  idPlan: number;
  type: SubscriptionType;
  periodType: SubscriptionPeriodType;
  periodStartDate: Date | string | null;
  periodEndDate: Date | string | null;
  startDate?: Date | string | null;
  endDate?: Date | string | null;
  // 구매 내역
  title?: string;
  payType?: Type;
  amount?: number;
  paymentDate?: Date | string | null;
}

export interface ResSubscriptionPage {
  id: number;
  type: SubscriptionType;
  periodType: SubscriptionPeriodType;
  status: SubscriptionStatus;
  periodStartDate: string;
  periodEndDate: string;
  plan: ResPlan;
  brandName: string;
}

/** 구독 페이징 **/
export const getSubscriptionPage = async (params: ReqSubscriptionPage) => {
  const response = await APIClient.get(`/v2/api/subscription/page`, {
    params,
  });
  return response.data;
};

/** 구독 상세*/
export const getSubscription = async (id: string) => {
  const response = await APIClient.get(`/v2/api/subscription/${id}`);
  return response.data;
};
/**
 * 구독 시작 설정.
 * */
export const startSubscribe = async (idSubscription: number, body: ReqUpdateSubscribe) => {
  await APIClient.patch(`/v2/api/subscription/${idSubscription}`, body);
};

/**
 * 구독 초기화(무료 계정 전환)
 * */
export const resetSubscribe = async (idSubscription: number) => {
  await APIClient.put(`/v2/api/subscription/reset/${idSubscription}`);
};

/**
 * 구독 만료 설정.
 * */
export const expireSubscribe = async (idSubscription: number) => {
  await APIClient.patch(`/v2/api/subscription/expire/${idSubscription}`);
};

/**
 * 구독 취소 설정.
 * */
export const cancelSubscribe = async (idSubscription: number, body: ReqSubscribeCancel) => {
  await APIClient.patch(`/v2/api/subscription/cancel/${idSubscription}`, body);
};
