// 결제링크 조회
import APIClient from './APIClient';

export interface ReqSubscriptionLink {
  idPlan?: string;
  idSubscription?: number;
  expiredDate?: Date | string | undefined;
  periodType?: string;
}

export const getSubscriptionLink = async (idSubscription: string) => {
  const response = await APIClient.get(`/v2/api/subscription-link`, {
    params: { idSubscription },
  });
  return response.data;
};

export const deleteSubscriptionLink = async (idSubscriptionLink: string) => {
  await APIClient.delete(`/v2/api/subscription-link/${idSubscriptionLink}`);
};

export const createSubscriptionLink = async (subscriptionLink: ReqSubscriptionLink) => {
  await APIClient.post(`/v2/api/subscription-link`, subscriptionLink);
};
