import { TBFormGroup, TBLabel } from '../../styledComponents';
import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';

interface DefaultProps {
  label?: string;
  message?: string | ReactElement;
  id?: string;
  name: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  horizontal?: boolean;
  register: any;
  errors: any;
  validation?: any;
  validate?: any;
}
const TbInputColor = ({
  label = '',
  message = '',
  name = '',
  id = '',
  defaultValue,
  disabled = false,
  horizontal = false,
  register = undefined,
  errors = {},
  validation = undefined,
  validate = undefined,
  onChange = undefined,
}: DefaultProps) => {
  // 현재 선택된 색상을 상태로 관리
  const [color, setColor] = useState(defaultValue || '#000000');

  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      {label && (
        <TBLabel horizontal={horizontal.toString()}>
          {label}
          {validation?.required && <span className={'text-danger'}>(필수)</span>}
        </TBLabel>
      )}
      <div className={'w-100'}>
        <div className={'d-flex align-items-center'}>
          <InputColor
            className={`form-control form-control-color ${errors[name] ? 'is-invalid' : ''}`}
            id={id}
            name={name}
            type={'color'}
            {...register(name, {
              ...validation,
              validate: validate,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                const newColor = e.target.value;
                setColor(newColor); // 색상 상태 업데이트
                if (onChange) onChange(e);
              },
            })}
            autoComplete="true"
            disabled={disabled}
          />
          <span className={'m-l-10'}>{color}</span>
        </div>
        {errors[name] && (
          <div className="text-danger mt-1">
            {errors[name]?.message ? errors[name].message : `${label}을/를 입력하세요.`}
          </div>
        )}
        {message && <div className={'mt-1'}>{message}</div>}
      </div>
    </TBFormGroup>
  );
};
export default React.memo(TbInputColor);

const InputColor = styled.input``;
