import React, { useCallback, useEffect, useState } from 'react';
import TbSearch from '../../../components/common/ui/TbSearch';
import { useMutation, useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { debounce } from 'lodash';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { connectBrandId } from '../../../api/logoquest';
import { getWorkflowPage } from '../../../api/workflowAPI';

interface WorkflowSearchProps {
  onComplete?: () => void;
  label?: string;
  id?: string;
  name?: string;
  register?: any;
  errors?: any;
  validation?: any;
  idLogoQuest: number;
}

const LogoWorkflowSearch: React.FC<WorkflowSearchProps> = ({
  onComplete,
  label,
  id,
  name,
  register,
  errors,
  validation,
  idLogoQuest,
}) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchConnectBrandId = useMutation(connectBrandId);
  const [query, setQuery] = useState<string | null>(null);
  const [idBrand, setIdBrand] = useState<number | null>(null);

  // GET ::: Brand ID
  const { data: searchResults, refetch } = useQuery(
    ['brandPage', query],
    () =>
      getWorkflowPage({
        query,
        page: 0,
        size: 30,
      }),
    {
      enabled: !!query && query.length < 1,
      select: (data) =>
        data?._embedded?.workflows.map((item: any) => ({
          value: item.id,
          label: `${item.brandName} (${item.connectEmail})`,
        })),
      onError: (error: any) => {
        handleApiError(error);
      },
    },
  );

  useEffect(() => {
    if (query && query.length > 1) {
      refetch();
    }
  }, [query]);

  // Search
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value) {
        setQuery(value);
      }
    }, 500),
    [],
  );

  // onChange
  const handleSearchKeyword = (value: string) => {
    debouncedSearch(value);
  };

  /**
   * 브랜드 연결 요청.
   * */
  const connectBrand = async () => {
    if (idLogoQuest && idBrand) {
      try {
        await patchConnectBrandId.mutateAsync({
          idWorkflow: idBrand,
          idLogoQuest,
        });
        if (onComplete) {
          onComplete();
        }
        toast.success('브랜드가 연동 됐습니다.');
      } catch (error: any) {
        const errorData = error?.response?.data;
        if (errorData.status.toString() === '400') {
          if (errorData.code === 'ALREADY_EXIST_USER') {
            toast.warn('이미 연동된 브랜드 입니다.');
            return;
          }
        }
        handleApiError(error);
      }
    }
  };

  return (
    <>
      <TbSearch
        label={label}
        id={id}
        name={name}
        placeholder="ex) Tidy-B"
        register={register}
        errors={errors}
        validation={validation}
        onChange={handleSearchKeyword}
        searchData={searchResults}
        onSelectValue={setIdBrand}
      />
      <div className={'text-center'}>
        <Button onClick={connectBrand} color={'primary'}>
          브랜드 연결하기
        </Button>
      </div>
    </>
  );
};

export default LogoWorkflowSearch;
