import { PageInfo } from './types';
import APIClient from './APIClient';
import { AxiosResponse } from 'axios';

export type SearchKeywordEntityType =
  | 'TEMPLATE'
  | 'ELEMENT'
  | 'ELEMENT_SHAPE'
  | 'ELEMENT_GRAPHIC'
  | 'ELEMENT_LINE'
  | 'ELEMENT_ICON'
  | 'ELEMENT_FRAME'
  | 'ELEMENT_TEXT_STYLE'
  | 'ELEMENT_TEXT_FONT'
  | 'ELEMENT_IMAGE';
export interface ReqSearchKeywordPage extends PageInfo {
  entityType?: SearchKeywordEntityType | null;
  recommend?: boolean | null;
  active?: boolean | null;
}

export interface ReqSearchKeyword {
  name?: string;
  entityType?: SearchKeywordEntityType;
  keywordList?: string[];
  orderAscending?: number;
  recommend?: boolean;
  active?: boolean;
  idTemplateForm?: number;
  idElementCategory?: number;
}

export interface ResSearchKeyword {
  idSearchKeyword?: number;
  name?: string;
  entityType?: SearchKeywordEntityType;
  keywordList?: string[];
  orderAscending?: number;
  recommend?: boolean;
  active?: boolean;
  idTemplateForm?: number;
  idElementCategory?: number;
  createdDate?: string;
  updatedDate?: string;
}

export interface ResSearchKeywordList {
  idSearchKeyword?: number;
  name?: string;
  entityType?: SearchKeywordEntityType;
  keywordList?: string[];
  recommend?: boolean;
  active?: boolean;
}

/** 페이징 */
export const getSearchKeywordPage = async (params: ReqSearchKeywordPage) => {
  const response = await APIClient.get('/v2/api/search-keyword/page', {
    params,
  });
  return response.data;
};

/** 단일 조회 */
export const getSearchKeyword = async (idSearchKeyword: number) => {
  const response: AxiosResponse<ResSearchKeyword> = await APIClient.get(`/v2/api/search-keyword/${idSearchKeyword}`);
  return response.data;
};

/** 생성 */
export const createSearchKeyword = async (body: ReqSearchKeyword) => {
  const response = await APIClient.post('/v2/api/search-keyword', body);
  return response.data;
};

/** 수정 */
export const updateSearchKeyword = async ({
  idSearchKeyword,
  body,
}: {
  idSearchKeyword: number;
  body: ReqSearchKeyword;
}) => {
  const response = await APIClient.patch(`/v2/api/search-keyword/${idSearchKeyword}`, body);
  return response.data;
};

/** 삭제 */
export const deleteSearchKeyword = async (idSearchKeyword: number) => {
  const response = await APIClient.delete(`/v2/api/search-keyword/${idSearchKeyword}`);
  return response.data;
};

/**
 * Enum 타입 조회.
 * */
export const getSearchKeywordEnum = async () => {
  const response = await APIClient.get('/v2/api/search-keyword/enum');
  return response.data;
};
