import React, { Fragment, useState } from 'react';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import {
  deleteElementCategory,
  getElementCategory,
  patchElementCategory,
  postElementCategory,
  ReqEditorElementCategory,
} from '../../api/editorElementAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import TbModal from '../../components/common/ui/TbModal';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import TbLoading from '../../components/common/ui/TbLoading';
import TbInput from '../../components/common/ui/form/TbInput';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import { toast } from 'react-toastify';
import { getSearchKeywordEnum } from '../../api/searchKeywordAPI';
import { longTermCacheConfig } from '../../data/customizer/config';
import { enumToSelect } from '../../utils/enumUtils';
import TbSelect from '../../components/common/ui/form/TbSelect';

const EditorElementCategoryUpdate: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const { id: idCategory } = useParams<{ id: string }>();
  const createCategory = useMutation(postElementCategory);
  const updateCategory = useMutation(patchElementCategory);
  const deleteMutation = useMutation(deleteElementCategory);
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ReqEditorElementCategory>({
    defaultValues: {
      ...(!idCategory
        ? // 생성일 경우 초기값.
          {
            active: false, // 여기에 초기값 설정
          }
        : {}),
    },
  });

  /**
   * Enum Type 조회
   * */
  const { data: searchKeywordType } = useQuery(['getSearchKeywordEnum'], getSearchKeywordEnum, {
    ...longTermCacheConfig,
    select: (data) => ({
      listEntityType: enumToSelect(data.listEntityType),
    }),
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  useQuery(['getElementCategory', idCategory], () => getElementCategory(idCategory as string), {
    enabled: !!idCategory,
    onSuccess: (data) => {
      setValue('name', data.name);
      setValue('searchKeywordType', data.searchKeywordType);
      setValue('orderAscending', data.orderAscending);
      setValue('active', data.active);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(false);
    if (idCategory && Number(idCategory) > 0) {
      // Update (PATCH)
      try {
        await updateCategory.mutateAsync({
          id: idCategory, // `id`를 문자열로 변환
          body: { ...data, searchKeywordType: data.searchKeywordType || null }, // 업데이트할 데이터
        });
        toast.success('카테고리 수정이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create (POST)
      try {
        await createCategory.mutateAsync({ ...data });
        toast.success('카테고리가 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const handleDelete = async () => {
    if (idCategory) {
      try {
        await deleteMutation.mutateAsync(idCategory);
        navigate(-1);
        toggleDeleteModal();
        toast.success('카테고리가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Row>
          <Col sm="10">
            <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
              <Breadcrumb parent="에디터 요소" title={`카테고리 ${idCategory ? '수정' : '생성'}`}>
                <div className="mb-0">
                  {idCategory ? (
                    <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                      삭제
                    </Button>
                  ) : (
                    ''
                  )}
                  <Button color="secondary" className="me-3" type="submit">
                    {isLoading ? <TbLoading /> : idCategory ? '수정' : '생성'}
                  </Button>
                  <Button onClick={() => navigate(-1)} color="primary">
                    목록
                  </Button>
                </div>
              </Breadcrumb>
              <Row>
                <Col sm="6">
                  <Card>
                    <CardHeader>
                      <h5>콘텐츠</h5>
                    </CardHeader>
                    <CardBody>
                      <TbInput
                        label="카테고리 이름"
                        name={'name'}
                        type="text"
                        register={register}
                        errors={errors}
                        validation={{ required: true }}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card>
                    <div className="card-header">
                      <h5>설정</h5>
                    </div>
                    <CardBody>
                      <TbSelect
                        label="검색 키워드 엔티티 타입"
                        name="searchKeywordType"
                        options={searchKeywordType?.listEntityType ? searchKeywordType.listEntityType : []}
                        register={register}
                        errors={errors}
                        // validation={{ required: true }}
                      />
                      <TbInput
                        label="순서(오름차순)"
                        name={'orderAscending'}
                        type="number"
                        register={register}
                        errors={errors}
                        validation={{ required: true }}
                      />
                      <TbRadioBoolean
                        control={control}
                        message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                        label={'게시 상태'}
                        name="active"
                        register={register}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default React.memo(EditorElementCategoryUpdate);
