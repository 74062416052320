import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import useApiError from '../../hooks/useApiError';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';
import {
  getBundleCollectionPage,
  ReqBundleCollectionPage,
  ResBundleCollectionList,
} from '../../api/bundleCollectionAPI';
import { useQuery } from 'react-query';
import Breadcrumb from '../../components/common/breadcrumb';
import TbFilter from '../../components/common/ui/TbFilter';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbDataTable from '../../components/common/ui/TbDataTable';
import { BundleCollectionColumns } from './data/bundle-collection-columns';

const BundleCollectionList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [pageData, setPageData] = useState<ResBundleCollectionList[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  // query string 값으로 request 파라미터 초기화.
  const [reqParams, setReqParams] = useState<ReqBundleCollectionPage>({
    ...defaultFilter,
    active: searchParams.get('active') === 'true' ? true : searchParams.get('active') === 'false' ? false : null,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getBundleCollectionPage', reqParams], () => getBundleCollectionPage(reqParams), {
    onSuccess: (data) => {
      setPageData(data?._embedded?.bundleCollections || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/bundle-collection/update/${row.id}`, {
      state: {
        from: location.pathname + location.search,
      },
    });
  };

  /**
   * 검색 핸들러
   * */
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  return (
    <Fragment>
      <Breadcrumb parent="번들 관리" title="번들 컬렉션">
        <Link className="btn btn-primary" to={`/admin/bundle-collection/create`}>
          새로 등록
        </Link>
      </Breadcrumb>
      <Container fluid={true}>
        {/* :::::::::: Filter : Start ::::::::::  */}
        <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams}>
          {({ register, errors, setValue }) => {
            setValue('active', reqParams.active);
            return (
              <Row>
                <Col md={6}>
                  <Row>
                    <Col sm={2}>
                      <label className="col-form-label">활성 여부</label>
                    </Col>
                    <Col sm={10}>
                      <TbSelect
                        placeholder={'전체'}
                        name="active"
                        options={[
                          { label: '활성(지원)', value: 'true' },
                          { label: '비활성(미지원)', value: 'false' },
                        ]}
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          }}
        </TbFilter>
        {/* :::::::::: Filter : End ::::::::::  */}

        {/*  :::::::::: List ::::::::::  */}
        <TbDataTable
          columns={BundleCollectionColumns()}
          data={pageData}
          progressPending={isLoading}
          paginationDefaultPage={(reqParams.page || 0) + 1}
          paginationTotalRows={totalRows}
          paginationPerPage={reqParams.size}
          paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={handleRowClicked}
        />
      </Container>
    </Fragment>
  );
};

export default React.memo(BundleCollectionList);
