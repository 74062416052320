import moment from 'moment';
import React from 'react';
import TbFormText from './TbFormText';

interface DefaultProps {
  label: string;
  date?: Date | string;
}
const TbDateText = ({ label, date }: DefaultProps) => {
  return <TbFormText label={label} value={date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-'} />;
};
export default React.memo(TbDateText);
