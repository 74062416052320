import React, { useMemo, useState } from 'react';
import { Button, Card, CardHeader, Form, ModalFooter } from 'reactstrap';
import TbDataTable from '../../../components/common/ui/TbDataTable';
import { FontFamilyI18nColumns } from '../data/fontfamily-i18n-columns';
import { useMutation, useQuery } from 'react-query';
import {
  createFontFamilyI18n,
  deleteFontFamilyI18n,
  getFontFamilyI18nList,
  ReqFontFamilyI18n,
  ResFontFamilyI18n,
  updateFontFamilyI18n,
} from '../../../api/fontAPI';
import useApiError from '../../../hooks/useApiError';
import TbModal from '../../../components/common/ui/TbModal';
import TbInput from '../../../components/common/ui/form/TbInput';
import { useForm } from 'react-hook-form';
import { getSupportLocale } from '../../../api/settingAPI';
import { SelectItem } from '../../../utils/enumUtils';
import TbSelect from '../../../components/common/ui/form/TbSelect';
import { toast } from 'react-toastify';
import TbLoading from '../../../components/common/ui/TbLoading';
import TbInputUploadImage from '../../../components/common/ui/form/file/TbResourceFileUpload';
import { longTermCacheConfig } from '../../../data/customizer/config';

interface DefaultProps {
  fontFamily: any;
}
const FontFamilyI18n = ({ fontFamily }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [i18nData, setI18nData] = useState<ResFontFamilyI18n[]>([]);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idFontFamilyI18n, setIdFontFamilyI18n] = useState<number | null>(null);
  const deleteMutate = useMutation(deleteFontFamilyI18n);
  const createMutate = useMutation(createFontFamilyI18n);
  const updateMutate = useMutation(updateFontFamilyI18n);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqFontFamilyI18n>({
    defaultValues: {
      ...{},
    },
  });

  const watchLocale = watch('locale');
  const watchImage = watch('image');

  /**
   * 번역 목록 조회.
   * */
  const { isLoading: isLoadingList, refetch } = useQuery(
    ['getFontFamilyI18nList', { idFontFamily: fontFamily?.idFontFamily }],
    () => getFontFamilyI18nList(fontFamily?.idFontFamily),
    {
      onSuccess: (data) => {
        setI18nData(data || []);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 지원 언어 목록 조회
   * */
  const { data: supportLocale } = useQuery(['getSupportLocale'], () => getSupportLocale(), {
    ...longTermCacheConfig,
    select: (data) => {
      return Object.entries(data).map(
        ([, value]): SelectItem => ({
          value: value,
          label: value as string,
        }),
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (idFontFamilyI18n) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idFontFamilyI18n,
          body: { ...data } as ReqFontFamilyI18n,
        });
        refetch();
        toast.success('다국어 데이터가 수정되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        // 국가 중복 확인
        const alreadyLocale = i18nData.some((i18n) => {
          return i18n.locale === data.locale;
        });
        if (alreadyLocale) {
          setIsLoading(false);
          toast.success('이미 등록된 언어/국가 입니다.');
          return;
        }

        await createMutate.mutateAsync({
          ...data,
        });
        refetch();
        toast.success('다국어 데이터가 생성되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
    setIsUpdateModal(false);
    setIsLoading(false);
  };

  /**
   * 번역 아이템 클릭 핸들러
   * */
  const handleOpenModal = (row?: ResFontFamilyI18n) => {
    setIdFontFamilyI18n(row?.id ? row.id : null);
    setValue('idFontFamily', fontFamily.idFontFamily);
    setValue('locale', row ? row.locale : undefined);
    setValue('displayName', row ? row.displayName : undefined);
    setValue('image', row ? row.image : undefined);
    setIsUpdateModal(true);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idFontFamilyI18n) {
      try {
        await deleteMutate.mutateAsync(Number(idFontFamilyI18n));
        setIsDeleteModal(!isDeleteModal);
        setIsUpdateModal(false);
        refetch();
        toast.success('폰트패밀리가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 업로드 파일의 공통 URL
   * 호스트, 기본경로(service/fonts), 폰트 파일 이름 의 조합으로 고유 경로가 생성되기 때문에 아래와 같이 예측 가능.
   * */
  const uploadFileUrl = useMemo(() => {
    return `${process.env.REACT_APP_SERVICE_RESOURCES_URL}/${fontFamily?.filePath}`;
  }, [fontFamily?.filePath]);

  return (
    <div>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={() => setIsDeleteModal(!isDeleteModal)}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Form className="theme-form">
        <TbModal
          headerTitle={'다국어 지원'}
          isOpen={isUpdateModal}
          toggle={() => setIsUpdateModal(!isUpdateModal)}
          backdrop={'static'}
          footer={
            <ModalFooter>
              <Button
                color="danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsDeleteModal(!isDeleteModal);
                }}
              >
                삭제
              </Button>
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit(onSubmit)();
                }}
              >
                {isLoading ? <TbLoading /> : idFontFamilyI18n ? '수정' : '생성'}
              </Button>
            </ModalFooter>
          }
        >
          <TbSelect
            label={'언어/국가 선택'}
            placeholder={'전체'}
            name="locale"
            options={supportLocale || []}
            register={register}
            errors={errors}
            readonly={!!idFontFamilyI18n}
          />
          <TbInput
            label="폰트패밀리 표기명"
            name={'displayName'}
            type="text"
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
          <TbInputUploadImage
            type={'FILE'}
            path={`service/fonts/${fontFamily?.fileName}`} // s3의 element 경로에 업로드
            fileName={`${fontFamily?.fileName}_${watchLocale}.png`}
            control={control}
            label={'대표 썸네일'}
            message={'* 1MB 이하 PNG 형식만 업로드 가능합니다.'}
            accept={'.png'}
            maxSize={1}
            initialData={watchImage ? watchImage : ''}
            onComplete={() => {
              setValue('image', `${fontFamily?.fileName}_${watchLocale}.png`);
            }}
            onDeleteComplete={() => {
              setValue('image', '');
            }}
            errors={errors}
          />
          {watchImage && <img height={30} alt={watchImage} src={`${uploadFileUrl}/${watchImage}`} />}
        </TbModal>
      </Form>
      <Card>
        <CardHeader>
          <h5>다국어 지원</h5>
          <div>
            <Button onClick={() => handleOpenModal()} color={'primary'}>
              다국어 추가
            </Button>
          </div>
        </CardHeader>
        <div className={'card-table-body'}>
          {fontFamily && (
            <TbDataTable
              columns={FontFamilyI18nColumns(uploadFileUrl)}
              data={i18nData}
              progressPending={isLoadingList}
              pagination={false}
              onRowClicked={handleOpenModal}
            />
          )}
        </div>
      </Card>
    </div>
  );
};
export default React.memo(FontFamilyI18n);
