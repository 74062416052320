import React from 'react';
import moment from 'moment/moment';

export const subscriptionColumns = [
  {
    name: 'ID',
    selector: (row: any) => row.id,
    center: true,
    width: '90px',
    cell: (row: any) => <div className={'text-left'}>ID.{row.id}</div>,
  },
  {
    name: '브랜드명',
    selector: (row: any) => row.brandName,
    sortable: true,
    center: true,
    wrap: true,
    minWidth: '200px',
  },
  {
    name: '구독상태',
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
    cell: (row: any) => (
      <div>
        {row.status === 'SUBSCRIBE' && <span className={'btn btn-xs btn-success'}>구독중</span>}
        {row.status === 'EXPIRED' && <span className={'btn btn-xs btn-danger'}>구독만료</span>}
      </div>
    ),
  },
  {
    name: '약정타입',
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
    cell: (row: any) => (
      <div>
        {row.periodType === 'MONTHLY' && <span className={'btn btn-xs btn-info'}>월납</span>}
        {row.periodType === 'YEARLY' && <span className={'btn btn-xs btn-warning'}>연납</span>}
        {!row.periodType && <span>-</span>}
      </div>
    ),
  },
  {
    name: '구독형태',
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
    cell: (row: any) => (
      <div>
        {row.type === 'FREE' && <span className={'btn btn-xs btn-default'}>무료</span>}
        {row.type === 'PAID' && <span className={'btn btn-xs btn-success'}>요금지불</span>}
        {row.type === 'ADMIN' && <span className={'btn btn-xs btn-danger'}>관리자 전용</span>}
      </div>
    ),
  },
  {
    name: '요금제',
    selector: (row: any) => row.plan.title + ` (Lv.${row.plan.level})`,
    sortable: true,
    center: true,
    wrap: true,
    width: '120px',
  },
  {
    name: '거래일',
    sortable: true,
    center: true,
    wrap: true,
    width: '140px',
    cell: (row: any) => (
      <div style={{ whiteSpace: 'nowrap' }}>
        시작일: {row.periodStartDate ? moment(row.periodStartDate).format('YYYY/MM/DD') : '-'}
        <br />
        종료일: {row.periodEndDate ? moment(row.periodEndDate).format('YYYY/MM/DD') : '-'}
      </div>
    ),
  },
];
