import UseApiError from '../../hooks/useApiError';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getContactPage, ReqContactList } from '../../api/contactAPI';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTable from 'react-data-table-component';
import { contactColumns } from './data/contact-columns';
import TbFilter from '../../components/common/ui/TbFilter';
import { Col, Row } from 'reactstrap';
import TbSelect from '../../components/common/ui/form/TbSelect';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../data/customizer/react-data-table-config';
import useDefaultFilter from '../../hooks/useDefaultFilter';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../utils/urlUtils';

const statusList: any[] = [
  { label: '신규 문의', value: 'REQUEST' },
  { label: '상담중', value: 'PROCEEDING' },
  { label: '상담 완료', value: 'COMPLETED' },
  { label: '고객 전환', value: 'CONVERSION' },
];

function ContactList() {
  const handleApiError = UseApiError();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  // Pagination
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParam, setReqParam] = useState<ReqContactList>({
    ...defaultFilter,
    status: (searchParams.get('status') as 'REQUEST' | 'PROCEEDING' | 'COMPLETED' | 'CONVERSION') || '',
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParam));
  }, [reqParam, setSearchParams]);

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(['getContactPage', { reqParam }], () => getContactPage({ ...reqParam }), {
    onSuccess: (data) => {
      setListData(data?._embedded?.contacts || []);
      setTotalRows(data?.page?.totalElements | 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParam({
      ...reqParam,
      ...filters,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/contact/update/${row.id}`);
  };

  return (
    <div>
      <Breadcrumb parent="문의 내역" title="일반 문의 목록"></Breadcrumb>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch}>
              {({ register, errors, setValue }) => {
                useEffect(() => {
                  setValue('status', reqParam.status);
                });
                return (
                  <Row>
                    <Col sm={6}>
                      <Row>
                        <Col sm={2}>
                          <label className="col-form-label">처리 상태</label>
                        </Col>
                        <Col sm={10}>
                          <TbSelect
                            placeholder={'전체'}
                            name="status"
                            options={statusList ? statusList : []}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              }}
            </TbFilter>
          </div>
          <div className={'col-xl-12'}>
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={contactColumns}
                data={listData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationDefaultPage={(reqParam.page || 0) + 1}
                paginationTotalRows={totalRows}
                paginationPerPage={reqParam.size}
                paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ContactList);
