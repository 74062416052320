import { PageInfo } from './types';
import APIClient from './APIClient';

/** 페이지 요청*/
export interface ReqTemplateBundlePage extends PageInfo {
  active?: boolean | null;
  idBundleCollection?: number;
}

/** 수정/생성 요청*/
export interface ReqTemplateBundle {
  idBundleCollection?: number;
  title?: string;
  thumbnail?: string;
  cost?: number;
  price?: number;
  description?: string;
  lastUpdatedDate?: Date | string | null;
  top?: boolean;
  active?: boolean;
}

/** 상세 응답 */
export interface ResTemplateBundle {
  id?: number;
  title?: string;
  thumbnail?: string;
  cost?: number;
  price?: number;
  description?: string;
  lastUpdatedDate?: string;
  top?: boolean;
  active?: boolean;
  createdDate?: string;
  updatedDate?: string;
}

/** 목록 응답 */
export interface ResTemplateBundleList {
  id?: number;
  title?: string;
  thumbnail?: string;
  cost?: number;
  price?: number;
  count?: string;
  active?: boolean;
}

/**
 * 템플릿 번들 페이징
 * */
export const getTemplateBundlePage = async (params: ReqTemplateBundlePage) => {
  const response = await APIClient.get('/v2/api/template-bundle/page', {
    params,
  });
  return response.data;
};

/**
 * 템플릿 번들 단일 조회
 * */
export const getTemplateBundle = async (id: number) => {
  const response = await APIClient.get(`/v2/api/template-bundle/${id}`);
  return response.data;
};

/**
 * 템플릿 번들 생성
 * */
export const createTemplateBundle = async (TemplateBundle: ReqTemplateBundle) => {
  const response = await APIClient.post('/v2/api/template-bundle', TemplateBundle);
  return response.data;
};

/**
 * 템플릿 번들 수정
 * */
export const updateTemplateBundle = async ({
  idTemplateBundle,
  body,
}: {
  idTemplateBundle: number;
  body: ReqTemplateBundle;
}) => {
  const response = await APIClient.patch(`/v2/api/template-bundle/${idTemplateBundle}`, body);
  return response.data;
};

/**
 * 템플릿 번들 삭제
 * */
export const deleteTemplateBundle = async (id: number) => {
  const response = await APIClient.delete(`/v2/api/template-bundle/${id}`);
  return response.data;
};

/**
 * 템플릿 번들 변경
 * */
export const patchMain = async (body: { idBundleCollection: number; idTemplateBundle: number }) => {
  const response = await APIClient.patch(`/v2/api/template-bundle/main`, body);
  return response.data;
};

/**
 * 번들 하위 템플릿 추가.
 * */
export const addTemplates = async (body: { idTemplateBundle: number; idTemplates: string[] }) => {
  const response = await APIClient.patch(`/v2/api/template-bundle/add`, body);
  return response.data;
};

/**
 * 번들 하위 템플릿 제거.
 * */
export const removeTemplates = async (body: { idTemplateBundle: number; idTemplates: string[] }) => {
  const response = await APIClient.patch(`/v2/api/template-bundle/remove`, body);
  return response.data;
};
