import React, { ReactNode } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ModalProps {
  isOpen: boolean;
  headerTitle?: string;
  footer?: ReactNode;
  onClickConfirm?: (e: React.MouseEvent) => void;
  btnConfirmLabel?: string | React.ReactNode;
  toggle?: () => void; // 부모 컴포넌트에서 모달 상태를 변경하는 함수'
  children?: React.ReactNode;
  isShowFooter?: boolean;
  isBtnCancel?: boolean;
  size?: string;
  backdrop?: boolean | 'static';
  centered?: boolean;
}

function TbModal({
  isOpen = false,
  footer,
  headerTitle = '',
  onClickConfirm,
  btnConfirmLabel = '확인',
  toggle,
  children,
  isShowFooter = true,
  isBtnCancel = true,
  size = 'md',
  backdrop = true,
  centered = true,
}: ModalProps) {
  return (
    <Modal
      backdrop={backdrop}
      isOpen={isOpen}
      toggle={toggle}
      className="modal-body"
      centered={centered}
      size={size}
      fade={true}
    >
      {/* Header */}
      {headerTitle && (
        <ModalHeader className={'modal-header'} toggle={toggle}>
          {headerTitle}
        </ModalHeader>
      )}
      {/* Body */}
      <ModalBody className="modal-body p-t-30 p-b-30">{children}</ModalBody>
      {/* Footer */}
      {isShowFooter ? (
        footer ? (
          footer
        ) : (
          <ModalFooter className={'modal-footer'}>
            {isBtnCancel && (
              <Button color="danger" onClick={toggle}>
                취소
              </Button>
            )}
            <Button color="primary" className="me-3" onClick={(e) => onClickConfirm?.(e)}>
              {btnConfirmLabel}
            </Button>
          </ModalFooter>
        )
      ) : null}
    </Modal>
  );
}

export default React.memo(TbModal);
