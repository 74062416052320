import { Button } from 'reactstrap';
import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { ReqCredentials, ResJwtToken, userLogin } from '../../../api/accountAPI';
import useApiError from '../../../hooks/useApiError';
import { toast } from 'react-toastify';

const BtnEditTemplate = ({ idTemplate, email }: { idTemplate: string; email?: string }) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const loginUserMutation = useMutation(userLogin);
  /**
   * 유저 에디터 접속
   * */
  const loginUserAccount = useCallback(async () => {
    console.log(email, idTemplate);
    if (!email || !idTemplate) {
      toast.error('이메일 또는 템플릿 ID가 존재하지 않습니다.');
      return;
    }
    try {
      const reqBody: ReqCredentials = { email: email };
      const resData: ResJwtToken = await loginUserMutation.mutateAsync(reqBody);
      if (window) {
        document.cookie = `tidyb-access-token=${resData.accessToken}; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
        window.open(`${process.env.REACT_APP_EDITOR_ADDRESS}/edit/${idTemplate}`, '_blank');
      }
    } catch (error) {
      handleApiError(error);
    }
  }, [idTemplate, email]);

  return (
    <Button onClick={loginUserAccount} color="success" className="me-3">
      에디터
    </Button>
  );
};

export default React.memo(BtnEditTemplate);
