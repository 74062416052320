import { Col, Container, Row } from 'reactstrap';
import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import CountWidget from './components/CountWidget';
import { Activity, Star, UserCheck, UserPlus } from 'react-feather';
import useApiError from '../../hooks/useApiError';
import { useQuery } from 'react-query';
import { getDashboard } from '../../api/dashboardAPI';

const DashboardMain = () => {
  const handleApiError = useApiError(); // 에러 처리 훅

  /**
   * 데시보드 데이터 로드
   * */
  const { data: defaultData } = useQuery(['getDashboard'], () => getDashboard(), {
    select: (data) => {
      return {
        ...data,
      };
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  return (
    <Fragment>
      <Breadcrumb parent="" title="대시보드">
        {/*<Link className="btn btn-primary" to={`/admin/workflow/update`}>*/}
        {/*  새로 등록*/}
        {/*</Link>*/}
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <div className="col-sm-6 col-xl-3 col-lg-6">
            <CountWidget
              label={'활성 구독'}
              count={defaultData?.subscribeCount || 0}
              icon={<Activity />}
              bgClass={'bg-primary'}
            />
          </div>
          <div className="col-sm-6 col-xl-3 col-lg-6">
            <CountWidget label={'브랜드'} count={defaultData?.brandCount || 0} icon={<Star />} bgClass={'bg-info'} />
          </div>
          <div className="col-sm-6 col-xl-3 col-lg-6">
            <CountWidget
              label={'당월 신규 회원'}
              count={defaultData?.userCountOfMonth || 0}
              icon={<UserPlus />}
              bgColor={'#6fa2d8'}
            />
          </div>
          <div className="col-sm-6 col-xl-3 col-lg-6">
            <CountWidget
              label={'누적 회원'}
              count={defaultData?.userCount || 0}
              icon={<UserCheck />}
              bgColor={'#50598e'}
            />
          </div>
          <Col xl={6}>
            {/****** 결제 내역 ******/}
            {/*<ManagePayment ignoreIdSubscription={true} />*/}
          </Col>
          <Col xl={6}>
            {/****** 태스크 관리 ******/}
            {/*<ManageTask ignoreIdBrand={true} />*/}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default React.memo(DashboardMain);
