import React, { Fragment, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Col, Container, Row } from 'reactstrap';
import ManageCredit from './components/ManageCredit';
import ManagePayment from '../payment/components/ManagePayment';
import { getSubscription } from '../../api/subscriptionAPI';
import ManageBrand from './components/ManageBrand';
import ManageTask from '../task/components/ManageTask';
import ManageSubscription from './components/ManageSubscription';

const ReadSubscription: React.FC = () => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { id: idSubscription } = useParams<{ id: string }>();
  // 브랜드 인포 refetch
  const [refetchBrandInfo, setRefetchBrandInfo] = useState(false);

  // Update 일떄 초기 데이터 로드
  const { data: defaultData, refetch } = useQuery(
    ['getSubscription', idSubscription],
    () => getSubscription(idSubscription as string),
    {
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // =================== 공통 항목 끝 ==================  //

  /**
   * 크레딧 변동 콜백
   * */
  const handleOnUpdateCredit = useCallback(() => {
    // 브랜드 정보 다시 로드(보유 크레딧)
    setRefetchBrandInfo(!refetchBrandInfo);
  }, []);

  // R ea d
  return (
    <Fragment>
      <Breadcrumb parent="회원 구독" title="회원 구독 상세">
        <div className="mb-0">
          <Button onClick={() => navigate(-1)} color="primary">
            목록
          </Button>
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col lg={4}>
            {/****** 브랜드/유저 정보 ******/}
            <ManageBrand idBrand={defaultData?.idBrand} refetchBrandInfo={refetchBrandInfo} />

            {/****** 구독 정보 ******/}
            <ManageSubscription subscription={defaultData} refetchSubscription={refetch} />
          </Col>

          <Col lg={8}>
            {/****** 크레딧 관리 ******/}
            <ManageCredit
              idBrand={defaultData?.idBrand}
              creditBrand={defaultData?.credit}
              onUpdateCredit={handleOnUpdateCredit}
            />

            {/****** 태스크 관리 ******/}
            <ManageTask idBrand={defaultData?.idBrand} />

            {/****** 결제 내역 ******/}
            <ManagePayment idSubscription={defaultData?.id} status={defaultData?.status} />
          </Col>
        </Row>
        <br />
      </Container>
    </Fragment>
  );
};

export default ReadSubscription;
