import APIClient from './APIClient';
import { PageInfo } from './types';
import { AxiosResponse } from 'axios';
import { ResFontFace } from './fontFaceAPI';
export enum ActivityPeriod {
  _LT6M = '6개월 미만',
  _6MTO1Y = '6개월 ~ 1년',
  _1YTO3Y = '1 ~ 2년',
  _2YTO3Y = '2 ~ 3년',
  _3YTO10Y = '3 ~ 10년',
  _MT10Y = '10년 이상',
}
export enum MemberCount {
  _1TO2 = '1 ~ 2명',
  _3TO10 = '3 ~ 10명',
  _11TO25 = '11 ~ 25명',
  _MT26 = '26명 이상',
}

export enum Industry {
  FNB = '외식업 (음식점, 카페, 배달전문점, 푸드트럭 등)',
  FASHION_BEAUTY = '패션 & 뷰티 (의류 브랜드, 액세서리, 화장품, 뷰티샵 등)',
  EDUCATION = '교육 & 학원 (어린이 교육, 입시 학원, 성인 자기계발 등)',
  ECOMMERCE = '온라인 쇼핑몰 & 이커머스 (스마트스토어, 아마존, 쿠팡 등)',
  PROFESSIONAL = '서비스 & 전문직 (디자인, 컨설팅, IT 개발, 법률, 마케팅 등)',
  HEALTHCARE_MEDICAL = '병원 & 의료 서비스 (병원, 한의원, 치과, 피부과, 성형외과, 건강관리 센터 등)',
  OTHERS = '기타',
}

/** 목록 요청*/
export interface ReqBrandPage extends PageInfo {
  deleted: boolean;
  market?: boolean;
}

/** 브랜드 설정 조회 */
export interface ResBrandGuide extends ResBrandCore, ResBrandPersonality {
  id?: string;
  // 브랜드 프로필
  name?: string;
  industry?: string;
  activityPeriod?: ActivityPeriod;
  memberCount?: MemberCount;
  what?: string[];
  // 기업 프로필
  businessName?: string;
  representative?: string;
  businessNumber?: string;
  address?: any;
  website?: string;
  // 비주얼
  logo?: ResBrandVisualLogo;
  color?: ResBrandVisualColor;
  typography?: ResBrandVisualTypography;
}
export interface ResBrandCore {
  background?: string;
  purposeManifesto?: string;
  visionManifesto?: string;
  coreValues?: { keyword: string; description: string }[];
}
export interface ResBrandPersonality {
  keywords: string[];
}
export interface ResBrandVisualLogo {
  logoPrimary?: any;
  logoPrimaryBlack?: any;
  logoPrimaryWhite?: any;
  logoSecondary?: any;
  logoSecondaryBlack?: any;
  logoSecondaryWhite?: any;
  logoSymbol?: any;
  logoSymbolBlack?: any;
  logoSymbolWhite?: any;
  logoUpload?: any;
}
export interface ResBrandVisualLogoEntity {
  idLogoEntity?: number;
  path?: string;
  filename?: string;
  url?: string;
}
export interface ResBrandVisualColor {
  main?: string;
  secondary?: string;
  tertiary?: string;
  quaternary?: string;
  success?: string;
  info?: string;
  warning?: string;
  danger?: string;
}
export interface ResBrandVisualTypography {
  fontFaceHeaderEn?: ResFontFace;
  fontFaceBodyEn?: ResFontFace;
  fontFaceHeaderKo?: ResFontFace;
  fontFaceBodyKo?: ResFontFace;
}

/** 브랜드 프로필 업데이트 */
export interface ReqBrandProfile {
  name?: string;
  industry?: string;
  activityPeriod?: ActivityPeriod;
  memberCount?: MemberCount;
  what?: string[];
  businessName?: string;
  representative?: string;
  businessNumber?: string;
  website?: string;
}

/** 브랜드 코어 업데이트*/
export interface ReqBrandCore {
  background?: string;
  purposeManifesto?: string;
  visionManifesto?: string;
  coreValues?: { keyword: string; description: string }[];
}

/**
 * 브랜드 비주얼 로고 업데이트
 * */
export interface ReqBrandVisualLogo {
  logoPrimary?: File;
  logoPrimaryBlack?: File;
  logoPrimaryWhite?: File;
  logoSecondary?: File;
  logoSecondaryBlack?: File;
  logoSecondaryWhite?: File;
  logoSymbol?: File;
  logoSymbolBlack?: File;
  logoSymbolWhite?: File;
  logoUpload?: File;
}

/** 브랜드 비주얼 색상 업데이트 */
export interface ReqBrandVisualColor {
  main?: string;
  secondary?: string;
  tertiary?: string;
  quaternary?: string;
  success?: string;
  info?: string;
  warning?: string;
  danger?: string;
}

/** 브랜드 비주얼 타이포그래피 업데이트 */
export interface ReqBrandVisualTypography {
  idFontFaceHeaderKo?: number;
  idFontFaceBodyKo?: number;
  idFontFaceHeaderEn?: number;
  idFontFaceBodyEn?: number;
}

/** 브랜드 퍼스널리티 업데이트 */
export interface ReqBrandPersonality {
  keywords?: string[];
}

/** 브랜드 페이징 */
export const getBrandPage = async (params: ReqBrandPage) => {
  const response = await APIClient.get('/v2/api/brand/page', {
    params,
  });
  return response.data;
};

/** 브랜드 정보 조회*/
export const getBrandInfo = async (id: string) => {
  const response = await APIClient.get(`/v2/api/brand/${id}`);
  return response.data;
};

/** 브랜드 설정 조회*/
export const getBrandGuide = async (id: string) => {
  const response: AxiosResponse<ResBrandGuide> = await APIClient.get(`/v2/api/brand/verbal/${id}`);
  return response.data;
};

/** 마켓 브랜드 생성*/
export const createMarketBrand = async (body: ReqBrandProfile) => {
  const response = await APIClient.post(`/v2/api/brand/market`, body);
  return response.data;
};

/** 브랜드 삭제*/
export const deleteBrand = async (id: string) => {
  const response = await APIClient.delete(`/v2/api/brand/${id}`);
  return response.data;
};

/** 브랜드 프로필 수정*/
export const updateBrandProfile = async ({ idBrand, body }: { idBrand: string; body: ReqBrandProfile }) => {
  const response = await APIClient.patch(`/v2/api/brand/profile/${idBrand}`, body);
  return response.data;
};

/** 브랜드 코어 수정*/
export const updateBrandCore = async ({ idBrand, body }: { idBrand: string; body: ReqBrandCore }) => {
  const response = await APIClient.patch(`/v2/api/brand/core/${idBrand}`, body);
  return response.data;
};

/** 브랜드 비주얼 로고 수정*/
export const updateBrandVisualLogo = async ({ idBrand, body }: { idBrand: string; body: FormData }) => {
  const response = await APIClient.patch(`/v2/api/brand/visual/logo/${idBrand}`, body, {
    headers: {
      'content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

/** 브랜드 비주얼 로고 삭제*/
export const deleteBrandVisualLogo = async ({ idBrand, idFileEntity }: { idBrand: string; idFileEntity: string }) => {
  const response = await APIClient.delete(`/v2/api/brand/visual/logo/${idBrand}/${idFileEntity}`);
  return response.data;
};

/** 브랜드 비주얼 색상 수정*/
export const updateBrandVisualColor = async ({ idBrand, body }: { idBrand: string; body: ReqBrandVisualColor }) => {
  const response = await APIClient.patch(`/v2/api/brand/visual/color/${idBrand}`, body);
  return response.data;
};

/** 브랜드 비주얼 타이포그래피 수정*/
export const updateBrandVisualTypography = async ({
  idBrand,
  body,
}: {
  idBrand: string;
  body: ReqBrandVisualTypography;
}) => {
  const response = await APIClient.patch(`/v2/api/brand/visual/typography/${idBrand}`, body);
  return response.data;
};

/** 브랜드 퍼스널리티 수정*/
export const updateBrandPersonality = async ({ idBrand, body }: { idBrand: string; body: ReqBrandPersonality }) => {
  const response = await APIClient.patch(`/v2/api/brand/personality/${idBrand}`, body);
  return response.data;
};
