export class ConfigDB {
  static data = {
    settings: {
      layout_type: 'ltr',
      sidebar: {
        wrapper: 'default',
        bodyWrapper: 'default',
      },
      sidebar_setting: 'default-sidebar',
      sidebar_backround: 'dark-sidebar',
    },
    color: {
      layout_version: 'light',
      color: 'color-1',
      primary_color: '#4466f2',
      secondary_color: '#1ea6ec',
      mix_layout: 'default',
    },
    router_animation: 'fadeIn',
  };
}

export default ConfigDB;

export const longTermCacheConfig = {
  staleTime: 1000 * 60 * 15, // 5분 동안 새로운 요청을 보내지 않고 캐싱된 데이터 사용
  cacheTime: 1000 * 60 * 20, // 10분 동안 쿼리 데이터를 메모리에 유지 (이후 삭제됨)
};
export const shortTermCacheConfig = {
  staleTime: 1000 * 15, // 15초 동안 새로운 요청을 보내지 않고 캐싱된 데이터 사용
  cacheTime: 1000 * 20, // 20초 동안 쿼리 데이터를 메모리에 유지 (이후 삭제됨)
};
