import { Button } from 'reactstrap';
import React from 'react';
import { useQuery } from 'react-query';
import { getGenKeywords, ReqGenKeywords } from '../../../api/toolsAPI';
import useApiError from '../../../hooks/useApiError';

interface DefaultProps extends ReqGenKeywords {
  onComplete?: (data: string[]) => void;
}
const TbGenKeywords = ({ url, excludes, count = 10, onComplete }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅

  const { refetch, isFetching } = useQuery(
    ['getGenKeywords'],
    () =>
      getGenKeywords({
        url,
        excludes,
        count,
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        console.log('>> 결과 키워드 : ', data);
        const trimmedValue = data.trim();
        if (trimmedValue) {
          // 콤마로 값을 분리한 후 트리밍
          const newValues = trimmedValue.split(',').map((value: string) => value.trim());
          onComplete?.(newValues);
        }
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (url) {
      refetch();
    }
  };

  return (
    <Button onClick={handleOnClick} color={'success'} disabled={!url || isFetching}>
      {!url ? '이미지 없음' : <>{isFetching ? '생성 중...' : '키워드 자동 생성(10개)'}</>}
    </Button>
  );
};
export default React.memo(TbGenKeywords);
