import React, { Fragment, useState } from 'react';
import useApiError from '../../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { getSupportLocale } from '../../../api/settingAPI';
import { longTermCacheConfig } from '../../../data/customizer/config';
import { SelectItem } from '../../../utils/enumUtils';
import { toast } from 'react-toastify';
import {
  createBrandKeywordI18n,
  deleteBrandKeywordI18n,
  getBrandKeywordI18nList,
  ReqBrandKeywordI18n,
  ResBrandKeywordI18n,
  updateBrandKeywordI18n,
} from '../../../api/brandKeywordAPI';
import TbModal from '../../../components/common/ui/TbModal';
import { Button, Card, CardHeader, Form, ModalFooter } from 'reactstrap';
import TbLoading from '../../../components/common/ui/TbLoading';
import TbSelect from '../../../components/common/ui/form/TbSelect';
import TbInput from '../../../components/common/ui/form/TbInput';
import TbDataTable from '../../../components/common/ui/TbDataTable';
import { BrandKeywordI18nColumns } from '../data/keyword-columns-i18n';

interface DefaultProps {
  brandKeyword: any;
}
const BrandKeywordI18n = ({ brandKeyword }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [i18nData, setI18nData] = useState<ResBrandKeywordI18n[]>([]);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idBrandKeywordI18n, setIdBrandKeywordI18n] = useState<number | null>(null);
  const deleteMutate = useMutation(deleteBrandKeywordI18n);
  const createMutate = useMutation(createBrandKeywordI18n);
  const updateMutate = useMutation(updateBrandKeywordI18n);

  // Form
  const {
    // control,
    register,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<ReqBrandKeywordI18n>({
    defaultValues: {
      ...{},
    },
  });

  /**
   * 번역 목록 조회.
   * */
  const { isLoading: isLoadingList, refetch } = useQuery(
    ['getBrandKeywordI18nList', { idBrandKeyword: brandKeyword?.id }],
    () => getBrandKeywordI18nList(brandKeyword?.id),
    {
      onSuccess: (data) => {
        setI18nData(data || []);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 지원 언어 목록 조회
   * */
  const { data: supportLocale } = useQuery(['getSupportLocale'], () => getSupportLocale(), {
    ...longTermCacheConfig,
    select: (data) => {
      return Object.entries(data).map(
        ([, value]): SelectItem => ({
          value: value,
          label: value as string,
        }),
      );
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (idBrandKeywordI18n) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idBrandKeywordI18n,
          body: { ...data } as ReqBrandKeywordI18n,
        });
        refetch();
        toast.success('다국어 데이터가 수정되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        // 국가 중복 확인
        const alreadyLocale = i18nData.some((i18n) => {
          return i18n.locale === data.locale;
        });
        if (alreadyLocale) {
          setIsLoading(false);
          toast.success('이미 등록된 언어/국가 입니다.');
          return;
        }

        await createMutate.mutateAsync({
          ...data,
        });
        refetch();
        toast.success('다국어 데이터가 생성되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
    setIsUpdateModal(false);
    setIsLoading(false);
  };

  /**
   * 번역 아이템 클릭 핸들러
   * */
  const handleOpenModal = (row?: ResBrandKeywordI18n) => {
    setIdBrandKeywordI18n(row?.id ? row.id : null);
    setValue('idBrandKeyword', brandKeyword.id);
    setValue('locale', row ? row.locale : undefined);
    setValue('name', row ? row.name : undefined);
    setIsUpdateModal(true);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idBrandKeywordI18n) {
      try {
        await deleteMutate.mutateAsync(Number(idBrandKeywordI18n));
        setIsDeleteModal(!isDeleteModal);
        setIsUpdateModal(false);
        refetch();
        toast.success('브랜드 키워드가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };
  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={() => setIsDeleteModal(!isDeleteModal)}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Form className="theme-form">
        <TbModal
          headerTitle={'다국어 지원'}
          isOpen={isUpdateModal}
          toggle={() => setIsUpdateModal(!isUpdateModal)}
          backdrop={'static'}
          footer={
            <ModalFooter>
              <Button
                color="danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsDeleteModal(!isDeleteModal);
                }}
              >
                삭제
              </Button>
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit(onSubmit)();
                }}
              >
                {isLoading ? <TbLoading /> : idBrandKeywordI18n ? '수정' : '생성'}
              </Button>
            </ModalFooter>
          }
        >
          <TbSelect
            label={'언어/국가 선택'}
            placeholder={'전체'}
            name="locale"
            options={supportLocale || []}
            register={register}
            errors={errors}
            readonly={!!idBrandKeywordI18n}
          />
          <TbInput
            label="키워드 이름"
            name={'name'}
            type="text"
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
        </TbModal>
        <Card>
          <CardHeader>
            <h5>다국어 지원</h5>
            <div>
              <Button onClick={() => handleOpenModal()} color={'primary'}>
                다국어 추가
              </Button>
            </div>
          </CardHeader>
          <div className={'card-table-body'}>
            {brandKeyword && (
              <TbDataTable
                columns={BrandKeywordI18nColumns}
                data={i18nData}
                progressPending={isLoadingList}
                pagination={false}
                onRowClicked={handleOpenModal}
              />
            )}
          </div>
        </Card>
      </Form>
    </Fragment>
  );
};

export default React.memo(BrandKeywordI18n);
