import React, { Fragment, useState } from 'react';
import TbFilter from '../../../components/common/ui/TbFilter';
import DataTable from 'react-data-table-component';
import useApiError from '../../../hooks/useApiError';
import { useQuery } from 'react-query';
import { logoquestColumns } from './data/logoquest-columns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../../components/common/breadcrumb';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../data/customizer/react-data-table-config';
import { getLogoQuestPage, ReqLogoQuestList } from '../../../api/logoquest';
import useDefaultFilter from '../../../hooks/useDefaultFilter';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../../utils/urlUtils';

const LogoQuestList = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [pageInfoData, setPageInfoData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  //  state
  const [reqParams, setReqParams] = useState<ReqLogoQuestList>({
    ...defaultFilter,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(['getLogoQuestPage', { reqParams }], () => getLogoQuestPage(reqParams), {
    onSuccess: (data) => {
      setPageInfoData(data?._embedded?.brandQuests || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  // 페이지 변경 핸들러
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  // 한 페이지 당 보여줄 행 수 변경 핸들러
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  // =================== 공통 항목 끝 ==================  //

  // 검색 핸들러
  const onSubmitSearch = (filters: any) => {
    setReqParams({
      ...reqParams,
      ...filters,
    });
  };

  // 행 클릭 핸들러
  const handleRowClicked = (row: any) => {
    navigate(`/admin/logoquest/update/${row.id}`);
  };

  return (
    <Fragment>
      <Breadcrumb parent="문의 내역" title="로고 의뢰 목록" />
      <div className="container-fluid">
        <div className="row">
          {/* :::::::::: Filter : Start ::::::::::  */}
          <div className="col-xl-12">
            <TbFilter onSubmit={onSubmitSearch} defaultValues={reqParams} />
          </div>
          {/* :::::::::: Filter : End ::::::::::  */}

          {/*  :::::::::: List ::::::::::  */}
          <div className=" col-xl-12 mb-4">
            <div className={'card-table-body'}>
              <DataTable
                noHeader
                columns={logoquestColumns}
                data={pageInfoData}
                progressPending={isLoading}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationDefaultPage={(reqParams.page || 0) + 1}
                paginationTotalRows={totalRows}
                paginationPerPage={reqParams.size}
                paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onRowClicked={handleRowClicked}
                dense={true}
                customStyles={{
                  rows: rowsStyle,
                  headCells: headCellsStyle,
                  cells: cellsStyle,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LogoQuestList;
