import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getPaletteFromURL } from 'color-thief-node';
import { rgbToHex } from '../../../utils/colorUtils';

interface DefaultProps {
  url: string;
  onSelect?: (color: string) => void;
}
const TBGenColorPalette = ({ url, onSelect }: DefaultProps) => {
  const [colors, setColors] = useState<any>([]);
  /**
   * CORS 우회를 위한 Blog url 변경
   * */
  const toBlobUrl = useCallback((url: string) => {
    return new Promise<string>((resolve) => {
      fetch(url, {
        mode: 'cors',
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
        .then((response) => {
          return response;
        })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          resolve(url);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, []);

  useEffect(() => {
    if (url) {
      (async () => {
        const image = (await toBlobUrl(url)) || '';
        const palette = await getPaletteFromURL(image, 1);
        setColors(palette);
      })();
    }
  }, [url]);

  return (
    <div>
      <p className={'m-b-5'}>- 썸네일에서 추출한 컬러입니다.</p>
      <Container>
        {colors.map((color: any) => (
          <ColorBoxWrap key={color}>
            <ColorBox
              onClick={() => onSelect?.(rgbToHex(`rgb(${color[0]}, ${color[1]}, ${color[2]})`))}
              style={{
                backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
              }}
            />
          </ColorBoxWrap>
        ))}
      </Container>
    </div>
  );
};
export default React.memo(TBGenColorPalette);

const Container = styled.div`
  display: flex;
`;
const ColorBoxWrap = styled.div`
  display: flex;
  margin-right: 4px;
  padding: 3px;
  border-radius: 6px;
  border: 1px solid #ccc;
`;
const ColorBox = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 10px;
  cursor: pointer;
`;
