import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import useApiError from '../../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import { ReqBrandPersonality, ResBrandPersonality, updateBrandPersonality } from '../../../api/brandAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbLoading from '../../../components/common/ui/TbLoading';
import { getBrandKeywordList } from '../../../api/brandKeywordAPI';
import TbSelect from '../../../components/common/ui/form/TbSelect';

interface DefaultProps {
  idBrand: string;
  defaultData: ResBrandPersonality;
}
const BrandPersonalityUpdate = ({ idBrand, defaultData }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateMutate = useMutation(updateBrandPersonality);

  // Form
  const {
    // control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqBrandPersonality>({
    defaultValues: {
      keywords: [],
    },
  });

  const watchKeywords = watch('keywords');

  /* 초기 데이터 설정 */
  useEffect(() => {
    setValue(
      'keywords',
      Array.from({ length: 5 }, (_, i) => defaultData?.keywords?.[i] || ''),
    );
  }, [defaultData, setValue]);

  /**
   * 브랜드 키워드 조회
   * */
  const { data: brandKeywords } = useQuery(
    ['getBrandKeywordList', {}], // queryKey에 reqParam을 개별 요소로 전달
    () => getBrandKeywordList({}), // queryFn에 필요한 인수들을 각각 전달
    {
      select: (data) => {
        return data._embedded?.brandKeywords.map((keyword: any) => {
          return { label: keyword.name, value: keyword.name };
        });
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    console.log(data, idBrand);
    setIsLoading(true);
    //Update
    try {
      await updateMutate.mutateAsync({
        idBrand: idBrand,
        body: { ...data } as ReqBrandPersonality,
      });
      toast.success('퍼스널리티 수정이 완료되었습니다.');
      // navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
      <Card>
        <CardHeader>
          <h5>퍼스널리티</h5>
          <Button color="secondary" type="submit" disabled={isLoading}>
            {isLoading ? <TbLoading /> : '수정'}
          </Button>
        </CardHeader>
        <CardBody>
          <TbSelect
            label={`키워드1`}
            placeholder={'전체'}
            name="keywords.0"
            value={watchKeywords?.[0]}
            options={brandKeywords || []}
            register={register}
            errors={errors}
          />
          <TbSelect
            label={`키워드2`}
            placeholder={'전체'}
            name="keywords.1"
            value={watchKeywords?.[1]}
            options={brandKeywords || []}
            register={register}
            errors={errors}
          />
          <TbSelect
            label={`키워드3`}
            placeholder={'전체'}
            name="keywords.2"
            value={watchKeywords?.[2]}
            options={brandKeywords || []}
            register={register}
            errors={errors}
          />
          <TbSelect
            label={`키워드4`}
            placeholder={'전체'}
            name="keywords.3"
            value={watchKeywords?.[3]}
            options={brandKeywords || []}
            register={register}
            errors={errors}
          />
          <TbSelect
            label={`키워드5`}
            placeholder={'전체'}
            name="keywords.4"
            value={watchKeywords?.[4]}
            options={brandKeywords || []}
            register={register}
            errors={errors}
          />
        </CardBody>
      </Card>
    </Form>
  );
};
export default React.memo(BrandPersonalityUpdate);
