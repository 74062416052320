import React from 'react';

export const BundleCollectionColumns = () => [
  {
    name: 'id',
    selector: (row: any) => row.id,
    center: true,
    width: '80px',
  },
  {
    name: '썸네일',
    cell: (row: any) => {
      return (
        <div>
          {row.thumbnail ? (
            <>
              {row.thumbnail?.split('.').pop() === 'mp4' ? (
                <video
                  src={row.thumbnail}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  width={'100%'}
                  style={{ maxHeight: '70px' }}
                ></video>
              ) : (
                <img
                  src={row.thumbnail}
                  alt={'템플릿 썸네일'}
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '100px',
                    maxHeight: '70px',
                  }}
                />
              )}
            </>
          ) : (
            '미리보기 없음'
          )}
        </div>
      );
    },
    center: true,
    width: '120px',
  },
  {
    name: '컬렉션명',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '확장팩 수',
    selector: (row: any) => row.count,
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: '활성 여부',
    // selector: (row: any) => row.active,
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
];
