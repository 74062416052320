import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useMemo } from 'react';
import TbInput from '../../../components/common/ui/form/TbInput';
import { useForm } from 'react-hook-form';
import useApiError from '../../../hooks/useApiError';
import TbSelect from '../../../components/common/ui/form/TbSelect';
import TbDatePicker from '../../../components/common/ui/form/TbDatePicker';
import { useMutation, useQuery } from 'react-query';
import { getPlanList } from '../../../api/planAPI';
import { ReqUpdateSubscribe, startSubscribe } from '../../../api/subscriptionAPI';
import { toast } from 'react-toastify';
import moment from 'moment';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { FORMAT_DATE_TIME_UNIT_BAR } from '../../../constant/format';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSuccess?: () => void;
  idSubscription: number;
  defaultValue?: {
    // 기본 설정값이 있는 경우 readonly
    idPlan?: number;
    type?: 'FREE' | 'PAID' | 'ADMIN';
    periodType?: 'MONTHLY' | 'YEARLY';
    title?: string;
    periodMonth?: number; // 최초 구독 주기
    contractMonth?: number; // 약정 개월 수
  };
}

const ManageSubscribeModal = ({ isOpen, toggle, onSuccess, idSubscription, defaultValue }: ModalProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchStartSubscribe = useMutation(
    ({ idSubscription, body }: { idSubscription: number; body: ReqUpdateSubscribe }) =>
      startSubscribe(idSubscription, body),
  );

  // 요금제 목록
  const { data: listPlan } = useQuery(['getPlanList'], () => getPlanList({ active: true }), {
    select: (data) =>
      data
        ?.filter((plan: any) => plan.level > 1)
        .map((plan: any) => {
          return {
            label: `id.${plan.id}, Lv.${plan.level}, ${plan.title}, ${plan.price.toLocaleString()}원/월, ${(
              plan.price *
              12 *
              (1 - plan.discountRate)
            ).toLocaleString()}원/연 : ${plan.description}`,
            value: plan.id,
            data: plan,
          };
        }),
    onError: (error) => {
      handleApiError(error);
    },
  });

  // useForm
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ReqUpdateSubscribe>();

  // 기본값이 있는 경우.
  useDidMountEffect(() => {
    if (defaultValue) {
      if (defaultValue.idPlan) {
        setValue('idPlan', defaultValue.idPlan);
      }
      if (defaultValue.type) {
        setValue('type', defaultValue.type);
      }
      if (defaultValue.periodType) {
        setValue('periodType', defaultValue.periodType);
      }
      if (defaultValue.title) {
        setValue('title', defaultValue.title);
      }
    }
  }, [defaultValue]);

  const watchIdPlan = Number(watch('idPlan'));
  const watchPeriod = watch('periodType');
  const watchPeriodStartDate = watch('periodStartDate');
  const watchPayType = watch('payType');

  // 기본값이 있을 때, 주기 시작일 설정 시 나머지 날짜 자동 삽입.
  useDidMountEffect(() => {
    if (watchPeriodStartDate) {
      const periodMonth = watchPeriod === 'YEARLY' ? 12 : 1;
      const periodEndDate = moment(watchPeriodStartDate)
        .add(defaultValue?.periodMonth ? defaultValue.periodMonth : periodMonth, 'months')
        .subtract(1, 'day');
      setValue('periodEndDate', periodEndDate.toDate());
      // 약정 기본 값이 있는 경우.
      if (defaultValue?.contractMonth && defaultValue?.contractMonth > 0) {
        setValue('startDate', watchPeriodStartDate);
        setValue(
          'endDate',
          moment(watchPeriodStartDate).add(defaultValue?.contractMonth, 'months').subtract(1, 'day').toDate(),
        );
      }
    }
  }, [watchPeriodStartDate, defaultValue, watchPeriod]);

  /**
   * 총 금액 계산
   * */
  const totalPrice = useMemo(() => {
    const plan = listPlan?.find((plan: any) => plan.data.id === watchIdPlan)?.data;
    let totalPrice = 0;
    if (plan) {
      totalPrice = plan.price;
    }
    if (watchPeriod === 'YEARLY') {
      return totalPrice * 12 * (1 - plan?.discountRate);
    }
    return totalPrice;
  }, [watchIdPlan, watchPeriod]);

  const handleOnSubmit = async (formData: ReqUpdateSubscribe) => {
    try {
      await patchStartSubscribe.mutateAsync({
        idSubscription: idSubscription,
        body: {
          idPlan: formData.idPlan,
          type: formData.type,
          periodType: formData.periodType,
          periodStartDate: moment(formData.periodStartDate).format(FORMAT_DATE_TIME_UNIT_BAR),
          periodEndDate: moment(formData.periodEndDate).format(FORMAT_DATE_TIME_UNIT_BAR),
          ...(formData.startDate && { startDate: moment(formData.startDate).format(FORMAT_DATE_TIME_UNIT_BAR) }),
          ...(formData.endDate && { endDate: moment(formData.endDate).format(FORMAT_DATE_TIME_UNIT_BAR) }),
          title: formData.title,
          amount: formData.amount,
          paymentDate: moment(formData.paymentDate).format(FORMAT_DATE_TIME_UNIT_BAR),
        },
      });
      toast.success('구독이 설정되었습니다.');
      toggle();
      // 성공 콜백
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'md'}>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <ModalHeader>구독 설정</ModalHeader>
        <ModalBody>
          <h6 className={'mb-4'}>요금 설정</h6>
          <TbSelect
            label={'요금제'}
            name={'idPlan'}
            disabled={!!defaultValue}
            options={listPlan}
            horizontal={true}
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
          <TbSelect
            label={'결제 타입'}
            name={'type'}
            disabled={!!defaultValue}
            options={[
              { label: '무료 계정', value: 'FREE' },
              { label: '유료 계정', value: 'PAID' },
              { label: '관리자 계정', value: 'ADMIN' },
            ]}
            horizontal={true}
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
          <TbSelect
            label={'결제 주기 타입'}
            name={'periodType'}
            disabled={!!defaultValue}
            options={[
              { label: '월간(월납)', value: 'MONTHLY' },
              { label: '연간(연납)', value: 'YEARLY' },
            ]}
            horizontal={true}
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
          <div className={'mb-4 d-flex justify-content-between'}>
            <span>정기 결제 예정 금액</span>
            <div>
              {watchPeriod && `${totalPrice?.toLocaleString()}`}
              {watchPeriod === 'MONTHLY' && '원/월'}
              {watchPeriod === 'YEARLY' && '원/연'}
            </div>
          </div>
          <hr />
          <h6 className={'mb-4'}>기간 설정</h6>
          <TbDatePicker
            label={'주기 시작일'}
            control={control}
            name={'periodStartDate'}
            horizontal={true}
            errors={errors}
            validation={{ required: true }}
            showTimeSelect
            onChange={(date: Date | null) => {
              if (date) {
                setValue('periodStartDate', date);
              }
            }}
          />
          <TbDatePicker
            label={'주기 종료일'}
            control={control}
            name={'periodEndDate'}
            disabled={!!defaultValue}
            horizontal={true}
            errors={errors}
            validation={{ required: true }}
            showTimeSelect
            onChange={(date: Date | null) => {
              if (date) {
                setValue('periodEndDate', date);
              }
            }}
          />
          <TbDatePicker
            label={'약정 시작일'}
            control={control}
            name={'startDate'}
            disabled={!!defaultValue?.contractMonth}
            horizontal={true}
            errors={errors}
            validation={{ required: false }}
            showTimeSelect
            onChange={(date: Date | null) => {
              if (date) {
                setValue('startDate', date);
              }
            }}
          />
          <TbDatePicker
            label={'약정 종료일'}
            control={control}
            name={'endDate'}
            disabled={!!defaultValue?.contractMonth}
            horizontal={true}
            errors={errors}
            validation={{ required: false }}
            showTimeSelect
            onChange={(date: Date | null) => {
              if (date) {
                setValue('endDate', date);
              }
            }}
          />
          <div className={'d-flex justify-content-end'}>
            <ContractClearBtn
              onClick={() => {
                setValue('startDate', null);
                setValue('endDate', null);
              }}
            >
              약정 기간 초기화
            </ContractClearBtn>
          </div>
          <hr />
          <h6>결제 내역</h6>
          <div className={'text-danger mb-4 h6'}>
            * 로고결제, 링크결제 등 결제 내역이 자동으로 생성되는 경우는 생략!
            <br />
            <br />* 결제 내역 생성이 필요한 경우
            <br />
            1. 계좌 이체 또는 외부결제(범용결제)로 받은 경우.
            <br />
            2. 무료 구독 설정인 경우, 0원으로 결제 내역 생성.
          </div>
          <TbSelect
            label={'결제 형태'}
            name={'payType'}
            options={[
              { label: '현금(계좌이체)', value: 'CASH' },
              { label: '신용카드', value: 'CARD' },
            ]}
            horizontal={true}
            register={register}
            errors={errors}
          />
          {watchPayType && (
            <>
              <TbInput
                label={'상품명'}
                name={'title'}
                type="text"
                horizontal={true}
                register={register}
                errors={errors}
                validation={{ required: true }}
              />
              <TbInput
                label={'금액'}
                name={'amount'}
                type="number"
                horizontal={true}
                register={register}
                errors={errors}
                validation={{ required: true }}
              />
              <TbDatePicker
                label={'결제 일시'}
                name={'paymentDate'}
                horizontal={true}
                control={control}
                errors={errors}
                validation={{ required: true }}
                showTimeSelect
                timeIntervals={1} // 1분 간격으로 설정
                onChange={(date: Date | null) => {
                  if (date) {
                    setValue('paymentDate', date);
                  }
                }}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={(e) => {
              e.preventDefault();
              toggle?.();
            }}
          >
            취소
          </Button>
          <Button color="primary" className="me-3" type={'submit'}>
            완료
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default React.memo(ManageSubscribeModal);

const ContractClearBtn = styled.div`
  font-size: 12px;
  cursor: pointer;
  color: #777;
  &:hover {
    color: #006fe6;
  }
`;
