import React, { Fragment, useMemo, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import DataTable from 'react-data-table-component';
import {
  createWorkflow,
  deleteWorkflow,
  getWorkflowEnum,
  getWorkflowPage,
  patchWorkflow,
  ReqWorkflow,
  ReqWorkflowList,
} from '../../api/workflowAPI';
import { useMutation, useQuery } from 'react-query';
import useApiError from '../../hooks/useApiError';
import { workflowLogoColumns } from './data/workflow-logo-columns';
import { Button, Modal } from 'reactstrap';
import styled from 'styled-components';
import TbLoading from '../../components/common/ui/TbLoading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import WFTextArea from './components/WFTextArea';

const WorkflowLogoPage = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const createWorkflowMutation = useMutation(createWorkflow);
  const patchWorkflowMutation = useMutation(({ id, data }: { id: string; data: any }) => patchWorkflow(id, data));
  const deleteMutation = useMutation(deleteWorkflow);
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  // 날짜 모달.
  const [isDateModal, setIsDateModal] = useState<boolean>(false);
  const [dateFieldData, setDateFieldData] = useState<{
    id: number;
    fieldName: string;
    date: Date;
  } | null>(null);
  const [isTextModal, setIsTextModal] = useState<boolean>(false);
  const [textFieldData, setTextFieldData] = useState<{
    id: number;
    fieldName: string;
    value: string;
    readonly?: boolean;
  } | null>(null);
  // 검색어
  const [searchText, setSearchText] = useState<string>('');
  // 목록 요청 파라미터
  const [reqParam, setReqParam] = useState<ReqWorkflowList>({
    query: '',
    startDate: '',
    endDate: '',
    page: 0,
    size: 50,
    sort: 'startDate,desc',
    // custom
    type: 'LOGO',
    process: '',
    businessCard: '',
    manager: '',
    platform: '',
    payMethod: '',
  });

  /**
   * Enum 타입 요청.
   * */
  const { data: enumType } = useQuery(['enumData'], getWorkflowEnum, {
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 고객 목록 요청.
   * */
  const { isLoading: isLoadingPage, refetch } = useQuery(
    ['getWorkflowPage', { reqParam }],
    () => getWorkflowPage(reqParam),
    {
      onSuccess: (data) => {
        setPageData(
          data?._embedded?.workflows.map((item: any) => {
            return {
              ...item,
              toggleMenu: false,
            };
          }),
        );
        setTotalRows(data?.page?.totalElements || 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 기능 드롭다운 토글
   * */
  const handleToggleMenu = (id: number) => {
    setPageData((prevPageData) =>
      prevPageData.map((item) =>
        item.id === id
          ? { ...item, toggleMenu: !item.toggleMenu } // 불변성 유지, 새로운 객체 반환
          : item,
      ),
    );
  };

  /**
   * 고객 수정/생성 요청.
   * */
  const mutateWorkFlow = async (idWorkflow?: number, reqBody?: ReqWorkflow) => {
    if (idWorkflow && Number(idWorkflow) > 0) {
      // Update Workflow
      try {
        setIsLoading(true);
        await patchWorkflowMutation.mutateAsync({
          id: String(idWorkflow),
          data: { ...reqBody },
        });
        await refetch();
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      // Create Workflow
      const answer = window.prompt('브랜드명을 입력하세요.');
      if (answer) {
        try {
          setIsLoading(true);
          await createWorkflowMutation.mutateAsync({
            type: 'LOGO',
            brandName: answer,
            userName: '고객명을 입력하세요.',
          });
          await refetch();
        } catch (error) {
          handleApiError(error);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };
  /**
   * 고객 삭제.
   * */
  const deleteWorkFlow = async (idWorkFlow: string) => {
    if (idWorkFlow && confirm('해당 데이터를 삭제할까요?')) {
      try {
        setIsLoading(true);
        await deleteMutation.mutateAsync(idWorkFlow);
        await refetch();
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * 컬럼 데이터 변경.
   * */
  const handleChangeInput = (reqBody: ReqWorkflow) => {
    mutateWorkFlow(reqBody.id, reqBody);
  };

  /**
   * 필터 변경.
   * */
  const handleChnageFilter = (filterName: string, value: string) => {
    if (filterName) {
      setReqParam({ ...reqParam, [filterName]: value });
    }
  };

  /**
   * 데이트 픽커 모달 오픈.
   * 목록에서 편집이 어려운 경우 모달로 수정.
   * */
  const handleOpenDatePicker = (id: number, fieldName: string, currentValue: Date) => {
    setDateFieldData({
      id,
      fieldName,
      date: currentValue,
    });
    setIsDateModal(true);
  };

  /**
   * 텍스트 작성 모달 오픈.
   * 목록에서 편집이 어려운 겨웅 모달로 수정.
   * */
  const handleOpenTextarea = (id: number, fieldName: string, currentValue: string, readonly?: boolean) => {
    setTextFieldData({
      id,
      fieldName,
      value: currentValue,
      readonly,
    });
    setIsTextModal(true);
  };

  /**
   * 페이지 사이즈 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParam({
      ...reqParam,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParam({
      ...reqParam,
      page: page - 1,
    });
  };

  /**
   * 합계 금액
   * */
  const calcTotalPrice: number = useMemo(() => {
    if (pageData && pageData.length > 0) {
      return pageData.reduce((sum, item) => {
        // 쉼표 제거 후 숫자로 변환
        const price = item.price ? parseInt(item.price.toString().replace(/,/g, ''), 10) : 0;
        return sum + price;
      }, 0);
    }
    return 0;
  }, [pageData]);

  /**
   * Datepicker 의 월을 숫자로 표기.
   * */
  const renderMonthContent = (month: number) => {
    return <span>{(month + 1).toString().padStart(2, '0')}</span>;
  };

  const mStartDate = useMemo(() => {
    return reqParam.startDate ? moment(reqParam.startDate).toDate() : undefined;
  }, [reqParam.startDate]);
  const mEndDate = useMemo(() => {
    return reqParam.endDate ? moment(reqParam.endDate).toDate() : undefined;
  }, [reqParam.endDate]);

  return (
    <Fragment>
      {/* 로딩 */}
      {(isLoadingPage || isLoading) && (
        <LoadingOverlay>
          <TbLoading size={50} color={'#fff'} />
        </LoadingOverlay>
      )}

      {/* 날짜 수정 모달(날짜 모두 공통) */}
      <Modal isOpen={isDateModal} size={'sm'} toggle={() => setIsDateModal(!isDateModal)}>
        <DatePicker
          className="datepicker-here"
          selected={dateFieldData?.date}
          dateFormat={'yy/MM/dd'}
          onChange={(date: Date | null) => {
            const before = moment(dateFieldData?.date);
            const after = moment(date);
            if (dateFieldData?.id && !before.isSame(after)) {
              handleChangeInput({
                [dateFieldData.fieldName]: date,
                id: dateFieldData.id,
              });
              setIsDateModal(false);
            }
          }}
          inline // 캘린더만 표시
        />
      </Modal>

      {/* 텍스트 수정 모달 */}
      <Modal isOpen={isTextModal} size={'md'} toggle={() => setIsTextModal(!isTextModal)}>
        <WFTextArea
          readonly={textFieldData?.readonly}
          value={textFieldData?.value || ''}
          onChange={(newValue) => {
            if (textFieldData && !textFieldData?.readonly && newValue !== textFieldData.value) {
              handleChangeInput({
                [textFieldData.fieldName]: newValue || '',
                id: textFieldData.id,
              });
            }
            setIsTextModal(false);
          }}
        />
      </Modal>

      <div style={{ maxWidth: 1360 }}>
        <Breadcrumb parent="엔어프라이즈 고객" title="로고 고객 목록">
          <div className={'d-flex align-items-center m-r-10'}>
            <div>기간조회:&nbsp;</div>
            <div style={{ width: 78 }}>
              <DatePicker
                className="form-control"
                placeholderText={'시작일'}
                selected={mStartDate}
                selectsStart
                startDate={mStartDate}
                endDate={mEndDate}
                maxDate={mEndDate}
                dateFormat={'yy/MM/dd'}
                monthsShown={2}
                renderMonthContent={renderMonthContent}
                onChange={(date: Date | null) => {
                  setReqParam({ ...reqParam, startDate: moment(date).format('yyyy-MM-DD HH:mm:ss') });
                }}
              />
            </div>
            <div style={{ width: 78 }}>
              <DatePicker
                className="form-control"
                placeholderText={'종료일'}
                selected={mEndDate}
                selectsEnd
                startDate={mStartDate}
                endDate={mEndDate}
                minDate={mStartDate}
                dateFormat={'yy/MM/dd'}
                monthsShown={2}
                renderMonthContent={renderMonthContent}
                onChange={(date: Date | null) => {
                  setReqParam({ ...reqParam, endDate: moment(date).format('yyyy-MM-DD HH:mm:ss') });
                }}
              />
            </div>
          </div>
          <div className={'form-group m-r-10'}>
            <div className="input-group">
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setReqParam({ ...reqParam, query: searchText });
                  }
                }}
                type="text"
                className="form-control"
                placeholder="검색어를 입력하세요."
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setReqParam({ ...reqParam, query: searchText });
                }}
                className="btn btn-dark"
                type="button"
              >
                검색
              </button>
            </div>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setReqParam({ ...reqParam, startDate: null, endDate: null, query: '' });
            }}
            className="btn btn-dark m-r-10"
            type="button"
          >
            초기화
          </button>
          <Button color={'primary'} onClick={() => mutateWorkFlow(0, {})} style={{ whiteSpace: 'nowrap' }}>
            새로 등록
          </Button>
        </Breadcrumb>
        <div className={'container-fluid'}>
          <DataTable
            // noHeader
            columns={workflowLogoColumns(
              enumType,
              reqParam,
              calcTotalPrice,
              handleToggleMenu,
              deleteWorkFlow,
              handleOpenDatePicker,
              handleOpenTextarea,
              handleChangeInput,
              handleChnageFilter,
            )}
            data={pageData}
            noDataComponent={<div style={{ padding: 30 }}>조회된 데이터가 없습니다.</div>}
            persistTableHead={true}
            progressPending={isLoadingPage}
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={reqParam.size}
            paginationRowsPerPageOptions={[30, 50, 100, 300, 500]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            dense={true}
            responsive={true}
            customStyles={{
              rows: {
                style: {
                  minHeight: '24px !important',
                },
              },
              headCells: {
                style: {
                  padding: '4px 4px',
                  fontSize: '11px !important',
                  background: '#000',
                  color: '#fff',
                  borderRight: '1px solid #ddd',
                  '&:last-of-type': {
                    borderRight: 'none', // 마지막 헤더 셀의 오른쪽 보더 제거
                  },
                },
              },
              cells: {
                style: {
                  fontWeight: 500,
                  padding: '0px 4px',
                  borderRight: '1px solid #ddd',
                  '&:last-of-type': {
                    borderRight: 'none', // 마지막 헤더 셀의 오른쪽 보더 제거
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(WorkflowLogoPage);

const LoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;
