import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import React, { useState } from 'react';
import TbPreviewFileInput from './LogoPreviewFileInput';
import {
  deleteBrandVisualLogo,
  ReqBrandVisualLogo,
  ResBrandVisualLogo,
  updateBrandVisualLogo,
} from '../../../api/brandAPI';
import { useForm } from 'react-hook-form';
import useApiError from '../../../hooks/useApiError';
import { useMutation } from 'react-query';
import TbLoading from '../../../components/common/ui/TbLoading';
import { toast } from 'react-toastify';
import TbModal from '../../../components/common/ui/TbModal';

interface DefaultProps {
  idBrand: string;
  defaultData: ResBrandVisualLogo;
  refetchData?: () => void;
}

const BrandVisualLogo = ({ idBrand, defaultData, refetchData }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState(null);
  const deleteMutate = useMutation(deleteBrandVisualLogo);
  const updateMutate = useMutation(updateBrandVisualLogo);

  // Form
  const {
    control,
    // register,
    handleSubmit,
    // setValue,
    // watch,
    formState: { errors },
  } = useForm<ReqBrandVisualLogo>({
    defaultValues: {},
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    //Update
    try {
      const formData = new FormData();
      const emptyFile = new Blob([], { type: 'application/octet-stream' });
      formData.append('files', data.logoPrimary?.[0] || emptyFile);
      formData.append('files', data.logoPrimaryBlack?.[0] || emptyFile);
      formData.append('files', data.logoPrimaryWhite?.[0] || emptyFile);
      formData.append('files', data.logoSecondary?.[0] || emptyFile);
      formData.append('files', data.logoSecondaryBlack?.[0] || emptyFile);
      formData.append('files', data.logoSecondaryWhite?.[0] || emptyFile);
      formData.append('files', data.logoSymbol?.[0] || emptyFile);
      formData.append('files', data.logoSymbolBlack?.[0] || emptyFile);
      formData.append('files', data.logoSymbolWhite?.[0] || emptyFile);
      formData.append('files', data.logoUpload?.[0] || emptyFile);

      await updateMutate.mutateAsync({
        idBrand: idBrand,
        body: formData,
      });
      toast.success('로고 수정이 완료되었습니다.');
      refetchData?.();
      // navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      handleApiError(error);
    }
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idBrand && deleteTargetId) {
      try {
        await deleteMutate.mutateAsync({ idBrand, idFileEntity: deleteTargetId });
        // navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
        refetchData?.();
        toggleDeleteModal();
        toast.success('로고가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  console.log(defaultData);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Card>
        <CardHeader>
          <h5>비주얼 - 로고</h5>
          <Button color="secondary" type="submit" disabled={isLoading}>
            {isLoading ? <TbLoading /> : '수정'}
          </Button>
        </CardHeader>
        <CardBody>
          <h6>1. 가로형(메인)</h6>
          <div className={'d-flex flex-wrap'}>
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 기본'}
              name={'logoPrimary'}
              defaultValue={defaultData?.logoPrimary?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoPrimary?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 흑색'}
              name={'logoPrimaryBlack'}
              defaultValue={defaultData?.logoPrimaryBlack?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoPrimaryBlack?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 백색'}
              bgColor={'#000'}
              name={'logoPrimaryWhite'}
              defaultValue={defaultData?.logoPrimaryWhite?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoPrimaryWhite?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
          </div>
          <br />
          <h6>2. 세로형(보조)</h6>
          <div className={'d-flex flex-wrap'}>
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 기본'}
              name={'logoSecondary'}
              defaultValue={defaultData?.logoSecondary?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoSecondary?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 흑색'}
              name={'logoSecondaryBlack'}
              defaultValue={defaultData?.logoSecondaryBlack?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoPrimary?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 백색'}
              bgColor={'#000'}
              name={'logoSecondaryWhite'}
              defaultValue={defaultData?.logoSecondaryWhite?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoSecondaryWhite?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
          </div>
          <br />
          <h6>3. 심볼</h6>
          <div className={'d-flex flex-wrap'}>
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 기본'}
              name={'logoSymbol'}
              defaultValue={defaultData?.logoSymbol?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoSymbol?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 흑색'}
              name={'logoSymbolBlack'}
              defaultValue={defaultData?.logoSymbolBlack?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoSymbolBlack?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
            <TbPreviewFileInput
              control={control}
              errors={errors}
              label={'- 백색'}
              bgColor={'#000'}
              name={'logoSymbolWhite'}
              defaultValue={defaultData?.logoSymbolWhite?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoSymbolWhite?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
          </div>
          <br />
          <h6>4. 벡터</h6>
          <div className={'d-flex'}>
            <TbPreviewFileInput
              type={'FILE'}
              control={control}
              errors={errors}
              accept={''}
              name={'logoUpload'}
              defaultValue={defaultData?.logoUpload?.url}
              onClickDelete={() => {
                setDeleteTargetId(defaultData?.logoUpload?.idLogoEntity);
                setIsDeleteModal(true);
              }}
            />
          </div>
        </CardBody>
      </Card>
    </Form>
  );
};
export default React.memo(BrandVisualLogo);
