import React, { useState } from 'react';
import SearchBrand from '../../brand/components/SearchBrand';
import useApiError from '../../../hooks/useApiError';
import { paymentConnectBrand } from '../../../api/paymentAPI';
import { Button } from 'reactstrap';
import { useMutation } from 'react-query';

interface DefaultProps {
  onComplete?: () => void;
  idPayment: number;
}
const PaymentConnectBrand = ({ onComplete, idPayment }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchMutation = useMutation(paymentConnectBrand);
  const [idBrand, setIdBrand] = useState<number | null>(null);

  /**
   * 브랜드 연결 요청.
   * */
  const connectBrand = async () => {
    if (idPayment && idBrand) {
      try {
        await patchMutation.mutateAsync({
          idPayment,
          idBrand,
        });
        if (onComplete) {
          onComplete();
        }
      } catch (error: any) {
        handleApiError(error);
      }
    }
  };

  return (
    <>
      <SearchBrand onSelect={setIdBrand} />
      <div className={'text-center'}>
        <Button onClick={connectBrand} color={'primary'}>
          브랜드 연결하기
        </Button>
      </div>
    </>
  );
};
export default React.memo(PaymentConnectBrand);
