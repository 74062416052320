import React, { Fragment, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import {
  ActivityPeriod,
  createMarketBrand,
  deleteBrand,
  getBrandGuide,
  Industry,
  MemberCount,
  ReqBrandProfile,
  updateBrandProfile,
} from '../../api/brandAPI';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TbModal from '../../components/common/ui/TbModal';
import TbLoading from '../../components/common/ui/TbLoading';
import TbInput from '../../components/common/ui/form/TbInput';
import TbSelect from '../../components/common/ui/form/TbSelect';
import BrandCoreUpdate from './components/BrandVisualCoreUpdate';
import BrandPersonalityUpdate from './components/BrandPersonalityUpdate';
import BrandVisualColorUpdate from './components/BrandVisualColorUpdate';
import BrandTypographyUpdate from './components/BrandTypographyUpdate';
import BrandVisualLogo from './components/BrandVisualLogo';

const BrandUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idBrand } = useParams<{ idBrand: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedIndustry, setSelectedIndustry] = useState<any>('');
  const deleteMutate = useMutation(deleteBrand);
  const createMutate = useMutation(createMarketBrand);
  const updateMutate = useMutation(updateBrandProfile);
  // 산업군
  const industryOptions = Object.entries(Industry).map(([key, value]) => ({
    label: value, // 사용자에게 보여줄 값
    value: key, // 실제 코드 값
  }));
  // 브랜드 활동 기간
  const activityPeriodOptions = Object.entries(ActivityPeriod).map(([key, value]) => ({
    label: value, // 사용자에게 보여줄 값
    value: key, // 실제 코드 값
  }));
  // 멤버 수
  const memberCountOptions = Object.entries(MemberCount).map(([key, value]) => ({
    label: value, // 사용자에게 보여줄 값
    value: key, // 실제 코드 값
  }));

  // Form
  const {
    // control,
    register,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<ReqBrandProfile>({
    defaultValues: {},
  });

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData, refetch } = useQuery(['getBrandGuide', idBrand], () => getBrandGuide(idBrand!), {
    enabled: !!idBrand,
    select: (data) => {
      return {
        ...data,
      };
    },
    onSuccess: (data) => {
      setValue('name', data.name);
      setValue('industry', data.industry);
      setValue('activityPeriod', data.activityPeriod);
      setValue('memberCount', data.memberCount);
      setValue('what', data.what);
      setValue('businessName', data.businessName);
      setValue('representative', data.representative);
      setValue('businessNumber', data.businessNumber);
      setValue('website', data.website);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (idBrand) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idBrand: idBrand,
          body: { ...data } as ReqBrandProfile,
        });
        toast.success('프로필 수정이 완료되었습니다.');
        refetch();
        // navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          name: data.name,
        });
        toast.success('브랜드가 생성되었습니다.');
        navigate(location?.state?.from ? location.state.from : `/admin/brand-market/list`);
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idBrand) {
      try {
        await deleteMutate.mutateAsync(idBrand);
        navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
        toggleDeleteModal();
        toast.success('템플릿이 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Breadcrumb parent="브랜드 관리" title="디자이너 작업 상세">
          <div className="mb-0">
            {idBrand ? (
              <>
                <Button
                  color="success"
                  onClick={() => {
                    navigate(`/admin/template-event/create/${idBrand}`);
                  }}
                  className={'me-3'}
                >
                  템플릿 생성
                </Button>
                <Button color="danger" onClick={toggleDeleteModal} className={'me-3'}>
                  삭제
                </Button>
              </>
            ) : null}
            <Button
              color="primary"
              onClick={() => navigate(location?.state?.from ? location.state.from : `/admin/brand-market/list`)}
            >
              목록
            </Button>
          </div>
        </Breadcrumb>

        <Row>
          <Col sm={4}>
            <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
              <Card>
                <CardHeader>
                  <h5>브랜드&기업 프로필</h5>
                  <Button color="secondary" type="submit" disabled={isLoading}>
                    {isLoading ? <TbLoading /> : idBrand ? '수정' : '생성'}
                  </Button>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="브랜드 이름"
                    name={'name'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  {idBrand && (
                    <>
                      <TbSelect
                        label="브랜드 활동 기간"
                        options={industryOptions}
                        value={selectedIndustry}
                        onChange={(e) => {
                          const key = e.target.value;
                          console.log(Industry[key as keyof typeof Industry]);
                          setValue('industry', key !== 'OTHERS' ? Industry[key as keyof typeof Industry] : '');
                          setSelectedIndustry('');
                        }}
                      />
                      <TbInput
                        placeholder={'산업군을 입력하세요.'}
                        name={'industry'}
                        type="text"
                        register={register}
                        errors={errors}
                      />
                      <TbSelect
                        label="브랜드 활동 기간"
                        name="activityPeriod"
                        options={activityPeriodOptions}
                        register={register}
                        errors={errors}
                      />
                      <TbSelect
                        label="멤버수"
                        name="memberCount"
                        options={memberCountOptions}
                        register={register}
                        errors={errors}
                      />
                      <TbInput
                        label="기업 이름"
                        name={'businessName'}
                        type="text"
                        register={register}
                        errors={errors}
                      />
                      <TbInput
                        label="대표자명(복수일 경우 콤마로 구분)"
                        name={'representative'}
                        type="text"
                        register={register}
                        errors={errors}
                      />
                      <TbInput
                        label="사업자번호"
                        name={'businessNumber'}
                        type="text"
                        defaultValue={defaultData?.businessNumber}
                        readonly={true}
                      />
                      <TbInput
                        label="기업 주소"
                        name={'address'}
                        type="text"
                        defaultValue={`${defaultData?.address?.address1 || ''} ${defaultData?.address?.address2 || ''}`}
                        readonly={true}
                      />
                      <TbInput label="웹사이트" name={'website'} type="text" register={register} errors={errors} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Form>
          </Col>
          {idBrand && defaultData && (
            <>
              <Col sm={4}>
                {/* 가치 체계 */}
                <BrandCoreUpdate
                  idBrand={idBrand}
                  defaultData={{
                    background: defaultData?.background,
                    purposeManifesto: defaultData?.purposeManifesto,
                    visionManifesto: defaultData?.visionManifesto,
                    coreValues: defaultData?.coreValues,
                  }}
                />

                {/* 퍼스널리티 */}
                <BrandPersonalityUpdate
                  idBrand={idBrand}
                  defaultData={{
                    keywords: defaultData?.keywords,
                  }}
                />
              </Col>
              <Col sm={4}>
                {/* 비주얼 - 로고 */}
                {defaultData.logo && (
                  <BrandVisualLogo idBrand={idBrand} defaultData={defaultData.logo} refetchData={refetch} />
                )}

                {/* 비주얼 - 색상 */}
                {defaultData.color && <BrandVisualColorUpdate idBrand={idBrand} defaultData={defaultData.color} />}
                {/* 비주얼 - 타이포그래피 */}
                {defaultData.typography && (
                  <BrandTypographyUpdate idBrand={idBrand} defaultData={defaultData.typography} />
                )}
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};
export default React.memo(BrandUpdate);
