import React, { useState } from 'react';
import { useMutation } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { Button } from 'reactstrap';
import { patchWorkflowConnect } from '../../../api/workflowAPI';
import { toast } from 'react-toastify';
import SearchBrand from '../../brand/components/SearchBrand';

interface WorkflowSearchProps {
  onComplete?: () => void;
  idWorkflow: number;
}

const WFConnectBrand: React.FC<WorkflowSearchProps> = ({ onComplete, idWorkflow }) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const patchWorkflowMutation = useMutation(patchWorkflowConnect);
  const [idBrand, setIdBrand] = useState<number | null>(null);

  /**
   * 브랜드 연결 요청.
   * */
  const connectBrand = async () => {
    if (idWorkflow && idBrand) {
      try {
        await patchWorkflowMutation.mutateAsync({
          idWorkflow,
          idBrand,
        });
        if (onComplete) {
          onComplete();
        }
      } catch (error: any) {
        const errorData = error?.response?.data;
        if (errorData.status.toString() === '400') {
          if (errorData.code === 'ALREADY_EXIST_USER') {
            toast.warn('이미 연동된 브랜드 입니다.');
            return;
          }
        }
        handleApiError(error);
      }
    }
  };

  return (
    <>
      <SearchBrand onSelect={setIdBrand} />
      <div className={'text-center'}>
        <Button onClick={connectBrand} color={'primary'}>
          브랜드 연결하기
        </Button>
      </div>
    </>
  );
};

export default WFConnectBrand;
