import React from 'react';
import TbCheckbox from '../../../components/common/ui/form/TbCheckbox';

export const TemplateSearchColumns = (register: any, watchSelectedIds: string[]) => [
  {
    name: '선택',
    cell: (row: any) => (
      <TbCheckbox
        formGroupStyle={{ marginBottom: '0px !important' }}
        name={'selectedIds'}
        type={'checkbox'}
        checked={watchSelectedIds?.includes(row.idTemplate)}
        value={row.idTemplate}
        register={register}
      />
    ),
    center: true,
    width: '60px',
  },
  {
    name: '썸네일',
    cell: (row: any) => {
      return (
        <div>
          {row.thumbnail ? (
            <img
              src={row.thumbnail}
              alt={'템플릿 썸네일'}
              style={{
                width: '100%',
                height: '50px',
                maxWidth: '100px',
                maxHeight: '50px',
              }}
            />
          ) : (
            '미리보기 없음'
          )}
        </div>
      );
    },
    center: true,
    width: '100px',
  },
  {
    name: '템플릿명',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '브랜드명',
    selector: (row: any) => row.brandName,
    sortable: true,
    center: true,
    wrap: true,
  },
];
