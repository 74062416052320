import CountUp from 'react-countup';
import React, { ReactElement } from 'react';
interface DefaultProps {
  label: string;
  count: number;
  icon: ReactElement;
  bgClass?: string;
  bgColor?: string;
}

const BgIconWrap = ({ icon }: { icon: ReactElement }) => {
  return React.cloneElement(icon, {
    className: `${icon.props.className || ''}icon-bg`,
  });
};

const CountWidget = ({ label, count, icon, bgClass, bgColor }: DefaultProps) => {
  return (
    <div className="card o-hidden">
      <div
        className={`${bgClass} b-r-4 card-body`}
        style={{ backgroundColor: bgColor ? bgColor : 'transparent', color: '#fff' }}
      >
        <div className="d-flex static-top-widget">
          <div className="align-self-center text-center">{icon}</div>
          <div className="flex-grow-1">
            <span className="m-0">{label}</span>
            <h4 className="mb-0 counter">
              <CountUp className="counter" end={count} />
            </h4>
            <BgIconWrap icon={icon} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(CountWidget);
