import APIClient from './APIClient';
import { PageInfo } from './types';

/** 페이지 요청*/
export interface ReqBundleCollectionPage extends PageInfo {
  active?: boolean | null;
}

/** 수정/생성 요청*/
export interface ReqBundleCollection {
  name?: string;
  thumbnail?: string;
  fallbackThumb?: string;
  infoImages?: string[];
  idTemplateBundle?: number;
  active?: boolean | null;
  top?: boolean | null;
}

/** 상세 응답 */
export interface ResBundleCollection {
  id: number;
  name: string;
  thumbnail: string;
  infoImages: string[];
  count: number;
  top: boolean;
  active: boolean;
  createdDate: string;
  updateDate: string;
}

/** 목록 응답 */
export interface ResBundleCollectionList {
  id: number;
  name: string;
  thumbnail: string;
  count: number;
  active: boolean;
}

/**
 * 번들 컬렉 페이징
 * */
export const getBundleCollectionPage = async (params: ReqBundleCollectionPage) => {
  const response = await APIClient.get('/v2/api/bundle-collection/page', {
    params,
  });
  return response.data;
};

/**
 * 번들 컬렉 단일 조회
 * */
export const getBundleCollection = async (id: number) => {
  const response = await APIClient.get(`/v2/api/bundle-collection/${id}`);
  return response.data;
};

/**
 * 번들 컬렉 생성
 * */
export const createBundleCollection = async (BundleCollection: ReqBundleCollection) => {
  const response = await APIClient.post('/v2/api/bundle-collection', BundleCollection);
  return response.data;
};

/**
 * 번들 컬렉 수정
 * */
export const updateBundleCollection = async ({
  idBundleCollection,
  body,
}: {
  idBundleCollection: number;
  body: ReqBundleCollection;
}) => {
  const response = await APIClient.patch(`/v2/api/bundle-collection/${idBundleCollection}`, body);
  return response.data;
};

/**
 * 번들 컬렉션 삭제
 * */
export const deleteBundleCollection = async (id: number) => {
  const response = await APIClient.delete(`/v2/api/bundle-collection/${id}`);
  return response.data;
};
