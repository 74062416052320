import { FormGroup, Label } from 'reactstrap';
import React from 'react';

interface DefaultProps {
  label: string;
  value?: string;
}
const TbDateText = ({ label, value }: DefaultProps) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <p>{value || '-'}</p>
    </FormGroup>
  );
};
export default React.memo(TbDateText);
