import React from 'react';
import TBFlag from '../../../components/common/ui/TBFlag';

export const BrandKeywordI18nColumns = [
  {
    name: 'id',
    selector: (row: any) => row.id,
    center: true,
    width: '60px',
  },
  {
    name: '언어',
    cell: (row: any) => {
      return <TBFlag locale={row.locale} />;
    },
    sortable: true,
    center: true,
    width: '60px',
  },
  {
    name: '키워드',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
];
