import React, { useCallback, useState } from 'react';
import TbDataTable from '../../../components/common/ui/TbDataTable';
import useApiError from '../../../hooks/useApiError';
import useDefaultFilter from '../../../hooks/useDefaultFilter';
import { ResTemplateBundleList } from '../../../api/templateBundleAPI';
import { getTemplatePage, ReqTemplatePage } from '../../../api/templateAPI';
import { useQuery } from 'react-query';
import { TemplateSearchColumns } from '../data/template-search-columns';
import TbSearch from '../../../components/common/ui/TbSearch';
import { debounce } from 'lodash';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';

interface DefaultProps {
  onComplete?: (select: string[]) => void;
}
interface SearchForm {
  selectedIds: string[];
}
const SearchTemplate = ({ onComplete }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [defaultFilter] = useDefaultFilter();
  const [pageData, setPageData] = useState<ResTemplateBundleList[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  // 검색 결과에서 페이지 변경이 있을 때 이전 페이지에서 선택한 id가 소실 되기 때문에 임시로 저장 후 반영하기 위해 사용.
  const [tempIds, setTempIds] = useState<Set<string>>(new Set([]));

  // query string 값으로 request 파라미터 초기화.
  const [reqParams, setReqParams] = useState<ReqTemplatePage>({
    ...defaultFilter,
    size: 20,
    type: 'DESIGN',
    createdType: 'MARKET',
    deletedType: 'USED',
    isBundle: false,
  });

  // Form
  const {
    // control,
    register,
    handleSubmit,
    setValue,
    watch,
    // formState: { errors },
  } = useForm<SearchForm>({});

  const watchSelectedIds = watch('selectedIds');

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getTemplatePage', reqParams], () => getTemplatePage(reqParams), {
    enabled: !!reqParams.query,
    onSuccess: (data) => {
      // 로드 후 선택된 템플릿 id 초기화.
      if (tempIds) {
        setValue('selectedIds', [...tempIds]);
      }
      setPageData(data?._embedded?.templates || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    // 페이지 변경 전 선택된 id 임시 저장.
    if (watchSelectedIds && watchSelectedIds.length) {
      setTempIds(new Set([...watchSelectedIds, ...tempIds]));
    }
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  // Search
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value && value.length > 1) {
        setReqParams({
          ...reqParams,
          query: value,
        });
      }
    }, 700),
    [],
  );

  /**
   * 검색 핸들러
   * */
  const handleSearchKeyword = (value: string) => {
    debouncedSearch(value);
  };

  /**
   * 추가할 템플릿 최종 선택 완료.
   * */
  const onSubmit = async (data: SearchForm) => {
    onComplete?.(data.selectedIds);
  };

  return (
    <div>
      <TbSearch placeholder="템플리명을 검색하세요." onChange={handleSearchKeyword} />
      <form>
        <TbDataTable
          columns={TemplateSearchColumns(register, watchSelectedIds)}
          data={pageData}
          progressPending={isLoading}
          paginationDefaultPage={(reqParams.page || 0) + 1}
          paginationTotalRows={totalRows}
          paginationPerPage={reqParams.size}
          paginationRowsPerPageOptions={[reqParams.size || 20]}
          onChangePage={handlePageChange}
        />
        <div className={'d-flex justify-content-end'}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSubmit(onSubmit)();
            }}
            color={'primary'}
            type={'submit'}
          >
            추가하기
          </Button>
        </div>
      </form>
    </div>
  );
};

export default React.memo(SearchTemplate);
