import { useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import React, { Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import TbModal from '../../components/common/ui/TbModal';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import TbLoading from '../../components/common/ui/TbLoading';
import TbInput from '../../components/common/ui/form/TbInput';
import TbInputUploadImage from '../../components/common/ui/form/file/TbResourceFileUpload';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/form/TbDateText';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  createTemplateBundle,
  deleteTemplateBundle,
  getTemplateBundle,
  patchMain,
  ReqTemplateBundle,
  updateTemplateBundle,
} from '../../api/templateBundleAPI';
import TbTextarea from '../../components/common/ui/form/TbTextarea';
import Breadcrumb from '../../components/common/breadcrumb';
import TbDatePicker from '../../components/common/ui/form/TbDatePicker';
import moment from 'moment';
import { FORMAT_DATE_TIME_UNIT_BAR } from '../../constant/format';
import SearchTemplate from './components/BundleTemplateList';

const TemplateBundleUpdate = () => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idBundleCollection, idTemplateBundle } =
    useParams<{ idBundleCollection: string; idTemplateBundle: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteTemplateBundle);
  const createMutate = useMutation(createTemplateBundle);
  const updateMutate = useMutation(updateTemplateBundle);
  const patchMainMutate = useMutation(patchMain);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<ReqTemplateBundle>({
    defaultValues: {
      ...(!idTemplateBundle
        ? // 생성일 경우 초기값.
          {
            top: false,
            active: false,
          }
        : {}),
    },
  });

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(
    ['getTemplateBundle', idTemplateBundle],
    () => getTemplateBundle(Number(idTemplateBundle)),
    {
      enabled: !!idTemplateBundle,
      select: (data) => {
        return {
          ...data,
        };
      },
      onSuccess: (data) => {
        setValue('title', data.title);
        setValue('thumbnail', data.thumbnail);
        setValue('cost', data.cost);
        setValue('price', data.price);
        setValue('description', data.description);
        setValue('lastUpdatedDate', data.lastUpdatedDate);
        setValue('top', data.top);
        setValue('active', data.active);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (idTemplateBundle && Number(idTemplateBundle) > 0) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idTemplateBundle: Number(idTemplateBundle),
          body: {
            ...data,
            lastUpdatedDate: moment(data.lastUpdatedDate).format(FORMAT_DATE_TIME_UNIT_BAR),
            idBundleCollection,
          } as ReqTemplateBundle,
        });
        toast.success('번들 컬렉 수정이 완료되었습니다.');
        navigate(`/admin/bundle-collection/update/${idBundleCollection}`);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          ...data,
          lastUpdatedDate: moment(data.lastUpdatedDate).format(FORMAT_DATE_TIME_UNIT_BAR),
          idBundleCollection,
        });
        toast.success('번들 컬렉 생성이 완료되었습니다.');
        navigate(`/admin/bundle-collection/update/${idBundleCollection}`);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idTemplateBundle) {
      try {
        await deleteMutate.mutateAsync(Number(idTemplateBundle));
        navigate(`/admin/bundle-collection/update/${idBundleCollection}`);
        toggleDeleteModal();
        toast.success('템플릿 번들 삭제가 완료되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    if (defaultData.count > 0) {
      toast.warning('하위 템플릿이 존재합니다.');
      return;
    }
    setIsDeleteModal(!isDeleteModal);
  };

  /**
   * 기본 번들로 설정
   * */
  const setMainBundle = async () => {
    if (idBundleCollection && idTemplateBundle) {
      try {
        await patchMainMutate.mutateAsync({
          idBundleCollection: Number(idBundleCollection),
          idTemplateBundle: Number(idTemplateBundle),
        });
        toast.success('기본 번들로 설정되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="번들 관리" title={`템플릿 번들 ${idTemplateBundle ? '수정' : '생성'}`}>
            <div>
              {idTemplateBundle ? (
                <>
                  <Button onClick={setMainBundle} color="success" className="me-3" type={'button'}>
                    기본 번들 설정
                  </Button>
                  <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                    삭제
                  </Button>
                </>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idTemplateBundle ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(`/admin/bundle-collection/update/${idBundleCollection}`)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          {/* ::: 양식 :::*/}
          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>템플릿 번들 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="번들 컬렉션 이름"
                    name={'title'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  <TbInput
                    label="크레딧 원가"
                    name={'cost'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  <TbInput
                    label="크레딧 판매가"
                    name={'price'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  <TbTextarea
                    label="설명"
                    id="description"
                    name={'description'}
                    placeholder="설명"
                    rows={5}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />

                  {idTemplateBundle && (
                    <>
                      <TbInputUploadImage
                        type={'IMAGE'}
                        path={`market_thumb/bundle/${idTemplateBundle}`}
                        control={control}
                        label={'썸네일'}
                        message={'1MB 이하 JPG, JPEG, PNG형식만 업로드 가능합니다.'}
                        accept={'.jpeg, .jpg, .png'}
                        name={'thumbnail'}
                        maxSize={1}
                        initialData={defaultData?.thumbnail}
                        errors={errors}
                      />
                    </>
                  )}
                </CardBody>
              </Card>

              {idTemplateBundle && <>{<SearchTemplate idTemplateBundle={idTemplateBundle} />}</>}
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbDatePicker
                    label={'버전'}
                    control={control}
                    name={'lastUpdatedDate'}
                    errors={errors}
                    validation={{ required: true }}
                    onChange={(date: Date | null) => {
                      if (date) {
                        setValue('lastUpdatedDate', date);
                      }
                    }}
                  />
                  <hr className={'my-4'} />
                  <TbRadioBoolean
                    control={control}
                    message={'활성일 경우 최상단에 노출됩니다.'}
                    label={'상단 고정 여부'}
                    name="top"
                    register={register}
                  />
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  {idTemplateBundle && (
                    <>
                      <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default React.memo(TemplateBundleUpdate);
