import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { ReqBrandCore, ResBrandCore, updateBrandCore } from '../../../api/brandAPI';
import TbTextarea from '../../../components/common/ui/form/TbTextarea';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useApiError from '../../../hooks/useApiError';
import TbLoading from '../../../components/common/ui/TbLoading';
import { useMutation } from 'react-query';
import TbInput from '../../../components/common/ui/form/TbInput';

interface DefaultProps {
  idBrand: string;
  defaultData: ResBrandCore;
}
const BrandVisualCoreUpdate = ({ idBrand, defaultData }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateMutate = useMutation(updateBrandCore);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm<ReqBrandCore>({
    defaultValues: {},
  });

  const { fields, replace } = useFieldArray({
    control,
    name: 'coreValues', // name 속성 사용
  });

  /* 초기 데이터 설정 */
  useEffect(() => {
    // 배열이 null 또는 undefined 이면 배열 채우기
    let coreValues: { keyword: string; description: string }[] = [];
    if (!defaultData.coreValues) {
      coreValues = [
        { keyword: '', description: '' },
        { keyword: '', description: '' },
        { keyword: '', description: '' },
      ];
    } else {
      if (defaultData.coreValues.length < 3) {
        const count = 3 - defaultData.coreValues.length;
        for (let i = 0; i < count; i++) {
          coreValues.push({ keyword: '', description: '' });
        }
      } else {
        coreValues = defaultData.coreValues;
      }
    }
    setValue('background', defaultData.background);
    setValue('purposeManifesto', defaultData.purposeManifesto);
    setValue('visionManifesto', defaultData.visionManifesto);
    setValue('coreValues', coreValues);

    setTimeout(() => replace(coreValues), 0); // 🔥 강제 업데이트 트리거
  }, [defaultData, replace]);

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    //Update
    try {
      await updateMutate.mutateAsync({
        idBrand: idBrand,
        body: { ...data } as ReqBrandCore,
      });
      toast.success('가치체계 수정이 완료되었습니다.');
      // navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
      <Card>
        <CardHeader>
          <h5>가치체계</h5>
          <Button color="secondary" type="submit" disabled={isLoading}>
            {isLoading ? <TbLoading /> : '수정'}
          </Button>
        </CardHeader>
        <CardBody>
          <TbTextarea
            label="브랜드 스토리"
            id="background"
            name={'background'}
            placeholder="브랜드 스토리"
            rows={4}
            register={register}
            errors={errors}
          />
          <TbTextarea
            label="브랜드 목적 선언문"
            id="purposeManifesto"
            name={'purposeManifesto'}
            placeholder="브랜드 목적 선언문"
            rows={2}
            register={register}
            errors={errors}
          />
          <TbTextarea
            label="브랜드 비전 선언문"
            id="visionManifesto"
            name={'visionManifesto'}
            placeholder="브랜드 비전 선언문"
            rows={2}
            register={register}
            errors={errors}
          />
          {fields.map((field, index) => (
            <div key={field.id}>
              <TbInput
                label={`핵심가치 ${index + 1}`}
                name={`coreValues.${index}.keyword`}
                type="text"
                register={register}
                errors={errors}
              />
              <TbTextarea
                label={`핵심가치 ${index + 1} 설명`}
                id={`coreValues.${index}.description`}
                name={`coreValues.${index}.description`}
                rows={2}
                register={register}
                errors={errors}
              />
            </div>
          ))}
        </CardBody>
      </Card>
    </Form>
  );
};
export default React.memo(BrandVisualCoreUpdate);
