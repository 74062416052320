import React, { Fragment, useState } from 'react';
import { Button, Card, CardHeader } from 'reactstrap';
import { useMutation, useQuery } from 'react-query';
import { addTemplates, removeTemplates, ResTemplateBundleList } from '../../../api/templateBundleAPI';
import useApiError from '../../../hooks/useApiError';
import TbDataTable from '../../../components/common/ui/TbDataTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDefaultFilter from '../../../hooks/useDefaultFilter';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../../utils/urlUtils';
import { TemplateSimpleColumns } from '../../template/data/template-simple-columns';
import { getTemplatePage, ReqTemplatePage } from '../../../api/templateAPI';
import { Plus } from 'react-feather';
import TbModal from '../../../components/common/ui/TbModal';
import SearchTemplate from '../../template/component/SearchTemplate';
import { toast } from 'react-toastify';

interface DefaultProps {
  idTemplateBundle: string;
}

const BundleTemplateList = ({ idTemplateBundle }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [pageData, setPageData] = useState<ResTemplateBundleList[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isAddModal, setIsAddModal] = useState(false);
  const addTmplsMutate = useMutation(addTemplates);
  const removeTmplsMutate = useMutation(removeTemplates);
  // query string 값으로 request 파라미터 초기화.
  const [reqParams, setReqParams] = useState<ReqTemplatePage>({
    ...defaultFilter,
    idTemplateBundle: idTemplateBundle,
    type: 'DESIGN',
    createdType: 'MARKET',
    deletedType: 'USED',
    active: searchParams.get('active') === 'true' ? true : searchParams.get('active') === 'false' ? false : null,
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 목록 조회.
   * */
  const { isLoading, refetch } = useQuery(['getTemplatePage', reqParams], () => getTemplatePage(reqParams), {
    onSuccess: (data) => {
      setPageData(data?._embedded?.templates || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/template-event/update/${row.idTemplate}`);
  };

  /**
   * 템플릿 선택 완료
   * */
  const onCompleteSelectTemplate = async (ids: string[]) => {
    setIsAddModal(false);
    if (ids && ids.length > 0) {
      try {
        await addTmplsMutate.mutateAsync({
          idTemplateBundle: Number(idTemplateBundle),
          idTemplates: ids,
        });
        refetch();
        toast.success('템플릿이 번들에 추가되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 템플릿 번들에서 삭제
   * */
  const handleRemoveTemplate = async (idTemplate: string) => {
    if (idTemplate) {
      try {
        await removeTmplsMutate.mutateAsync({
          idTemplateBundle: Number(idTemplateBundle),
          idTemplates: [idTemplate],
        });
        refetch();
        toast.success('템플릿이 번들에서 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  return (
    <Fragment>
      {/** 템플릿 검색 모달 */}
      <TbModal
        isOpen={isAddModal}
        size={'lg'}
        headerTitle={'템플릿 검색'}
        isShowFooter={false}
        toggle={() => setIsAddModal(!isAddModal)}
        centered={false}
      >
        {isAddModal && <SearchTemplate onComplete={onCompleteSelectTemplate} />}
      </TbModal>
      <Card>
        <CardHeader>
          <h5>템플릿 관리</h5>
          <Button color="primary" className={'d-flex align-items-center'} onClick={() => setIsAddModal(true)}>
            <Plus size={20} className={'m-r-5'} /> 템플릿 추가
          </Button>
        </CardHeader>
        <div className={'card-table-body'}>
          <TbDataTable
            columns={TemplateSimpleColumns(handleRemoveTemplate)}
            data={pageData}
            progressPending={isLoading}
            paginationDefaultPage={(reqParams.page || 0) + 1}
            paginationTotalRows={totalRows}
            paginationPerPage={reqParams.size}
            paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onRowClicked={handleRowClicked}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default React.memo(BundleTemplateList);
