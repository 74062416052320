import React, { Fragment, ReactNode } from 'react';
import { Home } from 'react-feather';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  title: string;
  parent: string;
  children?: ReactNode;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ title, parent, children }) => {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row ">
            <div className="col-xl-6">
              <div className="page-header-left">
                <h3>{title}</h3>
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                      <Home />
                    </Link>
                  </li>
                  {parent && <li className="breadcrumb-item">{parent}</li>}
                  <li className="breadcrumb-item active">{title}</li>
                </ol>
              </div>
            </div>
            {/* <!-- Bookmark Start--> */}
            <div className="col-xl-6">
              <div className="d-flex justify-content-end">{children}</div>
            </div>
            {/* <!-- Bookmark Ends--> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
