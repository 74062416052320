export const BrandColumns = () => [
  {
    name: 'id',
    selector: (row: any) => row.id,
    center: true,
    width: '60px',
  },
  {
    name: '브랜드명',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '소유 계정',
    selector: (row: any) => row.email,
    sortable: true,
    center: true,
    wrap: true,
  },
];
