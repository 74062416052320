import React, { useEffect, useId, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { UploadCloud, X } from 'react-feather';
import { Button } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { checkFileExtension, convertToBytes } from '../../../utils/fileUtils';
import { toast } from 'react-toastify';

type FileUploadType = 'FILE' | 'IMAGE';
interface DefaultProps {
  type?: FileUploadType;
  name?: string;
  accept?: string;
  label?: string;
  bgColor?: string;
  onClickDelete?: () => void;
  defaultValue?: string;
  control?: any;
  validation?: any;
  errors?: any;
}

const LogoPreviewFileInput = ({
  type = 'IMAGE',
  name = 'file',
  accept = '.png, .jpg, .jpeg, .svg',
  label,
  bgColor,
  onClickDelete,
  defaultValue,
  control,
  validation = undefined,
  errors = {},
}: DefaultProps) => {
  const uniqueId = useId();
  const refFileInput = useRef<HTMLInputElement>(null);
  const [imagePreview, setImagePreview] = useState<string | null | undefined>(defaultValue);

  // 기본 설정값이 부모에 의해 변경된 경우 반영
  useEffect(() => {
    setImagePreview(defaultValue);
  }, [defaultValue]);

  /**
   * 미리보기 이미지 생성.
   * */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (accept && !checkFileExtension(file.name, accept.split(','))) {
        toast.warning(`허용되지 않는 확장자의 파일이 포함되어 있습니다.\n(가능 확장자: ${accept})`);
        return true;
      }
      if (file.size > convertToBytes(5, 'MB')) {
        toast.warning(`업로드 가능한 최대 파일 크기는 ${5}MB 입니다.`);
        return true;
      }

      // 유효한 파일인 경우 미리보기 이미지 생성.
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string); // base64 데이터 URL 저장
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container>
      <div>
        {label}
        {onClickDelete && (
          <InitBtn onClick={onClickDelete} className={'text-danger'}>
            [초기화]
          </InitBtn>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field: { onChange } }) => {
          return (
            <HiddenFileInput
              ref={refFileInput}
              id={uniqueId}
              type="file"
              accept={accept}
              onChange={(e) => {
                const files = e.target.files;
                if (files && files.length > 0) {
                  onChange(files); // 파일 선택 시 상태 업데이트
                }
                handleFileChange(e);
              }}
            />
          );
        }}
      />

      {imagePreview ? (
        <FileContainer href={imagePreview} target={'_blank'}>
          {type === 'IMAGE' ? (
            <PreviewImage src={imagePreview} alt="미리보기" bgColor={bgColor} />
          ) : (
            <FileWrap>'사용자 업로드 파일'</FileWrap>
          )}
          <BtnDelete
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              setImagePreview(null);

              // 인풋 리셋
              if (refFileInput.current) {
                (refFileInput.current as HTMLInputElement).value = '';
              }
            }}
            className={'btn btn-danger btn-xs'}
          >
            <X size={16} />
          </BtnDelete>
        </FileContainer>
      ) : (
        <Label htmlFor={uniqueId}>
          <UploadCloud size={30} color={'#007bff'} style={{ margin: 10 }} />
          <h6 className={'mb-0'}>Open Finder</h6>
        </Label>
      )}
      {errors && errors[name] && (
        <div className="text-danger mt-1">{errors[name].message || `${label}을/를 업로드하세요.`}</div>
      )}
    </Container>
  );
};

export default React.memo(LogoPreviewFileInput);

// Styled Components
const Container = styled.div`
  margin-left: 8px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //gap: 10px;
`;

const Label = styled.label`
  width: 120px;
  height: 120px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px dashed #dee2e6;
  text-align: center;
  &.hover {
    border-color: #007bff;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const FileContainer = styled.a`
  position: relative;
  display: flex;
`;

const FileWrap = styled.div`
  padding: 6px 46px 6px 10px;
  border: 2px solid #ddd;
`;

const PreviewImage = styled.img<{ bgColor?: string }>`
  ${(props) =>
    props.bgColor &&
    css`
      background: ${props.bgColor};
    `}
  width: 120px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #ddd;
`;

const BtnDelete = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  border-radius: 50%;
  padding: 2px;
`;

const InitBtn = styled.span`
  font-weight: 700;
  cursor: pointer;
`;
