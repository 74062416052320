import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery } from 'react-query';
import useApiError from '../../../hooks/useApiError';
import { getPaymentList, ReqPaymentList } from '../../../api/paymentAPI';
import { paymentColumns } from '../../subscription/data/payment-columns';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import PayLinkModal from './SubscriptionLinkModal';
import { useNavigate } from 'react-router-dom';
import { cellsStyle, headCellsStyle, rowsStyle } from '../../../data/customizer/react-data-table-config';

interface PropsType {
  ignoreIdSubscription?: boolean; // 전체 목록 조회가 필요한 경우 사용.
  idSubscription?: number;
  status?: string;
}

// 결제링크 생성기능 => 모달로 처리
// (구독 상세 페이지 공통으로 사용됨)
function ManagePayment({ ignoreIdSubscription, idSubscription, status }: PropsType) {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const [dropdownOpen, setOpen] = useState(false);
  const [listData, setListData] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [reqParams, setReqParams] = useState<ReqPaymentList>({
    page: 0,
    size: 5,
    sort: '',
  });
  const [isPayLinkModal, setIsPayLinkModal] = useState(false);

  /**
   * 페이지 데이터 요청.
   * */
  const { isLoading } = useQuery(
    ['getPaymentList', { reqParams }],
    () => getPaymentList({ ...reqParams, idSubscription }),
    {
      enabled: !!idSubscription || ignoreIdSubscription,
      onSuccess: (data) => {
        setListData(data?._embedded?.payments || []);
        setTotalRows(data?.page?.totalElements | 0);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 페이지 사이즈 변경
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 아이템 클릭
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/payment/read/${row.id}`);
  };

  /**
   * 드롭다운 토클
   * */
  const dropdownToggle = () => {
    setOpen(!dropdownOpen);
  };

  const toggleModal = () => {
    setIsPayLinkModal(!isPayLinkModal);
  };
  return (
    <div>
      {idSubscription && status && (
        <PayLinkModal
          idSubscription={idSubscription}
          status={status}
          isOpen={isPayLinkModal}
          toggle={toggleModal}
        ></PayLinkModal>
      )}

      <div className="card">
        <div className={'card-header'}>
          <h5>결제 관리</h5>
          {idSubscription ? (
            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle size={'sm'} color="dark" outline={true}>
                <span>
                  작업 <i className="fa fa-caret-down"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate(`/admin/payment/list?idSubscription=${idSubscription}`)}>
                  {'전체 내역 조회'}
                </DropdownItem>
                {idSubscription && (
                  <DropdownItem
                    onClick={() => {
                      toggleModal();
                    }}
                  >
                    {'결제 링크 조회'}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Button color={'primary'} onClick={() => navigate(`/admin/payment/list`)}>
              전체 보기
            </Button>
          )}
        </div>
        {idSubscription || ignoreIdSubscription ? (
          <div className={'card-table-body'}>
            <DataTable
              noHeader
              columns={paymentColumns}
              data={listData}
              progressPending={isLoading}
              highlightOnHover
              pointerOnHover
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={reqParams.size}
              paginationRowsPerPageOptions={[5, 10, 15]}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              onRowClicked={handleRowClicked}
              dense={true}
              customStyles={{
                rows: rowsStyle,
                headCells: headCellsStyle,
                cells: cellsStyle,
              }}
            />
          </div>
        ) : (
          <div className={'card-body'}>
            <p className={`text-center m-5-15 text-danger`}>브랜드를 연결하세요.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(ManagePayment);
