import { Button, Card, CardBody, CardHeader, Form } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import useApiError from '../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { ReqBrandVisualColor, ResBrandVisualColor, updateBrandVisualColor } from '../../../api/brandAPI';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import TbLoading from '../../../components/common/ui/TbLoading';
import TbInputColor from '../../../components/common/ui/form/TbInputColor';

interface DefaultProps {
  idBrand: string;
  defaultData: ResBrandVisualColor;
}
const BrandVisualColorUpdate = ({ idBrand, defaultData }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateMutate = useMutation(updateBrandVisualColor);

  // Form
  const {
    // control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqBrandVisualColor>({
    defaultValues: {},
  });

  const watchMain = watch('main');
  const watchSecondary = watch('secondary');
  const watchTertiary = watch('tertiary');
  const watchQuaternary = watch('quaternary');
  const watchSuccess = watch('success');
  const watchInfo = watch('info');
  const watchWarning = watch('warning');
  const watchDanger = watch('danger');

  /* 초기 데이터 설정 */
  useEffect(() => {
    setValue('main', defaultData.main);
    setValue('secondary', defaultData.secondary);
    setValue('tertiary', defaultData.tertiary);
    setValue('quaternary', defaultData.quaternary);
    setValue('success', defaultData.success);
    setValue('info', defaultData.info);
    setValue('warning', defaultData.warning);
    setValue('danger', defaultData.danger);
  }, [defaultData, setValue]);

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    console.log(data, idBrand);
    setIsLoading(true);
    //Update
    try {
      await updateMutate.mutateAsync({
        idBrand: idBrand,
        body: { ...data } as ReqBrandVisualColor,
      });
      toast.success('색상이 수정이 완료되었습니다.');
      // navigate(location?.state?.from ? location.state.from : `/admin/brand/list`);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
      <Card>
        <CardHeader>
          <h5>비주얼 - 색상</h5>
          <Button color="secondary" type="submit" disabled={isLoading}>
            {isLoading ? <TbLoading /> : '수정'}
          </Button>
        </CardHeader>
        <CardBody>
          <TbInputColor
            label={'메인 색상'}
            name={'main'}
            defaultValue={watchMain}
            register={register}
            errors={errors}
          />
          <TbInputColor
            label={'보조 색상'}
            name={'secondary'}
            defaultValue={watchSecondary}
            register={register}
            errors={errors}
          />
          <TbInputColor
            label={'포인트 색상1'}
            name={'tertiary'}
            defaultValue={watchTertiary}
            register={register}
            errors={errors}
          />
          <TbInputColor
            label={'포인트 색상2'}
            name={'quaternary'}
            defaultValue={watchQuaternary}
            register={register}
            errors={errors}
          />
          <TbInputColor
            label={'System Success'}
            name={'success'}
            defaultValue={watchSuccess}
            register={register}
            errors={errors}
          />
          <TbInputColor
            label={'System Info'}
            name={'info'}
            defaultValue={watchInfo}
            register={register}
            errors={errors}
          />
          <TbInputColor
            label={'System Warning'}
            name={'warning'}
            defaultValue={watchWarning}
            register={register}
            errors={errors}
          />
          <TbInputColor
            label={'System danger'}
            name={'danger'}
            defaultValue={watchDanger}
            register={register}
            errors={errors}
          />
        </CardBody>
      </Card>
    </Form>
  );
};

export default React.memo(BrandVisualColorUpdate);
