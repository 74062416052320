import React, { Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  createBrandKeyword,
  deleteBrandKeyword,
  getBrandKeyword,
  ReqBrandKeyword,
  updateDeleteKeyword,
} from '../../api/brandKeywordAPI';
import useApiError from '../../hooks/useApiError';
import TbModal from '../../components/common/ui/TbModal';
import TbLoading from '../../components/common/ui/TbLoading';
import Breadcrumb from '../../components/common/breadcrumb';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import { toast } from 'react-toastify';
import TbInput from '../../components/common/ui/form/TbInput';
import BrandKeywordI18n from './components/BrandKeywordI18n';

const BrandKeywordUpdate: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idBrandKeyword: idBrandKeyword } = useParams<{ idBrandKeyword: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deleteMutation = useMutation(deleteBrandKeyword);
  const createKeywordMutation = useMutation(createBrandKeyword);
  const patchKeywordMutation = useMutation(updateDeleteKeyword);

  // Date states
  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ReqBrandKeyword>({
    defaultValues: {
      ...(!idBrandKeyword
        ? // 생성일 경우 초기값.
          {
            active: false,
          }
        : {}),
    },
  });

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(
    ['getBrandKeyword', idBrandKeyword],
    () => getBrandKeyword(Number(idBrandKeyword)),
    {
      enabled: !!idBrandKeyword,
      onSuccess: (data) => {
        setValue('orderAscending', data.orderAscending);
        setValue('active', data.active);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  // 최종 Form Submit
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    if (idBrandKeyword && Number(idBrandKeyword) > 0) {
      // Update (PATCH)
      try {
        await patchKeywordMutation.mutateAsync({
          idBrandKeyword: idBrandKeyword, // `id`를 문자열로 변환
          body: { ...data }, // 업데이트할 데이터
        });
        toast.success('브랜드 키워드 수정이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create (POST)
      try {
        await createKeywordMutation.mutateAsync({ ...data });
        toast.success('브랜드 키워드가 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }
    setIsLoading(false);
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    if (idBrandKeyword) {
      try {
        await deleteMutation.mutateAsync(idBrandKeyword);
        navigate(-1);
        toggleDeleteModal();
        toast.success('브랜드 키워드가 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };
  //  =================== 공통 항목 끝 ================== //

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={handleDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
        <Breadcrumb parent="브랜드 키워드" title={`브랜드 키워드 ${idBrandKeyword ? '수정' : '생성'}`}>
          <div className="mb-0">
            {idBrandKeyword ? (
              <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                삭제
              </Button>
            ) : (
              ''
            )}
            <Button color="secondary" className="me-3" type="submit">
              {isLoading ? <TbLoading /> : idBrandKeyword ? '수정' : '생성'}
            </Button>
            <Button
              onClick={() => navigate(location?.state?.from ? location.state.from : `/admin/brand-keyword/list`)}
              color="primary"
            >
              목록
            </Button>
          </div>
        </Breadcrumb>
        <Container fluid={true}>
          <Row>
            <Col sm="7">
              {!!idBrandKeyword && defaultData ? (
                <>
                  {/* 다국어 지원 */}
                  <BrandKeywordI18n brandKeyword={defaultData} />
                </>
              ) : (
                <Card>
                  <CardHeader>
                    <h5>다국어 지원</h5>
                  </CardHeader>
                  <CardBody>
                    <p className={'text-danger'}>언어 등록은 생성 후 가능합니다.</p>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col sm="5">
              <Card>
                <div className="card-header">
                  <h5>설정</h5>
                </div>
                <CardBody>
                  <TbInput
                    label="순서(오름차순)"
                    name={'orderAscending'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadioBoolean control={control} label={'게시 상태'} name={'active'} register={register} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};
export default BrandKeywordUpdate;
