import TbSearch from '../../../components/common/ui/TbSearch';
import React, { useCallback, useState } from 'react';
import useApiError from '../../../hooks/useApiError';
import { useQuery } from 'react-query';
import { getBrandPage } from '../../../api/brandAPI';
import { debounce } from 'lodash';

interface DefaultProps {
  onSelect?: (brand: any) => void;
  isMarket?: boolean;
}

const SearchBrand = ({ onSelect, isMarket = false }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const [query, setQuery] = useState<string | null>(null);

  // GET ::: Brand ID
  const { data: searchResults } = useQuery(
    ['getBrandPage', { query }],
    () =>
      getBrandPage({
        query,
        page: 0,
        size: 30,
        deleted: false,
        market: isMarket,
      }),
    {
      enabled: !!query && query.length > 2,
      select: (data) =>
        data?._embedded?.brands.map((item: any) => ({
          value: item.id,
          label: `${item.name} (${item.email})`,
          data: item,
        })),
      onError: (error: any) => {
        handleApiError(error);
      },
    },
  );

  // Search
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (value) {
        console.log('value', value);
        setQuery(value);
      }
    }, 700),
    [],
  );

  // onChange
  const handleSearchKeyword = (value: string) => {
    debouncedSearch(value);
  };

  return (
    <TbSearch
      label={'브랜드명 또는 이메일로 검색하세요.'}
      placeholder="ex) Tidy-B"
      onChange={handleSearchKeyword}
      searchData={searchResults}
      onSelectValue={(idBrand) => {
        onSelect?.(idBrand);
      }}
    />
  );
};

export default React.memo(SearchBrand);
