import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useApiError from '../../../hooks/useApiError';
import TbRadioBoolean from '../../../components/common/ui/form/TbRadioBoolean';
import moment from 'moment';
import { useMutation } from 'react-query';
import { cancelSubscribe, ReqSubscribeCancel } from '../../../api/subscriptionAPI';
import { toast } from 'react-toastify';
import TbDatePicker from '../../../components/common/ui/form/TbDatePicker';
import { FORMAT_DATE_TIME_UNIT_BAR } from '../../../constant/format';

interface ModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSuccess?: () => void;
  defaultValue: {
    idSubscription: number;
    canceled: boolean;
    cancelDate: string | undefined;
  };
}

interface CancelForm {
  canceled: boolean;
  cancelDate: Date | string | null;
}

const ManageSubscribeCancelModal = ({ isOpen, toggle, defaultValue, onSuccess }: ModalProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const putCancelSubscribe = useMutation(
    ({ idSubscription, body }: { idSubscription: number; body: ReqSubscribeCancel }) =>
      cancelSubscribe(idSubscription, body),
  );

  // useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    reset,
  } = useForm<CancelForm>({
    defaultValues: {
      canceled: defaultValue.canceled,
      cancelDate:
        defaultValue.cancelDate && moment(defaultValue.cancelDate).isValid()
          ? moment(defaultValue.cancelDate).toDate()
          : null,
    },
  });

  const watchCanceled = watch('canceled')?.toString() === 'true';

  // 비활성인 경우 날짜 초기화.
  useEffect(() => {
    if (!watchCanceled) {
      setValue('cancelDate', null);
    }
  }, [watchCanceled]);

  // 모달 꺼졌을 때 초기화.
  useEffect(() => {
    if (isOpen) {
      reset({
        canceled: defaultValue.canceled,
        cancelDate:
          defaultValue.cancelDate && moment(defaultValue.cancelDate).isValid()
            ? moment(defaultValue.cancelDate).toDate()
            : null,
      });
    }
  }, [isOpen, defaultValue]);

  /**
   * 구독 취소 설정 요청.
   * */
  const handleOnSubmit = async (formData: CancelForm) => {
    try {
      console.log(typeof formData.canceled);
      await putCancelSubscribe.mutateAsync({
        idSubscription: defaultValue.idSubscription,
        body: {
          canceled: formData.canceled,
          cancelDate: JSON.parse(formData.canceled.toString())
            ? moment(formData.cancelDate).format(FORMAT_DATE_TIME_UNIT_BAR)
            : '',
        },
      });
      toast.success('구독 취소 상태가 변경 되었습니다.');
      toggle();
      // 성공 콜백
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'md'}>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <ModalHeader>구독 취소 설정</ModalHeader>
        <ModalBody>
          <TbRadioBoolean
            control={control}
            label={'구독 취소'}
            name={'canceled'}
            register={register}
            errors={errors}
            validation={{ required: true }}
          />
          <TbDatePicker
            label={'취소 일시'}
            control={control}
            name={'cancelDate'}
            errors={errors}
            validation={{ required: false }}
            showTimeSelect
            timeIntervals={1} // 1분 간격으로 설정
            onChange={(date: Date | null) => {
              if (date) {
                setValue('cancelDate', date);
              }
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={(e) => {
              e.preventDefault();
              toggle?.();
            }}
          >
            취소
          </Button>
          <Button color="primary" className="me-3" type={'submit'}>
            완료
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default React.memo(ManageSubscribeCancelModal);
