import React from 'react';
import { Button } from 'reactstrap';
import { Trash2 } from 'react-feather';

export const TemplateSimpleColumns = (handleRemoveTemplate: (idTemplate: string) => void) => [
  // {
  //   name: 'id',
  //   cell: (row: any) => <span title={row.idTemplate}>{row.idTemplate}</span>,
  //   center: true,
  //   width: '80px',
  // },
  {
    name: '썸네일',
    cell: (row: any) => {
      return (
        <div>
          {row.thumbnail ? (
            <img
              src={row.thumbnail}
              alt={'템플릿 썸네일'}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '100px',
                maxHeight: '70px',
              }}
            />
          ) : (
            '미리보기 없음'
          )}
        </div>
      );
    },
    center: true,
    width: '120px',
  },
  {
    name: '템플릿명',
    selector: (row: any) => row.name,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '브랜드명',
    selector: (row: any) => row.brandName,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '활성 여부',
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: 'Action',
    cell: (row: any) => (
      <div>
        <Button
          size={'sm'}
          color={'danger'}
          onClick={() => {
            handleRemoveTemplate(row.idTemplate);
          }}
          className={'d-flex'}
        >
          <Trash2 size={16} />
        </Button>
      </div>
    ),
    center: true,
    width: '80px',
  },
];
