import React, { Fragment, useState } from 'react';
import TbModal from '../../components/common/ui/TbModal';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import TbLoading from '../../components/common/ui/TbLoading';
import TbInput from '../../components/common/ui/form/TbInput';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/form/TbDateText';
import { useNavigate, useParams } from 'react-router-dom';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import {
  createSearchKeyword,
  deleteSearchKeyword,
  getSearchKeyword,
  getSearchKeywordEnum,
  ReqSearchKeyword,
  updateSearchKeyword,
} from '../../api/searchKeywordAPI';
import { useForm } from 'react-hook-form';
import { enumToSelect } from '../../utils/enumUtils';
import TbSelect from '../../components/common/ui/form/TbSelect';
import TbInputTag from '../../components/common/ui/form/TbInputTag';
import { getEditorElementCategoryPage } from '../../api/editorElementAPI';
import { getTemplateFormPage } from '../../api/templateFormAPI';
import { longTermCacheConfig } from '../../data/customizer/config';

const SearchKeywordUpdate = () => {
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idSearchKeyword } = useParams<{ idSearchKeyword: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteSearchKeyword);
  const createMutate = useMutation(createSearchKeyword);
  const updateMutate = useMutation(updateSearchKeyword);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqSearchKeyword>({
    defaultValues: {
      ...(!idSearchKeyword
        ? // 생성일 경우 초기값.
          {
            orderAscending: 0,
            recommend: false,
            active: false,
          }
        : {}),
    },
  });

  const watchEntityType = watch('entityType');
  const watchRecommend = watch('recommend');

  /**
   * Enum Type 조회
   * */
  const { data: enumType } = useQuery(['getSearchKeywordEnum'], getSearchKeywordEnum, {
    ...longTermCacheConfig,
    select: (data) => ({
      listEntityType: enumToSelect(data.listEntityType),
    }),
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 요소 카테고리 목록 요청.
   * */
  const { data: resCategories } = useQuery(
    ['getEditorElementCategoryPage'],
    () => getEditorElementCategoryPage({ page: 0, size: 1000 }),
    {
      ...longTermCacheConfig,
      select: (data) => {
        return data?._embedded?.categories?.map((item: any) => ({
          label: item.name,
          value: item.idElementCategory,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 템플릿 양식 목록 요청.
   * */
  const { data: resTemplateForm } = useQuery(
    ['getTemplateFormPage'],
    () => getTemplateFormPage({ page: 0, size: 1000 }),
    {
      ...longTermCacheConfig,
      select: (data) => {
        return data?._embedded?.templateForms?.map((item: any) => ({
          label: item.name,
          value: item.idTemplateForm,
        }));
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(
    ['getSearchKeyword', idSearchKeyword],
    () => getSearchKeyword(Number(idSearchKeyword)),
    {
      enabled: !!idSearchKeyword,
      select: (data) => {
        return {
          ...data,
        };
      },
      onSuccess: (data) => {
        setValue('name', data.name);
        setValue('entityType', data.entityType);
        setValue('keywordList', data.keywordList);
        setValue('orderAscending', data.orderAscending);
        setValue('recommend', data.recommend);
        setValue('active', data.active);
        setValue('idTemplateForm', data.idTemplateForm);
        setValue('idElementCategory', data.idElementCategory);
      },
      onError: (error) => {
        handleApiError(error);
      },
    },
  );

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    setIsLoading(false);
    if (idSearchKeyword && Number(idSearchKeyword) > 0) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idSearchKeyword: Number(idSearchKeyword),
          body: { ...data } as ReqSearchKeyword,
        });
        toast.success('수정이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          ...data,
        });
        toast.success('생성이 완료되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idSearchKeyword) {
      try {
        await deleteMutate.mutateAsync(Number(idSearchKeyword));
        navigate(-1);
        toggleDeleteModal();
        toast.success('삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="검색 키워드" title={`검색 키워드 ${idSearchKeyword ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idSearchKeyword ? (
                <>
                  <Button
                    onClick={() => {
                      if (defaultData?.entityType?.includes('TEMPLATE')) {
                        navigate(`/admin/template-event/list?idSearchKeyword=${idSearchKeyword}`);
                      } else if (defaultData?.entityType?.includes('ELEMENT')) {
                        navigate(`/admin/editor-element/list?idSearchKeyword=${idSearchKeyword}`);
                      }
                    }}
                    color="success"
                    className="me-3"
                    type={'button'}
                  >
                    검색하기
                  </Button>
                  <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                    삭제
                  </Button>
                </>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idSearchKeyword ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>검색 키워드 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="검색 키워드 이름(표기명)"
                    name={'name'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbRadioBoolean control={control} label={'추천 검색어 설정'} name="recommend" register={register} />

                  {/* 추천 키워드인 경우 키워드 미설정 */}
                  {watchRecommend?.toString() === 'false' && (
                    <TbInputTag
                      control={control}
                      label="검색 키워드"
                      message={'실제로 검색에 포함되는 키워드를 입력하세요.'}
                      name={'keywordList'}
                      errors={errors}
                    />
                  )}

                  {watchEntityType === 'TEMPLATE' ? (
                    <TbSelect
                      label="템플릿 양식"
                      message={'템플인 경우 선택하세요.'}
                      name="idTemplateForm"
                      options={resTemplateForm ? resTemplateForm : []}
                      register={register}
                      errors={errors}
                    />
                  ) : (
                    <TbSelect
                      label="요소 카테고리"
                      message={'요소 검색 키워드인 경우 선택하세요.'}
                      name="idElementCategory"
                      options={resCategories ? resCategories : []}
                      register={register}
                      errors={errors}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbSelect
                    label="엔티티 타입"
                    name="entityType"
                    options={enumType?.listEntityType ? enumType.listEntityType : []}
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="순서(오름차순)"
                    name={'orderAscending'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <hr className={'my-4'} />
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  {idSearchKeyword && (
                    <>
                      <TbDateText label={'생성일'} date={defaultData?.createdDate || ''} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate || ''} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
export default React.memo(SearchKeywordUpdate);
