import React, { useState } from 'react';
import { Button, Card, CardHeader } from 'reactstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useApiError from '../../../hooks/useApiError';
import useDefaultFilter from '../../../hooks/useDefaultFilter';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { toURLSearchParams } from '../../../utils/urlUtils';
import { useQuery } from 'react-query';
import TbDataTable from '../../../components/common/ui/TbDataTable';
import { getTemplateBundlePage, ReqTemplateBundlePage, ResTemplateBundleList } from '../../../api/templateBundleAPI';
import { TemplateBundleColumns } from '../data/template-bundle-columns';

interface DefaultProps {
  idBundleCollection: number;
  mainBundleId?: number;
}
const BundleList = ({ idBundleCollection, mainBundleId }: DefaultProps) => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultFilter] = useDefaultFilter();
  const [pageData, setPageData] = useState<ResTemplateBundleList[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  // query string 값으로 request 파라미터 초기화.
  const [reqParams, setReqParams] = useState<ReqTemplateBundlePage>({
    ...defaultFilter,
    active: searchParams.get('active') === 'true' ? true : searchParams.get('active') === 'false' ? false : null,
    idBundleCollection: Number(idBundleCollection),
  });

  // reqParam 변경되는 경우, url의 쿼리스트링 업데이트
  useDidMountEffect(() => {
    // `null` 또는 `undefined` 제거하고 모든 값을 스트링으로 변환.
    setSearchParams(toURLSearchParams(reqParams));
  }, [reqParams, setSearchParams]);

  /**
   * 목록 조회.
   * */
  const { isLoading } = useQuery(['getTemplateBundlePage', reqParams], () => getTemplateBundlePage(reqParams), {
    onSuccess: (data) => {
      setPageData(data?._embedded?.templateBundles || []);
      setTotalRows(data?.page?.totalElements || 0);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 페이지 변경 핸들러
   * */
  const handlePageChange = (page: number) => {
    setReqParams({
      ...reqParams,
      page: page - 1,
    });
  };

  /**
   * 한 페이지 당 보여줄 행 수 변경 핸들러
   * */
  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setReqParams({
      ...reqParams,
      size: newPerPage,
      page: page - 1,
    });
  };

  /**
   * 행 클릭 핸들러
   * */
  const handleRowClicked = (row: any) => {
    navigate(`/admin/template-bundle/update/${idBundleCollection}/${row.id}`);
  };

  return (
    <Card>
      <CardHeader>
        <h5>번들(확장팩) 관리</h5>
        <div>
          <Button onClick={() => navigate(`/admin/template-bundle/create/${idBundleCollection}`)} color={'primary'}>
            새로 등록
          </Button>
        </div>
      </CardHeader>
      <div className={'card-table-body'}>
        <TbDataTable
          columns={TemplateBundleColumns(mainBundleId)}
          data={pageData}
          progressPending={isLoading}
          paginationDefaultPage={(reqParams.page || 0) + 1}
          paginationTotalRows={totalRows}
          paginationPerPage={reqParams.size}
          paginationRowsPerPageOptions={[5, 10, 30, 50, 100]}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={handleRowClicked}
        />
      </div>
    </Card>
  );
};
export default React.memo(BundleList);
