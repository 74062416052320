import APIClient from './APIClient';

export interface ReqGenKeywords {
  url?: string;
  excludes?: string;
  count?: number;
}
export const getGenKeywords = async (params: ReqGenKeywords) => {
  const response = await APIClient.get('/v2/api/tools/gen-keywords', {
    params,
  });
  return response.data;
};
