import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { TBFormGroup, TBLabel } from '../../../components/common/styledComponents';
import { toast } from 'react-toastify';
import { Controller } from 'react-hook-form';
import TbResourceFileUploadItem from '../../../components/common/ui/form/file/TbResourceFileUploadItem';
import { UploadCloud } from 'react-feather';
import styled from 'styled-components';
import { SelectedFile } from '../../../components/common/ui/form/file/TbResourceFileUpload';
import { checkFileExtension, convertToBytes } from '../../../utils/fileUtils';
import { rgbToHex } from '../../../utils/colorUtils';
import useDidMountEffect from '../../../hooks/useDidMountEffect';

interface DefaultProps {
  path?: string; // 저장 경로
  label?: string;
  name?: string;
  message?: string | ReactElement;
  maxSize?: number;
  horizontal?: boolean;
  initialData?: string;
  onGenerateJson?: (json: any) => void;
  onGenerateThumbnail?: (file: File) => void;
  control: any;
  validation?: any;
  errors?: any;
}
interface Size {
  width: number;
  height: number;
}

const UploadSVGElement = ({
  path = 'elements',
  label = '',
  name = 'image',
  message = '',
  maxSize = 5,
  horizontal = false,
  initialData = '',
  onGenerateJson,
  onGenerateThumbnail,
  control,
  validation = undefined,
  errors = {},
}: DefaultProps) => {
  const refFileInput = useRef<HTMLInputElement>(null);
  const tempContainerRef = useRef<HTMLDivElement>(null);
  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);

  const [svgColors, setSvgColors] = useState<string[]>([]);
  const [svgSize, setSvgSize] = useState<Size>({ width: 0, height: 0 });

  // 초기 값 설정을 위한 useEffect
  useEffect(() => {
    if (initialData) {
      setSelectedFile({
        id: initialData,
        file: undefined,
        url: initialData,
        isUploaded: true,
      });
    }
  }, [initialData]);

  /**
   * 5 썸네일 파일 자동 생성
   * */
  const generateThumbnail = async (svgBlob: Blob, size: { width: number; height: number }) => {
    try {
      if (!size || size.width <= 0 || size.height <= 0) {
        throw new Error('Invalid SVG dimensions');
      }

      // 1. 원하는 가로 크기에 맞춰 비율 계산
      const targetWidth = 200;
      const aspectRatio = size.width / size.height;
      const targetHeight = targetWidth / aspectRatio;

      // 2. Canvas에 그리기
      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Failed to get 2D context');
      }

      // 3. SVG 이미지를 로드
      const url = URL.createObjectURL(svgBlob);
      const image = await loadImage(url);

      // 4. Canvas에 이미지 그리기
      ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
      URL.revokeObjectURL(url); // 메모리 해제

      // 5. Canvas에서 Blob 생성
      const blob = await canvasToBlob(canvas);

      // 6. Blob을 File로 변환
      return new File([blob], 'svg_element_thumb.png', { type: 'image/png' });
    } catch (err) {
      console.error('Error converting SVG to PNG:', err);
      throw err;
    }
  };

  /**
   * 4. SVG 사이즈 추출
   * */
  const getSvgSize = (svgElement: HTMLElement): Size | null => {
    // 이미지 사이즈 추출
    const viewBox = svgElement.getAttribute('viewBox');
    if (viewBox) {
      const viewBoxValues = viewBox.split(' ').map(Number); // viewBox 문자열을 분리하여 숫자로 변환
      if (viewBoxValues.length === 4) {
        const size: Size = {
          width: viewBoxValues[2],
          height: viewBoxValues[3],
        };
        if (!size.width || !size.height) {
          toast.warning('viewbox 속성이 존재하지 않습니다.');
          return null;
        }
        setSvgSize(size);
        return size;
      }
    }
    return null;
  };

  /**
   * 3. SVG 파일을 DOM으로 로드하여 특정 속성을 편집 또는 추가한다.
   * @return 변환된 svg string
   * */
  const changeSVG = (svgElement: HTMLElement): string | null => {
    if (svgElement) {
      // fill 속성을 가질 수 있는 태그 목록
      const fillCapableTags = [
        'circle',
        'ellipse',
        'line',
        'path',
        'polygon',
        'polyline',
        'rect',
        'text',
        'tspan',
        'use',
        'g',
      ];

      try {
        // SVG를 실제 DOM에 추가
        const tempContainer = tempContainerRef.current;
        if (tempContainer) {
          tempContainer.appendChild(svgElement);

          // fill 속성을 가질 수 있는 모든 태그 찾기
          const elementsWithFillCapability = fillCapableTags.flatMap((tag) =>
            Array.from(tempContainer.getElementsByTagName(tag)),
          );

          const arrColors: string[] = [];

          // 찾은 태그에 필요한 작업 수행
          elementsWithFillCapability.forEach((element) => {
            // 클래스 포함된 실제 fill 값
            let appliedFill = getActualFill(element);

            // RGB인 경우만 HEX로 변환
            if (appliedFill?.startsWith('rgb')) {
              appliedFill = rgbToHex(appliedFill);
            }

            // fill, origin_fill 값 셋팅, class 는 모두 삭제.
            (element as HTMLElement).setAttribute('class', '');
            (element as HTMLElement).style.fill = '';
            if (appliedFill) {
              (element as HTMLElement).setAttribute('fill', appliedFill);
              (element as HTMLElement).setAttribute('origin_fill', appliedFill);
              arrColors.push(appliedFill);
            }
          });

          // 중복을 제거한 최종 추출 컬러.
          setSvgColors([...new Set(arrColors)]);

          // 수정된 SVG를 문자열로 변환
          const updatedSVG = tempContainer.innerHTML;

          // DOM에서 SVG 제거
          tempContainer.innerHTML = '';

          return updatedSVG as string;
        }
      } catch (error) {
        toast.error(error?.toString());
        console.error('svg 편집중 에러 발생 : ', error);
      }
    }
    return null;
  };

  /**
   * 2. svg 파일에 대한 유효성 검사.
   * */
  const validSvgUrl = async (doc: Document) => {
    try {
      // 1. 필수 태그 및 속성 확인
      const svgTag = doc.querySelector('svg');
      const viewBox = svgTag?.getAttribute('viewBox');
      if (!svgTag || !viewBox) {
        toast.warning(`SVG의 필수 태그 및 속성 확인 오류`);
        return;
      }

      // 2. 스크립트 태그와 외부 참조 검사
      const hasScript = doc.querySelector('script');
      const hasForeignObject = doc.querySelector('foreignObject');
      if (hasScript || hasForeignObject) {
        toast.warning(`보안상 위험한 요소가 있는 SVG입니다.(스크립트가 포함되어 있습니다.)`);
        return;
      }

      return true;
    } catch (error) {
      toast.error(error?.toString());
      console.error('유효하지 않은 SVG 파일 입니다. : ', error);
      return false;
    }
  };

  /**
   * 1. 파일 변경 이벤트
   * - 파일 기본 유효성 검사.
   * */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = Array.from(e.target.files || [])?.[0];

      // 파일 확장자 확인
      if (!checkFileExtension(file.name, '.svg'?.split(','))) {
        toast.warning(`허용 되지 않는 확장자의 파일이 포함되어있습니다.\n(가능 확장자 : .svg})`);
        return;
      }
      // 파일 크기 확인
      if (file.size > convertToBytes(maxSize, 'MB')) {
        toast.warning(`업로드 가능한 최대 파일 크기는 1MB 입니다.`);
        return;
      }

      // 파일 유효성 검사가 완료되면, SVG 유효성 검사 시작.
      // FileReader로 파일 읽기
      const reader = new FileReader();
      reader.onload = async () => {
        const svgContent = reader.result as string;
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = doc.documentElement;

        // 2. SVG 유효성 검사
        if (await validSvgUrl(doc)) {
          // 3. SVG 변환
          const strSVG = changeSVG(svgElement);
          // 4. SVG 사이즈 추출
          const svgSize = getSvgSize(svgElement);

          if (strSVG && svgSize) {
            // 파일 업로드를 위한 파일 생성.
            const blob = new Blob([strSVG], { type: 'image/svg+xml' });
            const file = new File([blob], 'example.svg', { type: 'image/svg+xml' });

            // 배열에 셋 되면 업로드가 시작됨.
            setSelectedFile({
              file: file,
              url: '',
              isUploaded: false,
            } as SelectedFile);

            // 인풋 리셋
            if (refFileInput.current) {
              (refFileInput.current as HTMLInputElement).value = '';
            }

            // 5. 썸네일 생성 : 콜백 함수가 있는 경우만 수행.
            if (onGenerateThumbnail) {
              const thumbFile = await generateThumbnail(blob, svgSize);
              onGenerateThumbnail(thumbFile);
            }
          }
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
      reader.readAsText(file);
    } catch (err) {
      console.error('Validate SVG File Error :', err);
    }
  };

  /**
   * inherit 또는 상속된 값 처리
   * fill이 inherit로 설정되어 있다면, 부모 요소로 거슬러 올라가며 값을 추출해야 합니다. 이를 처리하는 코드는 다음과 같습니다:
   * */
  const getActualFill = (element: Element): string | null => {
    let appliedFill = element.getAttribute('fill'); // 직접 지정된 fill 확인

    if (!appliedFill) {
      // 클래스나 스타일 시트로 설정된 최종 렌더링 값을 가져옴
      const computedStyle = window.getComputedStyle(element);
      appliedFill = computedStyle.fill;

      // 기본값 'rgb(0, 0, 0)'이면 무시
      if (appliedFill === 'rgb(0, 0, 0)' || appliedFill === 'none') {
        appliedFill = null;
      }
    }

    return appliedFill;
  };

  /**
   * Utility: Image 로드
   * */
  const loadImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });

  /**
   * Utility: Canvas -> Blob
   * */
  const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob> =>
    new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) resolve(blob);
        else reject(new Error('Failed to create Blob'));
      }, 'image/png');
    });

  // 디자인 json body 생성.
  useDidMountEffect(() => {
    if (svgColors && svgSize && svgSize.width > 0 && svgSize.height > 0) {
      const jsonBody = {
        originWidth: svgSize.width,
        originHeight: svgSize.height,
        fills: svgColors.map((color) => {
          return [color, color];
        }),
      };
      onGenerateJson?.(jsonBody);
    }
  }, [svgColors, svgSize]);

  return (
    <TBFormGroup horizontal={horizontal.toString()}>
      <TBLabel horizontal={horizontal.toString()}>
        {label}
        {validation?.required && <span className={'text-danger'}>(필수)</span>}
      </TBLabel>
      <div className={'w-100'}>
        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field }) => (
            <>
              <FileInput
                ref={refFileInput}
                id={'uploadInputFile'}
                type={'file'}
                accept={'.svg'}
                multiple={false}
                onChange={handleFileChange}
              />
              {/* Thumbnail UI*/}
              {message && <div className={'mt-1 mb-2'}>{message}</div>}

              <div className={'d-flex flex-wrap w-100'}>
                {selectedFile && (
                  <TbResourceFileUploadItem
                    type={'IMAGE'}
                    path={path}
                    file={selectedFile?.file}
                    isUploaded={!!selectedFile?.isUploaded}
                    url={selectedFile?.url}
                    onComplete={(url) => {
                      setSelectedFile({
                        ...selectedFile,
                        url,
                        isUploaded: true,
                      });
                      // useForm에 적용
                      field.onChange(url);
                    }}
                    onDeleteComplete={() => {
                      setSelectedFile(null);

                      // useForm에 적용
                      field.onChange('');
                    }}
                  />
                )}

                {/* 업로드 버튼 */}
                {!selectedFile && (
                  <ThumbWrap htmlFor={'uploadInputFile'}>
                    <div>
                      <UploadCloud size={30} color={'#007bff'} />
                      <h6 className={'mb-0'}>Open Finder</h6>
                    </div>
                  </ThumbWrap>
                )}
              </div>
            </>
          )}
        />

        {errors && errors[name] && (
          <div className="text-danger mt-1">{errors[name].message || `이미지를 업로드하세요.`}</div>
        )}

        {/* 테스트용 미리보기 이미지 */}
        <div ref={tempContainerRef}></div>
      </div>
    </TBFormGroup>
  );
};

export default React.memo(UploadSVGElement);

const FileInput = styled.input`
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  z-index: -999;
  overflow: hidden;
`;
const ThumbWrap = styled.label`
  width: 120px;
  height: 120px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #dee2e6;
  text-align: center;
  &.hover {
    border-color: #007bff;
  }
`;
