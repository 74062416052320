import React, { Fragment, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import useApiError from '../../hooks/useApiError';
import Breadcrumb from '../../components/common/breadcrumb';
import TbModal from '../../components/common/ui/TbModal';
import { cancelCredit, getCredit, refundCredit } from '../../api/creditAPI';
import { useNavigate, useParams } from 'react-router-dom';
import ManageBrand from '../subscription/components/ManageBrand';
import moment from 'moment/moment';

const CreditUpdate: React.FC = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const cancelCreditMutation = useMutation((idCredit: string) => cancelCredit(idCredit));
  const refundCreditMutation = useMutation((idCredit: string) => refundCredit(idCredit));
  // 크레딧 아이디
  const { id: idCredit } = useParams<{ id: string }>();
  const navigate = useNavigate();
  // Modal
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  // 초기 데이터 로드
  const { data: defaultData } = useQuery(['getCredit', idCredit], () => getCredit(idCredit as string), {
    enabled: !!idCredit,
    onError: (error) => {
      handleApiError(error);
    },
  });

  // 크레딧 사용 취소
  const handleCancel = async () => {
    if (idCredit) {
      try {
        await cancelCreditMutation.mutateAsync(idCredit);
        toggleCancelModal();
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      console.error('idCredit is undefined');
    }
  };

  // 크레딧 환불
  const handleRefund = async () => {
    if (idCredit) {
      try {
        await refundCreditMutation.mutateAsync(idCredit);
        toggleRefundModal();
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      console.error('idCredit is undefined');
    }
  };

  // Date states
  // Form
  const toggleCancelModal = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  const toggleRefundModal = () => {
    setIsRefundModalOpen(!isRefundModalOpen);
  };

  return (
    <Fragment>
      {/* :::::::: 크레딧 사용 취소 : Start ::::::: */}
      <TbModal
        isOpen={isCancelModalOpen}
        toggle={toggleCancelModal}
        onClickConfirm={handleCancel}
        isBtnCancel={true}
        isShowFooter
      >
        <h5 className="text-center m-0">크레딧 사용을 취소 하시겠습니까?</h5>
      </TbModal>
      {/* :::::::: 크레딧 사용 취소 : END ::::::: */}
      {/* :::::::: 크레딧 환불 : Start ::::::: */}
      <TbModal
        isOpen={isRefundModalOpen}
        toggle={toggleRefundModal}
        onClickConfirm={() => handleRefund}
        isBtnCancel={true}
        isShowFooter
      >
        <h5 className="text-center m-0">크레딧을 환불 하시겠습니까?</h5>
      </TbModal>
      {/* :::::::: 크레딧 환불 : END ::::::: */}
      <Breadcrumb parent="크레딧" title="크레딧 내역 상세">
        <div className="mb-0">
          {/* :::::::::: 취소 :::::::: */}
          {defaultData?.status === 'USE' && (
            <Button color="danger" className="me-3" onClick={toggleCancelModal}>
              크레딧 사용 취소
            </Button>
          )}
          {/* :::::::::: 환불 :::::::: */}
          {defaultData?.refund && (
            <Button color="danger" className="me-3" type="submit" onClick={toggleRefundModal}>
              크레딧 환불
            </Button>
          )}
          <Button color="primary" onClick={() => navigate(-1)}>
            목록
          </Button>
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col sm="6" xxl={4}>
            {/* ::::::::::::: 브랜드 정보 ::::::::::: */}
            <ManageBrand idBrand={defaultData?.idBrand} />
          </Col>
          <Col sm="6" xxl={4}>
            {/* ::::::::::::: 크레딧 정보 :::::::::::::: */}
            <Card>
              <div className="card-header">
                <h5>크레딧 내역 상세</h5>
              </div>
              <CardBody>
                <Row>
                  <Col xs="6" className={'detail-item'}>
                    상태
                    <br />
                    {defaultData?.status && (
                      <>
                        {defaultData.status === 'ACCUMULATE' && (
                          <span className={'btn btn-xs btn-info text-white'}>적립</span>
                        )}
                        {defaultData.status === 'USE' && (
                          <span className={'btn btn-xs btn-success text-white'}>사용</span>
                        )}
                        {defaultData.status === 'EXPIRE' && (
                          <span className={'btn btn-xs btn-danger text-white'}>만료</span>
                        )}
                        {defaultData.status === 'REFUND' && (
                          <span className={'btn btn-xs btn-warning text-white'}>환불</span>
                        )}
                        {defaultData.status === 'CANCEL' && (
                          <span className={'btn btn-xs btn-warning text-white'}>취소</span>
                        )}
                      </>
                    )}
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    금액
                    <br />
                    <span>{defaultData?.amount ? defaultData?.amount : '-'}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    잔액
                    <br />
                    <span>{defaultData?.resultAmount ? defaultData?.resultAmount : '-'}</span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    거래일
                    <br />
                    <span>
                      {defaultData?.createdDate ? moment(defaultData?.createdDate).format('YYYY/MM/DD HH:mm:ss') : '-'}
                    </span>
                  </Col>
                  <Col xs="6" className={'detail-item'}>
                    만료일 <br />
                    <span>
                      {defaultData?.expiredDate ? moment(defaultData?.expiredDate).format('YYYY/MM/DD HH:mm:ss') : '-'}
                    </span>
                  </Col>
                  <Col xs="12" className={'detail-item'}>
                    설명
                    <br />
                    <span>{defaultData?.description ? defaultData?.description : '-'}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CreditUpdate;
