import React, { Fragment, useMemo, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useApiError from '../../hooks/useApiError';
import { useMutation, useQuery } from 'react-query';
import {
  createCreditPlan,
  deleteCreditPlan,
  getCreditPlan,
  ReqCreditPlan,
  updateCreditPlan,
} from '../../api/creditPlanAPI';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { ReqPlan } from '../../api/planAPI';
import { toast } from 'react-toastify';
import TbModal from '../../components/common/ui/TbModal';
import TbLoading from '../../components/common/ui/TbLoading';
import TbInput from '../../components/common/ui/form/TbInput';
import TbRadioBoolean from '../../components/common/ui/form/TbRadioBoolean';
import TbDateText from '../../components/common/ui/form/TbDateText';

const CreditPlanUpdate = () => {
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const navigate = useNavigate();
  const handleApiError = useApiError(); // 에러 처리 훅
  const { idCreditPlan } = useParams<{ idCreditPlan: string }>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteMutate = useMutation(deleteCreditPlan);
  const createMutate = useMutation(createCreditPlan);
  const updateMutate = useMutation(updateCreditPlan);

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ReqCreditPlan>({
    defaultValues: {
      ...(!idCreditPlan
        ? // 생성일 경우 초기값.
          {
            active: false,
          }
        : {}),
    },
  });

  const watchCredit = watch('credit');
  const watchPrice = watch('price');

  /**
   * 업데이트인 경우, 초기 데이터 로드
   * */
  const { data: defaultData } = useQuery(['getCreditPlan', idCreditPlan], () => getCreditPlan(Number(idCreditPlan)), {
    enabled: !!idCreditPlan,
    select: (data) => {
      return {
        ...data,
      };
    },
    onSuccess: (data) => {
      setValue('title', data.title);
      setValue('price', data.price);
      setValue('credit', data.credit);
      setValue('orderAscending', data.orderAscending);
      setValue('active', data.active);
    },
    onError: (error) => {
      handleApiError(error);
    },
  });

  /**
   * 폼 요청.
   * */
  const onSubmit = async (data: any) => {
    if (!isAdmin) {
      toast.warning('수정 권한이 없습니다.');
      return;
    }

    setIsLoading(false);
    if (idCreditPlan && Number(idCreditPlan) > 0) {
      //Update
      try {
        await updateMutate.mutateAsync({
          idCreditPlan: Number(idCreditPlan),
          body: { ...data } as ReqPlan,
        });
        toast.success('크레딧 상품 수정되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    } else {
      // Create
      try {
        await createMutate.mutateAsync({
          ...data,
        });
        toast.success('크레딧 상품이 생성되었습니다.');
        navigate(-1);
      } catch (error) {
        handleApiError(error);
      }
    }

    setIsLoading(false);
  };

  /**
   * 삭제 요청 핸들러
   * */
  const onSubmitDelete = async () => {
    if (idCreditPlan) {
      try {
        await deleteMutate.mutateAsync(Number(idCreditPlan));
        navigate(-1);
        toggleDeleteModal();
        toast.success('크레딧 상품이 삭제되었습니다.');
      } catch (error) {
        handleApiError(error);
      }
    }
  };

  /**
   * 삭제 모달 토글
   * */
  const toggleDeleteModal = () => {
    setIsDeleteModal(!isDeleteModal);
  };

  /**
   * 어드민 권한
   * */
  const isAdmin = useMemo(() => {
    return userRole === 'ROLE_SUPER' || userRole === 'ROLE_ADMIN';
  }, [userRole]);

  return (
    <Fragment>
      <TbModal isOpen={isDeleteModal} onClickConfirm={onSubmitDelete} toggle={toggleDeleteModal}>
        <h5 className="text-center m-0">삭제 하시겠습니까?</h5>
      </TbModal>
      <Container fluid={true}>
        <Form onSubmit={handleSubmit(onSubmit)} className="theme-form">
          <Breadcrumb parent="크레딧 상품" title={`크레딧 상품 ${idCreditPlan ? '수정' : '생성'}`}>
            <div className="mb-0">
              {idCreditPlan && isAdmin ? (
                <Button onClick={toggleDeleteModal} color="danger" className="me-3" type={'button'}>
                  삭제
                </Button>
              ) : (
                ''
              )}

              <Button color="secondary" className="me-3" type="submit">
                {isLoading ? <TbLoading /> : idCreditPlan ? '수정' : '생성'}
              </Button>
              <Button onClick={() => navigate(-1)} color="primary">
                목록
              </Button>
            </div>
          </Breadcrumb>

          <Row>
            <Col sm={7}>
              <Card>
                <CardHeader>
                  <h5>요금제 정보</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="이름"
                    name={'title'}
                    type="text"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="판매 크레딧"
                    name={'credit'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <TbInput
                    label="판매가"
                    name={'price'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <hr />
                  <div>
                    <table className={'table table-hover table-bordered'}>
                      <tbody>
                        <tr>
                          <th>판매 크레딧</th>
                          <td>{watchCredit?.toLocaleString()} 크레딧</td>
                        </tr>
                        <tr>
                          <th>판매 원가</th>
                          <td>{((watchCredit || 0) * 10).toLocaleString()}원</td>
                        </tr>
                        <tr>
                          <th>판매가</th>
                          <td>
                            {watchPrice?.toLocaleString()}원(
                            {((1 - (watchPrice || 0) / ((watchCredit || 0) * 10)) * 100).toFixed(2)}
                            %할인)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/** 1크레딧 = 10원 <br />* 정상가 : <s>{(10 * Number(watchCredit)).toLocaleString()}원</s> <br />**/}
                    {/*판매가 : {(10 * Number(watchCredit) * (1 - Number(watchDiscountRate))).toLocaleString()}원(*/}
                    {/*{Number(watchDiscountRate) * 100}% 할인)*/}
                    {/*<br />*/}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={5}>
              <Card>
                <CardHeader>
                  <h5>설정</h5>
                </CardHeader>
                <CardBody>
                  <TbInput
                    label="순서(오름차순)"
                    name={'orderAscending'}
                    type="number"
                    register={register}
                    errors={errors}
                    validation={{ required: true }}
                  />
                  <hr className={'my-4'} />
                  <TbRadioBoolean
                    control={control}
                    message={'비활성일 경우 콘텐츠가 노출되지 않습니다.'}
                    label={'게시 상태'}
                    name="active"
                    register={register}
                  />
                  {idCreditPlan && (
                    <>
                      <TbDateText label={'생성일'} date={defaultData?.createdDate} />
                      <TbDateText label={'수정일'} date={defaultData?.updatedDate} />
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};
export default React.memo(CreditPlanUpdate);
