import React from 'react';

export const TemplateBundleColumns = (mainBundleId?: number) => [
  {
    name: 'id',
    selector: (row: any) => row.id,
    center: true,
    width: '80px',
  },
  {
    name: '썸네일',
    cell: (row: any) => {
      return (
        <div>
          {row.thumbnail ? (
            <img
              src={row.thumbnail}
              alt={'템플릿 썸네일'}
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '100px',
                maxHeight: '70px',
              }}
            />
          ) : (
            '미리보기 없음'
          )}
        </div>
      );
    },
    center: true,
    width: '120px',
  },
  {
    name: '번들명',
    cell: (row: any) => (
      <span className={row.id === mainBundleId ? 'fw-bold' : ''}>
        {row.id === mainBundleId && <span className={'text-danger'}>(기본번들)</span>}
        {row.title}
      </span>
    ),
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: '템플릿 수',
    selector: (row: any) => row.count,
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
  {
    name: '활성 여부',
    cell: (row: any) => {
      return row.active ? (
        <span className="badge badge-success">활성</span>
      ) : (
        <span className="badge badge-danger">비활성</span>
      );
    },
    sortable: true,
    center: true,
    wrap: true,
    width: '80px',
  },
];
