import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../../../components/common/breadcrumb';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import TbInput from '../../../../components/common/ui/form/TbInput';
import { useForm } from 'react-hook-form';
import useApiError from '../../../../hooks/useApiError';
import { useMutation } from 'react-query';
import { autoRemovebg, RemovebgFormatType, RemovebgSizeType, ReqRemovebgAutoRem } from '../../../../api/thirdPartyAPI';
import { toast } from 'react-toastify';
import TbLoading from '../../../../components/common/ui/TbLoading';
import TbSelect from '../../../../components/common/ui/form/TbSelect';

const ThirdPartyPage = () => {
  const handleApiError = useApiError(); // 에러 처리 훅
  const autoRemovebgMutation = useMutation(autoRemovebg);
  const [listFile, setListFile] = useState([]);
  const [listSizeType] = useState<any>([
    { label: 'preview', value: RemovebgSizeType.PREVIEW },
    { label: 'full', value: RemovebgSizeType.FULL },
    { label: '50MP', value: RemovebgSizeType.UP_TO_50MP },
    { label: 'auto', value: RemovebgSizeType.AUTO },
  ]);
  const [listFormatType] = useState<any>([
    { label: 'png', value: RemovebgFormatType.PNG },
    { label: 'jpg', value: RemovebgFormatType.JPG },
    { label: 'zip', value: RemovebgFormatType.ZIP },
    { label: 'auto', value: RemovebgFormatType.AUTO },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  // const [imageWeight, setImageWeight] = useState<number>(50);
  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ReqRemovebgAutoRem>({
    defaultValues: {
      request_image: {
        size: 'auto',
        channels: 'rgba',
      },
    },
  });

  /**
   * 이미지 배경 제거 요청.
   * */
  const onSubmit = async (data: ReqRemovebgAutoRem) => {
    try {
      setIsLoading(true);
      const resultData: any = await autoRemovebgMutation.mutateAsync(data);
      if (resultData && resultData.message) {
        if (resultData && resultData.result) {
          toast.success(resultData.message);
          if (resultData.data?.list && resultData.data.list.length > 0) {
            setListFile(resultData.data.list);
            reset({
              path: '',
            });
          }
        } else {
          toast.error(resultData.message);
        }
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="도구 모음" title="배경제거(Removebg)">
        <div className="mb-0">
          {/*<Button color="primary" className="me-3" type="submit">*/}
          {/*  저장*/}
          {/*</Button>*/}
        </div>
      </Breadcrumb>
      <Container fluid={true}>
        <Row>
          <Col xxl="8">
            <Card>
              <div className="card-header">
                <h5>이미지 배경 제거 도구</h5>
              </div>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={5}>
                      <Row>
                        <Col sm={12}>
                          <TbInput
                            label={'원본 폴더명'}
                            message={"'NAS > removebg_images > origin' 경로의 원본 폴더명을 입력하세요."}
                            name={'path'}
                            placeholder={'원본 이미지 폴더 경로'}
                            type="text"
                            horizontal={true}
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                        </Col>
                        <Col sm={12}>
                          <TbSelect
                            label={'이미지 사이즈'}
                            name={'request_image.size'}
                            options={listSizeType}
                            horizontal={true}
                            register={register}
                            errors={errors}
                            validation={{ required: true }}
                          />
                        </Col>
                        <Col sm={12}>
                          <TbSelect
                            label={'이미지 포멧'}
                            name={'request_image.format'}
                            options={listFormatType}
                            horizontal={true}
                            register={register}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                      <div className={'text-end'}>
                        {/*<Button color="primary" className="me-3" type="submit" disabled={isLoading}>*/}
                        {/*  배경 제거*/}
                        {/*</Button>*/}
                        <p className={'text-danger'}>현재 사용 불가합니다.(개발팀 문의)</p>
                      </div>
                      <hr />
                      {isLoading ? (
                        <TbLoading />
                      ) : (
                        <ul>
                          {listFile.map((file) => (
                            <li key={file}>{file}</li>
                          ))}
                          {listFile.length === 0 && <li className={'text-danger'}>원본 이미지가 없습니다.</li>}
                        </ul>
                      )}
                    </Col>
                    <Col md={7}>
                      <h6>[생성 방법]</h6>
                      <ol className={'d-block'}>
                        <li className={'mb-2'}>
                          <b>'Nas&#62;removebg_images&#62;origin'</b> 경로에 구분을 위한 폴더 생성 <br />
                          예시) 날짜 + 이름 : 20241010_flower
                        </li>
                        <li className={'mb-2'}>
                          생성한 폴더에 배경 제거할 이미지를 추가
                          <br />
                          <span className={'text-danger'}>(배경 제거 성공시 원본 이미지는 자동 삭제됩니다.)</span>
                        </li>
                        <li className={'mb-2'}>
                          <b>'이미지 배경 제거 도구'</b>의 <b>'원본 폴더명'</b> 인풋에 <b>'절차 1'</b>에서 생성한 폴더
                          명을 입력
                        </li>
                        <li className={'mb-2'}>
                          <b>'이미지 사이즈'</b>의 경우<b>'250KB(e.g. 625x400 pixels) 이하'</b>의 이미지를 원할 경우
                          <b>'preview'</b>선택, 나머지는 <b>'auto'</b> 선택
                        </li>
                        <li className={'mb-2'}>
                          <b>'이미지 포멧'</b> 선택 안함 시 대부분 <b>'PNG'</b>포멧으로 출력
                        </li>
                        <li className={'mb-2'}>
                          <b>'배경 제거'</b>버튼 클릭
                        </li>
                        <li className={'mb-2'}>
                          'Nas&#62;removebg_images&#62;result&#62;
                          <b className={'text-danger'}>[생성한 원본 폴더]</b>
                          '경로에 생성된 이미지 확인
                          <br />
                          <span className={'text-danger'}>(생성된 이미지는 최대 1분 간격으로 갱신됩니다.)</span>
                        </li>
                      </ol>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default React.memo(ThirdPartyPage);
